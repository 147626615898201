import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Footer from './Footer';
import Navbar from './Navbar';
import getYouTubeID from 'get-youtube-id';
import { ScriptFile } from '../constants/server.constants';
import { getCaseStudyByid } from '../handelrs/caseStudies.handler';
import Box from '@mui/material/Box';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
// import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import TabPanel from '@material-ui/lab/TabPanel';

function SingleProject() {
  const params = useParams();
  const [userInfo, setUserInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState('1');
  const formattedChallenge = userInfo?.challenge?.replace(/&nbsp;/g, ' ');
  const formattedSolution = userInfo?.solution?.replace(/&nbsp;/g, ' ');
  const formattedOutcome = userInfo?.result?.replace(/&nbsp;/g, ' ');
  useEffect(() => {
    getCaseStudiesId();
    window.scrollTo(0, 0);
    const script = document.createElement('script');

    script.src = ScriptFile;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getCaseStudiesId = async () => {
    setLoading(true);
    getCaseStudyByid(params.id).then((res) => {
      setUserInfo(res.data.data);
      setLoading(false);
    });
  };
  // if (userInfo.video && userInfo.video !== undefined && userInfo.video !== "") {
  //   var str = userInfo.video;
  //   var slug = str.substring(str.indexOf("_") + 1);
  // }
  var slug = getYouTubeID(`(${userInfo.video})`);

  return (
    <div>
      <div className="page-wrapper">
        {/* <!-- start preloader --> */}
        {loading && (
          <div className="preloader">
            <div className="sk-cube-grid">
              <div className="sk-cube sk-cube1"></div>
              <div className="sk-cube sk-cube2"></div>
              <div className="sk-cube sk-cube3"></div>
              <div className="sk-cube sk-cube4"></div>
              <div className="sk-cube sk-cube5"></div>
              <div className="sk-cube sk-cube6"></div>
              <div className="sk-cube sk-cube7"></div>
              <div className="sk-cube sk-cube8"></div>
              <div className="sk-cube sk-cube9"></div>
            </div>
          </div>
        )}
        {/* <!-- end preloader --> */}

        {/* <!-- Start header --> */}
        <header id="header" className="site-header header-style-1">
          <Navbar />
        </header>
        {/* <!-- end of header --> */}

        {/* <!-- start page-title --> */}
        <section className="page-title-caseStudy">
          <div className="container">
            <div className="row">
              <div className="col col-xs-12">
                <h2>{`Case Study of ${userInfo && userInfo.category}`}</h2>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- end page-title --> */}

        {/* <!-- start portfolio-sigle-section --> */}
        <section className="portfolio-sigle-section section-padding">
          <div className="container">
            <div className="row">
              <div className="col col-xs-12">
                <div className="project-single-content">
                  {userInfo &&
                  userInfo.image &&
                  userInfo.video &&
                  userInfo.video !== undefined &&
                  userInfo.video !== '' ? (
                    <section
                      className="cta-s2-section"
                      style={{
                        backgroundImage: `url(${userInfo.image})`,
                      }}
                    >
                      <div className="container">
                        <div className="row">
                          <div
                            className="col col-lg-8 col-lg-offset-2 col-sm-8 col-sm-offset-2"
                            style={{ marginTop: '96px' }}
                          >
                            <div className="cta-details">
                              <div className="video">
                                <a
                                  href={` https://www.youtube.com/embed/${slug}?autoplay=1`}
                                  // href=" https://www.youtube.com/embed/_x3Q1dY4czc?autoplay=1"
                                  className="video-btn"
                                  data-type="iframe"
                                  tabindex="0"
                                >
                                  <i className="fi flaticon-play-button"></i>
                                </a>
                              </div>
                              <h4></h4>
                            </div>
                          </div>{' '}
                          {/* https://www.youtube.com/watch?v=_x3Q1dY4czc*/}
                          {/* https://youtu.be/_x3Q1dY4czc */}
                          {/* https://www.youtube.com/embed/_x3Q1dY4czc?autoplay=1 */}
                        </div>
                      </div>
                      {/* <!-- end container --> */}
                    </section>
                  ) : (
                    <div className="img-holder">
                      <img
                        src={userInfo && userInfo.image}
                        alt="service"
                        style={{ height: '400px', width: '350px' }}
                      />
                    </div>
                  )}
                  <div className="content-area">
                    <div className="project clearfix">
                      <div className="project-info">
                        <h3 style={{ textAlign: 'left', marginBottom: '30px' }}>
                          Information
                        </h3>
                        <ul>
                          <li className="clientInformation">
                            <span>Client:</span>
                            <span className="userInfo">
                              {' '}
                              {userInfo && userInfo.clientName}
                            </span>
                          </li>

                          <li className="clientInformation">
                            <span>Catetory:</span>
                            <span className="userInfo">
                              {userInfo &&
                                userInfo.category &&
                                userInfo.category.charAt(0).toUpperCase() +
                                  userInfo.category.slice(1)}
                            </span>
                          </li>
                          {/* <li>
                            <span>Time:</span> 1 Month
                          </li> */}
                          <li className="clientInformation">
                            <span>Tags:</span>
                            <span className="userInfo">
                              {userInfo && userInfo.tags}
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="project-details">
                        <h2>{userInfo && userInfo.title}</h2>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: userInfo && userInfo.description,
                          }}
                          style={{
                            textAlign: 'left',
                            fontSize: '18px',
                            fontFamily: 'robot',
                          }}
                        ></p>
                      </div>
                    </div>

                    <div className="challange-solution-section">
                      <Box
                        className="tablinks"
                        sx={{ width: '100%', typography: 'body1' }}
                      >
                        <TabContext value={value} variant="scrollable">
                          <Box sx={{ borderBottom: 1, borderColor: 'primary' }}>
                            <TabList
                              onChange={handleChange}
                              aria-label="lab API tabs example"
                            >
                              <Tab label="Customer Challenge" value="1" />
                              <Tab label="SLS Solution" value="2" />
                              <Tab label="Outcome" value="3" />
                            </TabList>
                          </Box>
                          <div className="tab-content">
                            <TabPanel value="1">
                              <div className="tab-pane">
                                {
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: formattedChallenge,
                                    }}
                                  ></p>
                                }
                              </div>
                            </TabPanel>

                            <TabPanel value="2">
                              <div className="tab-pane">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: formattedSolution,
                                  }}
                                />
                              </div>
                            </TabPanel>
                            <TabPanel value="3">
                              <div className="tab-pane">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: formattedOutcome,
                                  }}
                                />
                              </div>
                            </TabPanel>
                          </div>
                        </TabContext>
                      </Box>
                      {/* <!-- Nav tabs --> */}
                      {/* <div className="tablinks">
                        <ul>
                          <li className="active">
                            <a href="#tab-1" data-toggle="tab">
                              Customer Challenge
                            </a>
                          </li>
                          <li>
                            <a href="#tab-2" data-toggle="tab">
                              SLS Solution
                            </a>
                          </li>
                          <li>
                            <a href="#tab-3" data-toggle="tab">
                              Outcome
                            </a>
                          </li>
                        </ul>
                      </div>
                      {/* 
                                <!-- Tab panes --> 
                      <div className="tab-content">
                        <div className="tab-pane in active" id="tab-1">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: userInfo.challenge,
                            }}
                          ></p>
                        </div>
                        <div className="tab-pane fade" id="tab-2">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: userInfo && userInfo.solution,
                            }}
                          ></p>
                        </div>
                        <div className="tab-pane fade" id="tab-3">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: userInfo && userInfo.result,
                            }}
                          ></p>
                        </div>
                      </div> */}
                    </div>
                    {/* <div className="prev-next-project">
                                <div>
                                    <a href="#">
                                        <div className="icon">
                                            <i className="ti-angle-left"></i>
                                        </div>
                                        <span>Previous project</span>
                                        <h5>Nullam quis ante. Etiam sit amet orci eget</h5>
                                    </a>
                                </div>
                                <div>
                                    <a href="#">
                                        <div className="icon">
                                            <i className="ti-angle-right"></i>
                                        </div>
                                        <span>Next project</span>
                                        <h5>Maecenas tempus, tellus eget condimentum</h5>
                                    </a>
                                </div>
                            </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- end portfolio-sigle-section --> */}

        {/* <!-- start case-study-section-s2 -->
    <section className="case-study-section-s2 section-padding p-t-0">
        <div className="container">
            <div className="row">
                <div className="col col-lg-5 col-md-5">
                    <div className="section-title-s3">
                        <span>CASE STUDIES</span>
                        <h2>Check related project,doen for client!</h2>
                    </div>
                </div>
                <div className="col col-lg-6 col-lg-offset-1 col-md-7">
                    <div className="text">
                        <p>Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col col-xs-12">
                    <div className="content-area clearfix">
                        <div className="case-grids clearfix">
                            <div className="grid">
                                <div className="inner">
                                    <div className="img-holder">
                                        <img src="assets/images/latest-projects/img-1.jpg" alt>="service"
                                    </div>
                                    <div className="details">
                                        <div className="info">
                                            <h3><a href="#">IT Project in Canada</a></h3>
                                            <a href="#">View details <i className="fi flaticon-next"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="inner">
                                    <div className="img-holder">
                                        <img src="assets/images/latest-projects/img-2.jpg" alt>="service"
                                    </div>
                                    <div className="details">
                                        <div className="info">
                                            <h3><a href="#">IT Project in Canada</a></h3>
                                            <a href="#">View details <i className="fi flaticon-next"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="inner">
                                    <div className="img-holder">
                                        <img src="assets/images/latest-projects/img-3.jpg" alt/="service">
                                    </div>
                                    <div className="details">
                                        <div className="info">
                                            <h3><a href="#">IT Project in Canada</a></h3>
                                            <a href="#">View details <i className="fi flaticon-next"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </section> */}
        {/* <!-- end case-study-section-s2 --> */}

        {/* <!-- start news-letter-section --> */}
        {/* <section className="news-letter-section">
          <div className="container">
            <div className="row">
              <div className="col col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
                <div className="newsletter">
                  <h3>Email Newsletter</h3>
                   <p>
                    Aenean leo ligula porttitor eu consequat vitae eleifend ac
                    enim. Aliquam lorem ante dapibus in viverra quiss consequat
                    vitae
                  </p> 
                  <div className="newsletter-form">
                    <form>
                      <div>
                        <input type="text" className="form-control" />
                        <button type="submit">Subscribe</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <!-- end news-letter-section --> */}

        {/* <!-- start site-footer --> */}
        <Footer />
        {/* <footer className="site-footer">
        <div className="upper-footer">
            <div className="container">
                <div className="row">
                    <div className="separator"></div>
                    <div className="col col-lg-4 col-md-3 col-sm-6">
                        <div className="widget about-widget">
                            <div className="logo widget-title">
                                <img src="assets/images/footer-logo.png" alt>="service"
                            </div>
                            <p>Condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar</p>
                            <ul>
                                <li><a href="#"><i className="ti-facebook"></i></a></li>
                                <li><a href="#"><i className="ti-twitter-alt"="service"></i></a></li>
                                <li><a href="#"><i className="ti-linkedin"></i></a></li>
                                <li><a href="#"><i className="ti-pinterest"></i></a></li>
                                <li><a href="#"><i className="ti-youtube"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-lg-3 col-md-3 col-sm-6">
                        <div className="widget contact-widget service-link-widget">
                            <div className="widget-title">
                                <h3>Contact</h3>
                            </div>
                            <ul>
                                <li>54/1 New dhas sorini Asut, <br>Melbord Austria.</li>
                                <li><span>Phone:</span> 54786547521</li>
                                <li><span>Email:</span> demo@example.com</li>
                                <li><span>Office Time:</span> 9AM- 5PM</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-lg-3 col-md-3 col-sm-6">
                        <div className="widget link-widget">
                            <div className="widget-title">
                                <h3>Services</h3>
                            </div>
                            <ul>
                                <li><a href="#">Managed IT services</a></li>
                                <li><a href="#">IT Support & helpdesk</a></li>
                                <li><a href="#">IT Consultancy</a></li>
                                <li><a href="#">Custom Software</a></li>
                                <li><a href="#">Cyber Security</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-lg-2 col-md-3 col-sm-6">
                        <div className="widget link-widget">
                            <div className="widget-title">
                                <h3>Company</h3>
                            </div>
                            <ul>
                                <li><a href="#">About our company</a></li>
                                <li><a href="#">Client’s Testimonial</a></li>
                                <li><a href="#">Privacy policy</a></li>
                                <li><a href="#">Pricing and plans</a></li>
                                <li><a href="#">Cookies Policy</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> <!-- end container -->
        </div>
        <div className="lower-footer">
            <div className="container">
                <div className="row">
                    <div className="separator"></div>
                    <div className="col col-xs-12">
                        <p className="copyright">Copyright &copy; 2019 netserv. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    </footer> */}
        {/* <!-- end site-footer --> */}
      </div>
    </div>
  );
}

export default SingleProject;
