import React from "react";
import { Link } from "react-router-dom";
import silverLeafLogo from "../assets/images/footer-logo.png";

function Footer() {
  return (
    <div>
      <footer className="site-footer">
        <div className="upper-footer">
          <div className="container">
            <div className="row">
              <div className="separator"></div>
              <div
                className="col col-lg-4 col-md-3 col-sm-6"
                style={{ textAlign: "left" }}
              >
                <div className="widget about-widget">
                  <div className="logo widget-title">
                    <img
                      src={silverLeafLogo}
                      alt="brand logo"
                      style={{
                        width: "108px",
                        height: "57px",
                        marginTop: "-31px",
                      }}
                    />
                  </div>
                  <p>
                    Silver Leaf Solutions is a Global & Independent Organization
                    of Software Licensing Consultants, Licensing Contract
                    Specialists, Legal Experts and Tax Advisors that help our
                    clients in realizing Guaranteed savings of 10% and more
                    annually, across Multi-Year Software Licensing Contracts of
                    major software publishers like Microsoft, Oracle, SAP, IBM,
                    VMWare, Salesforce, ServiceNow & Adobe.
                  </p>
                  <ul>
                    {/* <li>
                      <a href="#">
                        <i className="ti-facebook"></i>
                      </a>
                    </li> */}
                    <li>
                      <a
                        href="https://twitter.com/silverleafsol"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="ti-twitter-alt"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href=" https://www.linkedin.com/company/silver-leaf-solutions/"
                        target="_blank"
                      >
                        <i className="ti-linkedin"></i>
                      </a>
                    </li>
                    {/* <li>
                      <a href="#">
                        <i className="ti-pinterest"></i>
                      </a>
                    </li> */}
                    <li>
                      <a
                        href=" https://www.youtube.com/@SilverLeafSolutionsPvtLt-xo5fz/featured"
                        target="_blank"
                      >
                        <i className="ti-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="col col-lg-3 col-md-3 col-sm-6"
                style={{ textAlign: "left" }}
              >
                <div className="widget contact-widget service-link-widget">
                  <div className="widget-title">
                    <h3>Contact</h3>
                  </div>
                  <ul>
                    <li>
                      Silver Leaf Solutions Private Limited
                      <br />
                      5th floor, Emerald Plaza, Sector 65
                      <br />
                      Golf Course Extension Road, Gurgaon 122102, Haryana, India
                    </li>
                    <li>
                      <span>Phone :</span> +91-124-4069345
                    </li>
                    <li>
                      <span>Email : </span>
                      <a
                        href="mailto: info@silverleafsolutions.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <br />
                        info@silverleafsolutions.com
                      </a>
                    </li>
                    <li>
                      <span>Global Site :</span>
                      <a
                        href="https://silverleafsolutions.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        www.silverleafsolutions.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="col col-lg-3 col-md-3 col-sm-6"
                style={{ textAlign: "left" }}
              >
                <div className="widget link-widget">
                  <div className="widget-title">
                    <h3>Services</h3>
                  </div>
                  <ul>
                    <li>
                      <Link
                        to="/softwareLicenseAdvisory"
                        // style={{ textDecoration: 'none' }}
                      >
                        Software Licensing Advisory
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/cyberSecurity"
                        // style={{ textDecoration: 'none' }}
                      >
                        CyberSecurity Solutions
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        to="/itInfrastructure"
                        // style={{ textDecoration: 'none' }}
                      >
                        Infrastructure Modernization Solutions
                      </Link>
                    </li> */}
                    {/* <li><a href="content-engineering.html">Software Licensing Advisory</a></li> */}
                    <li>
                      <Link
                        to="/softwareAssetManagement"
                        // style={{ textDecoration: 'none' }}
                      >
                        Managed SAM (Software Asset Management)
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/servicethreed"
                        // style={{ textDecoration: 'none' }}
                      >
                        Technology Evangelization
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/servicefour"
                        // style={{ textDecoration: 'none' }}
                      >
                        Procurement Optimization
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="col col-lg-2 col-md-3 col-sm-6"
                style={{ textAlign: "left" }}
              >
                <div className="widget link-widget">
                  <div className="widget-title">
                    <h3>Company</h3>
                  </div>
                  <ul>
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>

                    <li>
                      <Link to="/service">Our Services</Link>
                    </li>
                    <li>
                      <Link to="/privacy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/projects">Case studies</Link>
                    </li>
                    <li>
                      <Link to="/blogs">Blogs</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- end container --> */}
        </div>
        <div className="lower-footer">
          <div className="container">
            <div className="row">
              <div className="separator"></div>
              <div className="col col-xs-12">
                <p className="copyright">
                  Copyright &copy; 2023 netserv. All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
