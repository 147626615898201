import AppConstants from "../constants";
import AxiosInstance from "./http.handlers";
let url = AppConstants.ServerConstants.API_ROUTES;
export const GetAllAwards = async (body, headers) => {
    return await AxiosInstance.post(
        url.AWARDS.GET_ALL_AWARDS,
        body,
      {
        headers,
      }
    )
        .then((response) => response)
        .catch((error) => error);
};