import AppConstants from "../constants";
import AxiosInstance from "./http.handlers";
let url = AppConstants.ServerConstants.API_ROUTES 
export const Addpartner = async (body, headers) => {
  return await AxiosInstance.post(
   url.Partner_With_Us.ADD_Partner,
    body,
    {
      headers,
    }
   
  )
    .then((response) => response)
    .catch((error) => error);
};

