import React, { useEffect, useRef, useState } from 'react';
import YouTube from 'react-youtube';
import getYouTubeID from 'get-youtube-id';

const YoutubeIframe = (props) => {
  const [videoId, setVideoId] = useState(null);
  const modalCloseButtonRef = useRef(null);
  const playerRef = useRef(null);
  const iframeRef = useRef(null);

  useEffect(() => {
    const pauseYouTubeVideo = () => {
      if (playerRef.current) {
        playerRef.current.pauseVideo();
      }
    };

    const handleDocumentClick = (event) => {
      const modalCloseButton = modalCloseButtonRef.current;
      if (!modalCloseButton.contains(event.target)) {
        pauseYouTubeVideo();
      }
    };

    document.addEventListener('click', handleDocumentClick);
    const modalCloseButton = modalCloseButtonRef.current;
    modalCloseButton.addEventListener('click', pauseYouTubeVideo);

    // Set the videoId based on the props.video
    setVideoId(getYouTubeID(props.video));

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleDocumentClick);
      modalCloseButton.removeEventListener('click', pauseYouTubeVideo);
    };
  }, [props.video]);
  return (
    <div className="bs-example">
      <a href={`#myModal${videoId}`} className="video-btn" data-toggle="modal">
        <i className="fi flaticon-play-button"></i>
      </a>

      <div id={`myModal${videoId}`} className="modal fade">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                ref={modalCloseButtonRef}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="embed-responsive embed-responsive-16by9">
                {videoId && (
                  <YouTube
                    videoId={videoId}
                    opts={{
                      width: '560',
                      height: '315',
                    }}
                    onReady={(event) => {
                      playerRef.current = event.target;
                    }}
                  />
                )}

                {/* 
                <iframe
                  ref={iframeRef}
                  id="cartoonVideo"
                  className="embed-responsive-item"
                  width="560"
                  height="315"
                  src={`https://www.youtube.com/embed/${videoId}?autoplay=0`}
                  allow="fullscreen"
                  onLoad={handleIframeLoad}
                ></iframe> 
                */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YoutubeIframe;
