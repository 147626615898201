import AppConstants from "../constants";
import AxiosInstance from "./http.handlers";
let url = AppConstants.ServerConstants.API_ROUTES 


export const GetAllCaseStudies = async (body, headers) => {
  return await AxiosInstance.post(
   url.CASE_STUDY.GET_ALL_CASE_STUDY,
    body,
    {
      headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
};


export const getCaseStudyByid = async (id, headers) => {
  return await AxiosInstance.get(
    url.CASE_STUDY.EDIT_CASE_STUDY+ '/'+id,
 
    id,
    {
      headers,
    }
  )
    .then((resonse) => resonse)
    .catch((error) => error);
};


