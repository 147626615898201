import React, { useEffect } from "react";
import Footer from "../Component/Footer";
import Navbar from "../Component/Navbar";
import { Link } from "react-router-dom";
import cyberSecurity from "../../src/assets/images/cyberSecurity.jpg";
import vapt_assessment from "../../src/assets/images/vapt_assessmentpng.png";
import security_std from "../../src/assets/images/security_standard.png";
import vapt_methodology from "../../src/assets/images/vapt_methodology.png";
import casbpng from "../../src/assets/images/casbpng.png";
import nacpng from "../../src/assets/images/nac.png";
import siempng from "../../src/assets/images/siempng.png";
import aruba from "../../src/assets/images/aruba.png";
import netskope from "../../src/assets/images/netskope.png";
import f5 from "../../src/assets/images/f5.png";
import load_balancing_sol from "../../src/assets/images/load_balancing_sol.png";
import secure_remote_access from "../../src/assets/images/secure_remote_access.jpg";
import siem_strategic_oem from "../../src/assets/images/siem_strategic_oem.png";
import accops from "../../src/assets/images/accops.png";
import multifactor_authatication from "../../src/assets/images/multifactor_authatication.jpg";
import crowdstrike from "../../src/assets/images/crowdstrike.png";
import endpoint_security_features from "../../src/assets/images/endpoint_security_features.png";
import checkpoint from "../../src/assets/images/checkpoint.png";
import ngfwPng from "../../src/assets/images/ngfwPng.png";
import forcepoint from "../../src/assets/images/forcepoint.png";
import dlpPng from "../../src/assets/images/dlpPng.png";
import webApplication from "../../src/assets/images/webApplication.png";
import cost_optimization from "../../src/assets/gif/cost-optimization.gif";
import asset_management from "../../src/assets/gif/asset-management.gif";
import security from "../../src/assets/gif/security.gif";
import legal from "../../src/assets/gif/legal.gif";
import risk from "../../src/assets/gif/risk.gif";
import optimization_config from "../../src/assets/gif/bar-chart.gif";
import management from "../../src/assets/gif/management.gif";
import socialMedia from "../../src/assets/gif/social-media.gif";
import building from "../../src/assets/gif/building.gif";
import quality from "../../src/assets/gif/optimization.gif";
import search from "../../src/assets/gif/search.gif";
import folder from "../../src/assets/gif/folder.gif";
import checklist from "../../src/assets/gif/checklist.gif";
import evangelization from "../../src/assets/gif/evangelization.gif";
import rocket from "../../src/assets/gif/rocket.gif";
import complete from "../../src/assets/gif/complete.gif";
import diagram from "../../src/assets/gif/diagram.gif";
import presentation from "../../src/assets/gif/presentation.gif";
import virus from "../../src/assets/gif/virus.gif";
import email from "../../src/assets/gif/message.gif";
import padlock from "../../src/assets/gif/padlock.gif";
import lock from "../../src/assets/gif/locked.gif";
import cloudnetwork from "../../src/assets/gif/cloud-network.gif";

function CyberSecuritySol() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="page-wrapper">
        <header id="header" className="site-header header-style-1">
          <Navbar />
        </header>

        <section className="page-title-services">
          <div className="container">
            <div className="row">
              <div className="col col-xs-12">
                <h2 style={{ textTransform: "uppercase" }}>
                  CyberSecurity Solutions
                </h2>
              </div>
            </div>
          </div>
        </section>

        <section className="service-single-section section-padding">
          <div className="container">
            <div className="row">
              <div className="col col-md-9 col-md-push-3">
                <div className="service-single-content">
                  <section className="cyberSecurity__section">
                    <div className="consulting__title heading__title">
                      What is Cybersecurity | An Introduction
                    </div>
                    <div className="cyberSecurity__content">
                      {/* <div className="img-holder">
                          <img
                            src="assets/images/service-single/managed_services.jpg"
                            alt="software license"
                          />
                        </div> */}
                      <p style={{ textAlign: "center" }}>
                        Cybersecurity is a set of Solutions & Strategies that
                        prevents unauthorized access to organizational assets
                        such as computers, networks, and data. It maintains the
                        integrity and confidentiality of sensitive information,
                        blocking the access of sophisticated hackers.
                      </p>
                      <br />
                      <div className="cyber_container">
                        <div className="cyber_container-col1">
                          <div className="cyber_intro">
                            <h2>What are the threats to Cybersecurity?</h2>
                          </div>
                          <p>
                            Threats to Cybersecurity can come in different
                            forms. A common threat is malware, or malicious
                            software, which may come in different variations to
                            infect network devices, including:
                          </p>

                          <ul className="cyberSecurity__small-ul">
                            <li>
                              <i className="ti-arrow-circle-right"></i>
                              <span> Ransomware</span>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <span>Spyware</span>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <span>Viruses</span>
                            </li>
                          </ul>
                          <div className="cyber_intro">
                            <h2>Types of Cybersecurity Solutions:</h2>
                          </div>
                          <div
                            className="cyberSecurity__small-ul marigin-left"
                            style={{ marginTop: "20px" }}
                          >
                            <ul>
                              <li>
                                <i className="ti-arrow-circle-right"></i>{" "}
                                <span>Network Security</span>
                              </li>
                              <li>
                                <i className="ti-arrow-circle-right"></i>{" "}
                                <span>Endpoint Security</span>
                              </li>
                              <li>
                                <i className="ti-arrow-circle-right"></i>{" "}
                                <span>Cloud Security</span>
                              </li>
                              {/* </ul>
                            <ul> */}
                              <li>
                                <i className="ti-arrow-circle-right"></i>{" "}
                                <span>Application Security</span>
                              </li>
                              <li>
                                <i className="ti-arrow-circle-right"></i>{" "}
                                <span>Internet Security</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div>
                          <img src={cyberSecurity} alt="cyber security" />
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div className="col col-md-3 col-md-pull-9 hide-phone">
                <div className="service-sidebar">
                  <div className="widget service-list-widget">
                    <ul>
                      <li>
                        <Link to="/service" style={{ textDecoration: "none" }}>
                          All Service
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/softwareLicenseAdvisory"
                          style={{ textDecoration: "none" }}
                        >
                          Software Licensing Advisory
                        </Link>
                      </li>
                      {/* <li><a href="content-engineering.html">Software Licensing Advisory</a></li> */}
                      <li className="current">
                        <Link
                          to="/cyberSecurity"
                          style={{ textDecoration: "none" }}
                        >
                          CyberSecurity Solution
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          to="/itInfrastructure"
                          style={{ textDecoration: 'none' }}
                        >
                          Infrastructure Modernization Solutions
                        </Link>
                      </li> */}
                      <li>
                        <Link
                          to="/softwareAssetManagement"
                          style={{ textDecoration: "none" }}
                        >
                          Managed SAM (Software Asset Management)
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/servicethreed"
                          style={{ textDecoration: "none" }}
                        >
                          Technology Evangelization
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/servicefour"
                          style={{ textDecoration: "none" }}
                        >
                          Procurement Optimization
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="widget download-widget">
                    <ul>
                      <li>
                        <a
                          href="/assets/file/CyberSecurity_Solutions.pdf"
                          download="CyberSecurity_Solutions.pdf"
                        >
                          <i className="ti-zip"></i>Company Profile
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="widget contact-widget">
                    <div>
                      <h5>
                        We are Software Licensing Advisory<span>Experts</span>
                      </h5>
                      <a href="/contact">Contact with us</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="containerFluid">
              <div className="universal nssplSection">
                <div className="consulting__title heading__title">
                  Next-Generation Firewall (NGFW)
                </div>
                <p className="consulting__boxContent margin-para">
                  Next-Generation firewall (NGFW) is an advanced network
                  security device that combines the features of traditional
                  firewalls with additional capabilities to provide enhanced
                  security and threat protection. NGFWs are designed to defend
                  against modern, sophisticated threats and provide more
                  granular control over network traffic.
                </p>
                <div className="universal__box">
                  <div>
                    <div className="consulting__box cyberRow">
                      <div className="consulting__boxImage">
                        <img src="https://silverleaf.nssplindia.com/static/media/diagram.2a6d4848e3864a0ab10f.gif" />
                      </div>
                      <div className="consulting__textArea">
                        <div className="consulting__boxHeading">Features</div>

                        <div className="cyberSecurity__features-ul">
                          <ul>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Application Awareness</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Intrusion Prevention System(IPS)</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>User Identification and Control</p>
                            </li>
                            {/* </ul>
                          <ul> */}
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Content Filtering</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Advanced Threat Protection</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>VPN Support</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Centralized Management and Reporting</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    {/* <div className="consulting__box cyberRow">
                      <div className="consulting__boxImage">
                        <img src="https://silverleaf.nssplindia.com/static/media/rocket.40329a34c4c138180450.gif" />
                      </div>
                      <div className="consulting__textArea">
                        <div className="consulting__boxHeading">
                          Strategic OEM
                        </div>
                        <div className="cyberSecurity__features-ul">
                          <ul>
                            <li>
                              <img src={checkpoint} alt="checkpoint" />
                            </li>

                            <div className="consulting__boxHeading">
                              Other OEMs
                            </div>
                            <div className="dflex">
                              <div>
                                <li>
                                  <i className="ti-arrow-circle-right"></i>{" "}
                                  <p>Fortigate </p>
                                </li>
                                <li>
                                  <i className="ti-arrow-circle-right"></i>{" "}
                                  <p>Cisco Net </p>
                                </li>

                                <li>
                                  <i className="ti-arrow-circle-right"></i>{" "}
                                  <p>SonicWALL</p>
                                </li>
                              </div>
                              <div>
                                <li>
                                  <i className="ti-arrow-circle-right"></i>{" "}
                                  <p>Net Gear</p>
                                </li>

                                <li>
                                  <i className="ti-arrow-circle-right"></i>{" "}
                                  <p>PaloAlto</p>
                                </li>
                                <li>
                                  <i className="ti-arrow-circle-right"></i>{" "}
                                  <p>Sophos</p>
                                </li>
                              </div>
                            </div>
                          </ul>
                        </div>
                      </div>
                    </div> */}
                  </div>

                  <div className="universal__imageContainer cyberSecurity__sideImg">
                    <img
                      src={ngfwPng}
                      className="casbpng__style"
                      alt="ngfwPng"
                    />
                  </div>
                </div>
              </div>

              <div className="universal nssplSection">
                <div className="consulting__title heading__title">
                  Data Loss Prevention(DLP)
                </div>
                <p className="consulting__boxContent margin-para">
                  Data loss prevention (DLP) is a set of techniques and
                  technologies designed to prevent the unauthorized or
                  accidental loss, leakage, or theft of sensitive data from an
                  organization. It involves identifying, monitoring, and
                  protecting sensitive data throughout its lifecycle, both
                  within the organization's network and outside of it.
                </p>
                <div className="universal__box">
                  <div>
                    <div className="consulting__box cyberRow">
                      <div className="consulting__boxImage">
                        <img src="https://silverleaf.nssplindia.com/static/media/diagram.2a6d4848e3864a0ab10f.gif" />
                      </div>
                      <div className="consulting__textArea">
                        <div className="consulting__boxHeading">Features</div>

                        <div className="cyberSecurity__features-ul">
                          <ul>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Data Discovery and Classification</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Data Monitoring and Control</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Policy Enforcement</p>
                            </li>
                            {/* </ul>
                              <ul> */}
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Endpoint Protection</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Incident Response and Reporting</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* <div className="consulting__box cyberRow">
                      <div className="consulting__boxImage">
                        <img src="https://silverleaf.nssplindia.com/static/media/rocket.40329a34c4c138180450.gif" />
                      </div>
                      <div className="consulting__textArea">
                        <div className="consulting__boxHeading">
                          Strategic OEM
                        </div>
                        <div className="cyberSecurity__features-ul">
                          <ul>
                            <li>
                              <img src={forcepoint} alt="forcepoint" />
                            </li>
                            <br />
                            <div className="consulting__boxHeading">
                              Other OEMs
                            </div>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>TrendMicro</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Trellix</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Symantec</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Sophos</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="universal__imageContainer cyberSecurity__sideImg">
                    <img
                      src={dlpPng}
                      className="casbpng__style dlpPng"
                      alt="dlpPng"
                    />
                  </div>
                </div>
              </div>

              <div className="universal nssplSection">
                <div className="consulting__title heading__title">
                  Web Application & API Protection
                </div>
                <p className="consulting__boxContent margin-para">
                  Web Application and API Protection (WAAP) is a set of security
                  measures and technologies designed to safeguard web
                  applications and APIs from various threats and
                  vulnerabilities. It involves implementing security controls
                  and best practices to mitigate risks, protect data, and ensure
                  the availability and integrity of web applications and APIs.
                </p>
                <div className="universal__box">
                  <div>
                    <div className="consulting__box cyberRow">
                      <div className="consulting__boxImage">
                        <img src="https://silverleaf.nssplindia.com/static/media/diagram.2a6d4848e3864a0ab10f.gif" />
                      </div>
                      <div className="consulting__textArea">
                        <div className="consulting__boxHeading">Features</div>

                        <div className="cyberSecurity__features-ul">
                          <ul>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Web Application Firewall (WAF)</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>API Security</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Bot Protection</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Secure Development Practices</p>
                            </li>
                            {/* </ul>
                              <ul> */}
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Threat Intelligence and Monitoring</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Logging, Auditing and Compliance</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* <div className="consulting__box cyberRow">
                      <div className="consulting__boxImage">
                        <img src="https://silverleaf.nssplindia.com/static/media/rocket.40329a34c4c138180450.gif" />
                      </div>
                      <div className="consulting__textArea">
                        <div className="consulting__boxHeading">
                          Strategic OEM
                        </div>

                        <div className="cyberSecurity__features-ul">
                          <ul>
                            <li>
                              <img src={f5} alt="f5" />
                            </li>
                            <br />
                            <div className="consulting__boxHeading">
                              Other OEMs
                            </div>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Cloudflare Spectrum</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Fortinet Forti Web</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Imperva WAF</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>AWS WAF</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="universal__imageContainer cyberSecurity__sideImg">
                    <img
                      src={webApplication}
                      className="casbpng__style"
                      alt="webApplication"
                    />
                  </div>
                </div>
              </div>
              <div className="universal nssplSection">
                <div className="consulting__title heading__title">
                  Endpoint Security & Response (EDR)
                </div>
                <p className="consulting__boxContent margin-para">
                  Endpoint security, or endpoint protection, is the
                  cybersecurity approach to defending endpoints – such as
                  desktops, laptops, and mobile devices – from malicious
                  activity. It is a centralized management console from which
                  administrators can connect to their enterprise network to
                  monitor, protect, investigate and respond to incidents.
                </p>
                <div className="universal__box">
                  <div>
                    <div className="consulting__box cyberRow">
                      <div className="consulting__boxImage">
                        <img src="https://silverleaf.nssplindia.com/static/media/diagram.2a6d4848e3864a0ab10f.gif" />
                      </div>
                      <div className="consulting__textArea">
                        <div className="consulting__boxHeading">Features</div>

                        <div className="cyberSecurity__features-ul">
                          <ul>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Application control</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Device Control</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Web Protection</p>
                            </li>
                            {/* </ul>
                          <ul> */}
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Data Loss Prevention</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Endpoint Detection and Response</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* <div className="consulting__box cyberRow">
                      <div className="consulting__boxImage">
                        <img src="https://silverleaf.nssplindia.com/static/media/rocket.40329a34c4c138180450.gif" />
                      </div>
                      <div className="consulting__textArea">
                        <div className="consulting__boxHeading">
                          Strategic OEM
                        </div>

                        <div className="cyberSecurity__features-ul">
                          <ul>
                            <li>
                              <img src={crowdstrike} alt="crowdstrike" />
                            </li>
                            <br />
                            <div className="consulting__boxHeading">
                              Other OEMs
                            </div>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Trend Micro Apex One</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>McAfee Endpoint Security</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Broadcom Symantec Endpoint Security</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Checkpoint Harmony Endpoint</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>FireEye Endpoint Security</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>SentinelOne Singularity XDR</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="universal__imageContainer cyberSecurity__sideImg">
                    <img
                      src={endpoint_security_features}
                      className="casbpng__style edrPng"
                      alt="endpoint_security_features"
                    />
                  </div>
                </div>
              </div>
              <div className="universal nssplSection">
                <div className="consulting__title heading__title">
                  Multi Factor Authentication
                </div>
                <p className="consulting__boxContent margin-para">
                  Multi-factor Authentication (MFA) is an authentication method
                  that requires the user to provide two or more verification
                  factors to gain access to a resource such as an application,
                  online account, or a VPN.
                </p>
                <div className="universal__box">
                  <div>
                    <div className="consulting__box cyberRow">
                      <div className="consulting__boxImage">
                        <img src="https://silverleaf.nssplindia.com/static/media/diagram.2a6d4848e3864a0ab10f.gif" />
                      </div>
                      <div className="consulting__textArea">
                        <div className="consulting__boxHeading">Features</div>

                        <div className="cyberSecurity__features-ul">
                          <ul>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Push notifications</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>SMS notifications</p>
                            </li>
                            {/* </ul>
                          <ul> */}
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>One-time passwords</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Email notifications</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* <div className="consulting__box cyberRow">
                      <div className="consulting__boxImage">
                        <img src="https://silverleaf.nssplindia.com/static/media/rocket.40329a34c4c138180450.gif" />
                      </div>
                      <div className="consulting__textArea">
                        <div className="consulting__boxHeading">
                          Strategic OEM
                        </div>

                        <div className="cyberSecurity__features-ul">
                          <ul>
                            <li>
                              <img src={accops} alt="accops" />
                            </li>
                            <br />
                            <div className="consulting__boxHeading">
                              Other OEMs
                            </div>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>RSA SecurID Access</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Salesforce Authenticator</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Azure Multi-Factor Authentication</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Forti Authenticator</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Okta Adaptive Multi-factor Authentication</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="universal__imageContainer cyberSecurity__sideImg">
                    <img
                      src={multifactor_authatication}
                      className="casbpng__style "
                      alt="multifactor_authatication"
                    />
                  </div>
                </div>
              </div>
              <div className="universal nssplSection">
                <div className="consulting__title heading__title">
                  Secure Remote Access
                </div>
                <p className="consulting__boxContent margin-para">
                  Zero Trust Network Access (ZTNA) is a category of technologies
                  that provides secure remote access to applications and
                  services based on defined access control policies.
                </p>
                <div className="universal__box">
                  <div>
                    <div className="consulting__box cyberRow">
                      <div className="consulting__boxImage">
                        <img src="https://silverleaf.nssplindia.com/static/media/diagram.2a6d4848e3864a0ab10f.gif" />
                      </div>
                      <div className="consulting__textArea">
                        <div className="consulting__boxHeading">Features</div>

                        <div className="cyberSecurity__features-ul">
                          <ul>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Secure Remote Access</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Single Sign On</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Strong Endpoint Control</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Device Audit/Detailed Users</p>
                            </li>
                            {/* </ul>
                          <ul> */}
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>User Activity Logs</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>MFA</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* <div className="consulting__box cyberRow">
                      <div className="consulting__boxImage">
                        <img src="https://silverleaf.nssplindia.com/static/media/rocket.40329a34c4c138180450.gif" />
                      </div>
                      <div className="consulting__textArea">
                        <div className="consulting__boxHeading">
                          Strategic OEM
                        </div>

                        <div className="cyberSecurity__features-ul">
                          <ul>
                            <li>
                              <img src={accops} alt="accops" />
                            </li>
                            <br />
                            <div className="consulting__boxHeading">
                              Other OEMs
                            </div>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Palo Alto</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Zscaler</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Akamai</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Perimeter 81</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="universal__imageContainer cyberSecurity__sideImg">
                    <img
                      src={secure_remote_access}
                      className="casbpng__style"
                      alt="secure_remote_access"
                    />
                  </div>
                </div>
              </div>
              <div className="universal nssplSection">
                <div className="consulting__title heading__title">
                  Load Balancing Solutions
                </div>
                <p className="consulting__boxContent margin-para">
                  A load balancer is a device that acts as a reverse proxy and
                  distributes network or application traffic across several
                  servers. Load balancers are used to increase capacity
                  (concurrent users) and reliability of applications. 
                </p>
                <div className="universal__box">
                  <div>
                    <div className="consulting__box cyberRow">
                      <div className="consulting__boxImage">
                        <img src="https://silverleaf.nssplindia.com/static/media/diagram.2a6d4848e3864a0ab10f.gif" />
                      </div>
                      <div className="consulting__textArea">
                        <div className="consulting__boxHeading">Features</div>

                        <div className="cyberSecurity__features-ul">
                          <ul>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p> Flexibility</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Scalability</p>
                            </li>
                            {/* </ul>
                          <ul> */}
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Redundancy</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Reduced Downtime</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* <div className="consulting__box cyberRow">
                      <div className="consulting__boxImage">
                        <img src="https://silverleaf.nssplindia.com/static/media/rocket.40329a34c4c138180450.gif" />
                      </div>
                      <div className="consulting__textArea">
                        <div className="consulting__boxHeading">
                          Strategic OEM
                        </div>

                        <div className="cyberSecurity__features-ul">
                          <ul>
                            <li>
                              <img src={f5} alt="f5" />
                            </li>
                            <br />
                            <div className="consulting__boxHeading">
                              Other OEMs
                            </div>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Azure Application Gateway</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Azure Traffic Manager</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Citrix ADC</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Barracuda Load Balancer ADC</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Radware Alteon</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div> */}
                  </div>

                  <div className="universal__imageContainer cyberSecurity__sideImg">
                    <img
                      src={load_balancing_sol}
                      className="casbpng__style dlpPng"
                      alt="load_balancing_sol"
                    />
                  </div>
                </div>
              </div>

              <div className="consulting nssplSection">
                <div className="consulting__title heading__title">
                  VAPT Assessment
                </div>

                <div className="consulting__container">
                  <div className="consulting__box singleRow">
                    {/* <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/locked.706a53bf199126e054b1.gif" />
                    </div> */}
                    <div className="consulting__textArea">
                      <p className="consulting__boxContent margin-para">
                        Under our VAPT service, we test complete IT
                        infrastructure (Mobile/Web App, Servers, Networks,
                        Desktops/Laptops, APIs, CRM/ERP) of an organization. We
                        recommend solutions against all the vulnerabilities and
                        do re-testing after the organization’s engineering team
                        patches all those vulnerabilities.
                      </p>
                    </div>
                  </div>

                  <div className="">
                    <img
                      src={vapt_assessment}
                      alt="vapt_assessment"
                      className="security_std"
                    />
                  </div>

                  <div className="consulting__title heading__title">
                    We follow the industry's best security standards like:
                  </div>
                  <div className="">
                    <img
                      src={security_std}
                      alt="security_std"
                      className="security_std"
                    />
                  </div>
                  <div className="">
                    <img
                      src={vapt_methodology}
                      alt="vapt_methodology"
                      className="security_std"
                    />
                  </div>
                </div>
              </div>
              <div className="universal nssplSection">
                <div className="consulting__title heading__title">
                  Cloud Access Security Broker (CASB)
                </div>
                <p className="consulting__boxContent margin-para">
                  With CASB, Organizations can confidently adopt cloud
                  applications and services – without sacrificing security.
                  Manage the unintentional or unapproved movement of sensitive
                  data between cloud app instances and in the context of app
                  risk and user risk with Netskope’s industry leading cloud
                  security solution.
                </p>
                <div className="universal__box">
                  <div>
                    <h3>Safely enable users anywhere</h3>
                    <div className="universal__list">
                      <div className="universal__listItem">
                        <span className="ti-arrow-circle-right"></span>
                        Eliminate blind spots: With Patented Netskope Cloud XD™,
                        get deeper understanding to quickly target and control
                        activities across thousands of cloud (SaaS and IaaS)
                        services and millions of websites.
                      </div>

                      <div className="universal__listItem">
                        <span className="ti-arrow-circle-right"></span>
                        Secure managed and unmanaged cloud services: Secure
                        managed cloud services like Microsoft 365, GSuite and
                        AWS, while safely enabling unmanaged, business-led cloud
                        services without the need for blocking.
                      </div>

                      <div className="universal__listItem">
                        <span className="ti-arrow-circle-right"></span>
                        Guard sensitive data with award-winning DLP: Netskope
                        DLP and introspection enables the protection of
                        sensitive content that matches DLP profiles. Includes
                        pre-defined DLP profiles for regulatory compliance.
                      </div>

                      <div className="universal__listItem">
                        <span className="ti-arrow-circle-right"></span>
                        Stop elusive cloud threats and malware: Protect against
                        malware, advanced threats, and cloud-enabled threats
                        with anti-malware, sandboxing, ML analysis, and more.
                      </div>
                    </div>
                    {/* <div className="consulting__box singleRow">
                      <div className="consulting__boxImage">
                        <img src="https://silverleaf.nssplindia.com/static/media/rocket.40329a34c4c138180450.gif" />
                      </div>
                      <div className="consulting__textArea">
                        <div className="consulting__boxHeading">
                          Strategic OEM
                        </div>

                        <div className="cyberSecurity__features-ul">
                          <ul>
                            <li>
                              <img src={netskope} alt="netskope" />
                            </li>
                            <br />
                            <div className="consulting__boxHeading">
                              Other OEMs
                            </div>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Zscalar</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Forcepoint</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Palo Alto</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="universal__imageContainer cyberSecurity__sideImg">
                    <img src={casbpng} className="casbpng__style casbpng" />
                  </div>
                </div>
              </div>
              <div className="universal nssplSection">
                <div className="consulting__title heading__title">
                  Network Access Control (NAC)
                </div>
                <p className="consulting__boxContent margin-para">
                  Network Access Control (NAC) is a security approach that
                  enforces policies and controls to manage and secure access to
                  network resources. It helps organizations ensure that only
                  authorized and compliant devices and users can connect to
                  their networks, while providing visibility and control over
                  network access.
                </p>
                <div className="universal__box">
                  <div>
                    <div className="consulting__box cyberRow">
                      <div className="consulting__boxImage">
                        <img src="https://silverleaf.nssplindia.com/static/media/diagram.2a6d4848e3864a0ab10f.gif" />
                      </div>
                      <div className="consulting__textArea">
                        <div className="consulting__boxHeading">Features</div>

                        <div className="cyberSecurity__features-ul">
                          <ul>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Dot1x Authentication</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Device Profiling</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Device Health Check</p>
                            </li>

                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Policy based Access Control</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Guest Access Control</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* <div className="consulting__box cyberRow">
                      <div className="consulting__boxImage">
                        <img src="https://silverleaf.nssplindia.com/static/media/rocket.40329a34c4c138180450.gif" />
                      </div>
                      <div className="consulting__textArea">
                        <div className="consulting__boxHeading">
                          Strategic OEM
                        </div>

                        <div className="cyberSecurity__features-ul">
                          <ul>
                            <li>
                              <img src={aruba} alt="aruba" />
                            </li>
                            <br />
                            <div className="consulting__boxHeading">
                              Other OEMs
                            </div>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Forescout</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Fortinet</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Cisco</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="universal__imageContainer cyberSecurity__sideImg">
                    <img src={nacpng} className="casbpng__style" />
                  </div>
                </div>
              </div>
              <div className="universal nssplSection">
                <div className="consulting__title heading__title">
                  Security Information & Event Management (SIEM)
                </div>
                <p className="consulting__boxContent margin-para">
                  Security Information and Event Management (SIEM) is a
                  centralized solution for security management that involves the
                  collection, analysis, and correlation of security events and
                  log data from various sources within an organization's
                  network. SIEM systems provide real-time monitoring, threat
                  detection, incident response, and compliance reporting
                  capabilities.
                </p>
                <div className="universal__box">
                  <div>
                    <div className="consulting__box cyberRow">
                      <div className="consulting__boxImage">
                        <img src="https://silverleaf.nssplindia.com/static/media/diagram.2a6d4848e3864a0ab10f.gif" />
                      </div>
                      <div className="consulting__textArea">
                        <div className="consulting__boxHeading">Features</div>

                        <div className="cyberSecurity__features-ul">
                          <ul>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Log Collection and Aggregation</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Event Correlation and Analysis</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Threat Detection and Alerts</p>
                            </li>

                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Incident Response and Workflow Management</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Forensic Analysis and Investigation</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Compliance and Reporting</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Integration and Data Enrichment</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* <div className="consulting__box cyberRow">
                      <div className="consulting__boxImage">
                        <img src="https://silverleaf.nssplindia.com/static/media/rocket.40329a34c4c138180450.gif" />
                      </div>
                      <div className="consulting__textArea">
                        <div className="consulting__boxHeading">
                          Strategic OEM
                        </div>

                        <div className="cyberSecurity__features-ul">
                          <ul>
                            <li>
                              <img
                                src={siem_strategic_oem}
                                alt="siem_strategic_oem"
                              />
                            </li>
                            <br />
                            <div className="consulting__boxHeading">
                              Other OEMs
                            </div>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Qradar</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Splunk</p>
                            </li>
                            <li>
                              <i className="ti-arrow-circle-right"></i>{" "}
                              <p>Sumologic</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="universal__imageContainer cyberSecurity__sideImg">
                    <img src={siempng} className="casbpng__style dlpPng" />
                  </div>
                </div>
              </div>
            </div>

            <div className={"row"}>
              <div className="col col-md-3 col-md-pull-9 hide-desktop">
                <div className="service-sidebar">
                  <div className="widget service-list-widget">
                    <ul>
                      <li>
                        <Link to="/service" style={{ textDecoration: "none" }}>
                          All Service
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/softwareLicenseAdvisory"
                          style={{ textDecoration: "none" }}
                        >
                          Software Licensing Advisory
                        </Link>
                      </li>
                      {/* <li><a href="content-engineering.html">Software Licensing Advisory</a></li> */}
                      <li className="current">
                        <Link
                          to="/itSecurity"
                          style={{ textDecoration: "none" }}
                        >
                          CyberSecurity Solutions
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          to="/itInfrastructure"
                          style={{ textDecoration: 'none' }}
                        >
                          Infrastructure Modernization Solutions
                        </Link>
                      </li> */}
                      <li>
                        <Link
                          to="/softwareAssetManagement"
                          style={{ textDecoration: "none" }}
                        >
                          Managed SAM (Software Asset Management)
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/servicethreed"
                          style={{ textDecoration: "none" }}
                        >
                          Technology Evangelization
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/servicefour"
                          style={{ textDecoration: "none" }}
                        >
                          Procurement Optimization
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="widget download-widget">
                    <ul>
                      <li>
                        <a
                          href="/assets/file/Silver_Leaf_Solutions_Who_Are_We.pdf"
                          download="Silver_Leaf_Solutions_Who_Are_We.pdf"
                        >
                          <i className="ti-zip"></i>Company Profile
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="widget contact-widget">
                    <div>
                      <h5>
                        We are Software Licensing Advisory<span>Experts</span>
                      </h5>
                      <a href="/contact">Contact with us</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>

      <script src="assets/js/jquery.min.js"></script>
      <script src="assets/js/bootstrap.min.js"></script>

      {/* <!-- Plugins for this template --> */}
      <script src="assets/js/jquery-plugin-collection.js"></script>

      {/* <!-- Custom script for this template --> */}
      <script src="assets/js/script.js"></script>
    </div>
  );
}

export default CyberSecuritySol;
