import AppConstants from "../constants";
import AxiosInstance from "./http.handlers";
let url = AppConstants.ServerConstants.API_ROUTES 

export const GetAllBanners = async () => {
    return await AxiosInstance.get(
    url.BANNERS.GET_BANNER,
    )
      .then((response) => response)
      .catch((error) => error);
  };
  