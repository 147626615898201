import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Footer from '../Component/Footer';
import Navbar from '../Component/Navbar';
import OurServices from '../Component/OurServices';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Addpartner } from '../handelrs/partnerWithUs.handler';
function PartnerWithus() {
  const phoneRegExp = RegExp(
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  );
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [userInfo, setUserInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    companyName: '',
    typeOfBusiness: '',
    country: '',
    state: '',
    city: '',
    address: '',
    companyRevenue: '',

    employees: '',
    url: '',
  });
  const [kindOfPartnership, setKindOfPartnership] = useState('');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // window.scrollTo(0, 0);
  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUserInfo({ ...userInfo, [name]: value });
  };
  const handleSelect = (e) => {
    setKindOfPartnership(e.target.value);
  };
  const saveInformations = () => {
    setIsLoadingMore(true);
    if (
      userInfo.firstName !== '' &&
      userInfo.lastName !== '' &&
      userInfo.email !== '' &&
      userInfo.phoneNumber !== '' &&
      phoneRegExp.test(userInfo.phoneNumber)
    ) {
      const data = {
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        email: userInfo.email,
        phoneNumber: userInfo.phoneNumber,
        companyName: userInfo.companyName,
        typeOfBusiness: userInfo.typeOfBusiness,
        country: userInfo.country,
        state: userInfo.state,
        city: userInfo.city,
        address: userInfo.address,
        companyRevenue: userInfo.companyRevenue,
        kindOfPartnership: kindOfPartnership,
        employees: userInfo.employees,
        url: userInfo.url,
      };
      Addpartner(data)
        .then((resp) => {
          if (resp.data.status === 200) {
            setIsLoadingMore(false);

            toast.info(
              `Your request has been received successfully, kindly allow us sometime to get in touch with you.
            `,
              {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
            setUserInfo({
              firstName: '',
              lastName: '',
              email: '',
              phoneNumber: '',
              companyName: '',
              typeOfBusiness: '',
              country: '',
              state: '',
              city: '',
              address: '',
              companyRevenue: '',
              kindOfPartnership: '',
              employees: '',
              url: '',
            });
          } else {
            setIsLoadingMore(false);
            toast.info(`${resp.data.message}`, {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          // setUserInfo.firstName("");
        })
        .catch((error) => {
          console.log('error');
        });
    } else if (userInfo.firstName === '') {
      setIsLoadingMore(false);
      toast.error('Please provide First Name', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (userInfo.lastName === '') {
      setIsLoadingMore(false);
      toast.error('Please provide Last Name', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (userInfo.email === '') {
      setIsLoadingMore(false);
      toast.error('Please provide Email', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (userInfo.phoneNumber === '') {
      setIsLoadingMore(false);
      toast.error('Please select Phone Number', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (userInfo.companyName === '') {
      setIsLoadingMore(false);
      toast.error('Please select Company Name', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (userInfo.country === '') {
      setIsLoadingMore(false);
      toast.error('Please select Country', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (!phoneRegExp.test(userInfo.phoneNumber)) {
      setIsLoadingMore(false);
      toast.error('Please enter valid Phone Number', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setIsLoadingMore(false);
      toast.error('Error', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    // setUserInfo("");
  };
  return (
    <>
      <div className="page-wrapper">
        {/* <!-- Start he?ader --> */}
        <header id="header" className="site-header header-style-1">
          <Navbar />
        </header>
        {/* <!-- end of header --> */}

        {/* <!-- start page-title --> */}
        <section className="page-title-partner-with-us">
          <div className="container">
            <div className="row">
              <div className="col col-xs-12">
                <h2 style={{ textTransform: 'uppercase' }}>Partner With Us</h2>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- end page-title --> */}

        {/* <!-- start contact-section --> */}
        <section className="contact-section contact-section-s2 section-padding">
          <div className="container">
            {/* <div className="row">
              <div className="col col-xs-12">
                <div className="section-title-s5">
                  <h2> Partner With Us</h2>
                </div>
              </div>
            </div> */}
            <div className="row">
              <div className="col col-lg-8 col-lg-offset-2">
                <div className="content-area">
                  {/* <p>
                    Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur
                    ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus.
                    Maecenas tempus, tellus eget condimentum rhoncus, sem quam
                  </p> */}
                  <div className="contact-form">
                    <form
                      method="post"
                      className="contact-validation-active"
                      id="contact-form-main"
                      onsubmit="event.preventDefault()"
                    >
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          name="firstName"
                          id="name"
                          placeholder="First Name*"
                          value={userInfo.firstName}
                          onChange={handleInput}
                        />
                      </div>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          name="lastName"
                          id="name"
                          placeholder="Last Name*"
                          value={userInfo.lastName}
                          onChange={handleInput}
                        />
                      </div>
                      <div>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          id="email"
                          placeholder="Email*"
                          value={userInfo.email}
                          onChange={handleInput}
                        />
                      </div>

                      <div>
                        <input
                          type="text"
                          className="form-control"
                          name="phoneNumber"
                          id="phone"
                          placeholder="Phone*"
                          value={userInfo.phoneNumber}
                          onChange={handleInput}
                        />
                      </div>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          name="companyName"
                          id="companyName"
                          placeholder="Company Name*"
                          value={userInfo.companyName}
                          onChange={handleInput}
                        />
                      </div>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          name="typeOfBusiness"
                          id="business"
                          placeholder="Type Of Business"
                          value={userInfo.typeOfBusiness}
                          onChange={handleInput}
                        />
                      </div>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          name="country"
                          id="country"
                          placeholder="Country*"
                          value={userInfo.country}
                          onChange={handleInput}
                        />
                      </div>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          name="state"
                          id="state"
                          placeholder="State"
                          value={userInfo.state}
                          onChange={handleInput}
                        />
                      </div>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          name="city"
                          id="city"
                          placeholder="City"
                          value={userInfo.city}
                          onChange={handleInput}
                        />
                      </div>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          name="address"
                          id="address"
                          placeholder="Address"
                          value={userInfo.address}
                          onChange={handleInput}
                        />
                      </div>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          name="companyRevenue"
                          id="companyRevenue"
                          placeholder="Company Revenue"
                          value={userInfo.companyRevenue}
                          onChange={handleInput}
                        />
                      </div>
                      <div>
                        {/* <input
                          type="text"
                          className="form-control"
                          name="kindOfPartnership"
                          id="kindOfPartnership"
                          placeholder="What kind of partnership"
                          value={kindOfPartnership}
                          onChange={handleInput}
                        /> */}
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          onChange={handleSelect}
                        >
                          <option disabled="true" selected>
                            What kind of partnership
                          </option>
                          <option value="Business Development Refral Partnership">
                            Business Development Refral Partnership
                          </option>
                          <option value="White Lable Service Partner">
                            White Lable Service Partner
                          </option>
                          <option value="Reseller of Products & Services">
                            Reseller of Products & Services
                          </option>
                          <option value="Distributor of Products & Services">
                            Distributor of Products & Services
                          </option>
                        </select>
                      </div>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          name="employees"
                          id="employees"
                          placeholder="Employees"
                          value={userInfo.employees}
                          onChange={handleInput}
                        />
                      </div>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          name="url"
                          id="url"
                          placeholder="URL"
                          value={userInfo.url}
                          onChange={handleInput}
                        />
                      </div>

                      {/* <div>
                        <select name="subject" className="form-control">
                          <option disabled="disabled" selected>
                            Contact subject
                          </option>
                          <option>Subject 1</option>
                          <option>Subject 2</option>
                          <option>Subject 3</option>
                        </select>
                      </div> */}
                      {/* <div className="fullwidth">
                        <textarea
                          className="form-control"
                          name="note"
                          id="note"
                          placeholder="Case Description..."
                        ></textarea>
                      </div> */}
                      <div className="submit-area">
                        <button
                          type="button"
                          className="theme-btn submit-btn"
                          onClick={saveInformations}
                        >
                          Submit Now
                        </button>
                        <div id="loader">
                          <i className="ti-reload"></i>
                        </div>
                      </div>
                      <div className="clearfix error-handling-messages">
                        <div id="success">Thank you</div>
                        <div id="error">
                          {' '}
                          Error occurred while sending email. Please try again
                          later.{' '}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- end contact-section --> */}

        {/* <!-- start contact-map-section --> */}

        {/* <!-- end contact-map-section --> */}

        <ToastContainer />
        {/* <!-- start site-footer --> */}
        <Footer />
        {/* <!-- end site-footer --> */}
      </div>
    </>
  );
}

export default PartnerWithus;
