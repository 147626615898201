import React, { useEffect, useState } from 'react';
import Footer from '../Component/Footer';
import Navbar from '../Component/Navbar';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import ReactHtmlParser from 'html-react-parser';
import getYouTubeID from 'get-youtube-id';
import { Link } from 'react-router-dom';
import { GetAllBlogs } from '../handelrs/blogs.handler';
import { ScriptFile } from '../constants/server.constants';
import YoutubeIframe from './Youtube.ifram';
function Blogs() {
  const [allBlogs, setAllBlogs] = useState([]);
  const [page, setPage] = React.useState(1);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, [page]);
  useEffect(() => {
    window.scrollTo(0, 0);
    // getDataCase();
    const script = document.createElement('script');

    script.src = ScriptFile;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const handlePageClick = (event, newPage) => {
    setPage(newPage);
  };
  const data = {
    page: page,
    limit: 20,
  };
  const getData = async () => {
    setLoading(true);
    GetAllBlogs(data).then((resp) => {
      if (resp.data.status === 200) {
        setAllBlogs(resp.data.data.blogList);
        setCount(resp.data.data.totalPages);
        setLoading(false);
      }
    });
  };
  return (
    <div>
      <div className="page-wrapper">
        {loading && (
          <div className="preloader">
            <div className="sk-cube-grid">
              <div className="sk-cube sk-cube1"></div>
              <div className="sk-cube sk-cube2"></div>
              <div className="sk-cube sk-cube3"></div>
              <div className="sk-cube sk-cube4"></div>
              <div className="sk-cube sk-cube5"></div>
              <div className="sk-cube sk-cube6"></div>
              <div className="sk-cube sk-cube7"></div>
              <div className="sk-cube sk-cube8"></div>
              <div className="sk-cube sk-cube9"></div>
            </div>
          </div>
        )}

        <header id="header" className="site-header header-style-1">
          <Navbar />
        </header>

        <section className="page-title-blog">
          <div className="container">
            <div className="row">
              <div className="col col-xs-12">
                <h2>BLOG</h2>
              </div>
            </div>
          </div>
        </section>

        <section className="blog-pg-section blog-pg-fullwidth section-padding">
          <div className="container">
            <div className="row">
              <div className="col col-md-10 col-md-offset-1">
                <div className="blog-content">
                  {allBlogs &&
                    allBlogs.map((value) => {
                      return value.video == undefined &&
                        value.embedPostLink == undefined ? (
                        <div
                          className="post format-standard-image"
                          key={value._id}
                        >
                          <div className="entry-media">
                            <img
                              src={value.image}
                              alt="blog"
                              style={{
                                width: '540px',
                                height: '300px',
                                objectFit: 'cover',
                              }}
                            />
                          </div>
                          <ul className="entry-meta">
                            <li>
                              <i className="ti-time"></i>
                              {new Date(value.updatedAt).toLocaleDateString(
                                'en-us',
                                {
                                  year: 'numeric',
                                  month: 'short',
                                  day: 'numeric',
                                }
                              )}
                            </li>
                            <li>
                              <i className="ti-book"></i>
                              {value.category}
                            </li>
                            {/* <li>
                              <a href="#">
                                <i className="ti-comment-alt"></i> 5
                              </a>
                            </li> */}
                          </ul>
                          <h3>{value.title}</h3>
                          <p style={{ textAlign: 'left' }}>
                            {' '}
                            {value &&
                            value.description &&
                            value.description.length < 130
                              ? ReactHtmlParser(value.description)
                              : ''}
                            {value &&
                            value.description &&
                            value.description.length > 200
                              ? ReactHtmlParser(
                                  value.description.slice(0, 200) + '...'
                                )
                              : ''}
                          </p>
                          <Link
                            to={`/singleblog/${value._id}`}
                            className="theme-btn"
                          >
                            Read More
                          </Link>
                        </div>
                      ) : value.video !== undefined &&
                        value.image !== '' &&
                        value.embedPostLink == undefined ? (
                        <div className="post format-video">
                          <div className="entry-media video-holder">
                            <img
                              src={value.image}
                              alt="blog"
                              style={{
                                width: '540px',
                                height: '300px',
                                objectFit: 'cover',
                              }}
                            />

                            {/* <a
                              href={` https://www.youtube.com/embed/${getYouTubeID(
                                `(${value.video})`
                              )}?autoplay=1`}
                              className="video-btn"
                              data-type="iframe"
                            >
                              <i className="fi flaticon-play-button-2"></i>
                            </a> */}

                            {value.video && (
                              <YoutubeIframe video={value.video} />
                            )}
                          </div>
                          <ul className="entry-meta">
                            <li>
                              <i className="ti-time"></i>
                              {new Date(value.updatedAt).toLocaleDateString(
                                'en-us',
                                {
                                  year: 'numeric',
                                  month: 'short',
                                  day: 'numeric',
                                }
                              )}
                            </li>
                            <li>
                              <i className="ti-book"></i>
                              {value.category}
                            </li>
                            {/* <li>
                              <a href="#">
                                <i className="ti-comment-alt"></i> 5
                              </a>
                            </li> */}
                          </ul>
                          <h3>{value.title}</h3>
                          <p style={{ textAlign: 'left' }}>
                            {' '}
                            {value &&
                            value.description &&
                            value.description.length < 130
                              ? ReactHtmlParser(value.description)
                              : ''}
                            {value &&
                            value.description &&
                            value.description.length > 200
                              ? ReactHtmlParser(
                                  value.description.slice(0, 200) + '...'
                                )
                              : ''}
                          </p>
                          <Link
                            to={`/singleblog/${value._id}`}
                            className="theme-btn"
                          >
                            Read More
                          </Link>
                        </div>
                      ) : (
                        <>
                          {
                            <>
                              {' '}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: value.embedPostLink,
                                }}
                                className="iframe-container"
                                style={{ height: '100%' }}
                              />
                              <p className="space-after-blog"></p>
                            </>
                          }
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>

          {/* <Stack style={{ alignItems: 'center', marginTop: '32px' }}>
            <Pagination
              count={count}
              onChange={handlePageClick}
              variant="outlined"
              color="primary"
            />
          </Stack> */}
        </section>

        {/* <!-- start news-letter-section --> */}
        {/* <section className="news-letter-section">
          <div className="container">
            <div className="row">
              <div className="col col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
                <div className="newsletter">
                  <h3>Email Newsletter</h3>
                  <p>
                    Aenean leo ligula porttitor eu consequat vitae eleifend ac
                    enim. Aliquam lorem ante dapibus in viverra quiss consequat
                    vitae
                  </p>
                  <div className="newsletter-form">
                    <form>
                      <div>
                        <input type="text" className="form-control" />
                        <button type="submit">Subscrib</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <!-- end news-letter-section --> */}

        <Footer />
      </div>
    </div>
  );
}

export default Blogs;
