import React, { useEffect, useState } from 'react';
import Footer from '../Component/Footer';
import Navbar from '../Component/Navbar';
import { _getDateTime } from '../constants/app.constants';
import ReactHtmlParser from 'html-react-parser';
import getYouTubeID from 'get-youtube-id';
import { useParams } from 'react-router-dom';
import { GetBlogsById } from '../handelrs/blogs.handler';
import YoutubeIframe from './Youtube.ifram';
function Blogs() {
  const params = useParams();
  const [allBlogs, setAllBlogs] = useState([]);
  const [page, setPage] = React.useState(1);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, [page]);
  const handlePageClick = (event, newPage) => {
    setPage(newPage);
  };
  const data = {
    page: page,
    limit: 6,
  };
  const getData = async () => {
    setLoading(true);
    GetBlogsById(params.id, data).then((resp) => {
      if (resp.data.status === 200) {
        setAllBlogs(resp.data.data);
        setCount(resp.data.count);
        setLoading(false);
      }
    });
  };
  return (
    <div>
      <div className="page-wrapper">
        {loading && (
          <div className="preloader">
            <div className="sk-cube-grid">
              <div className="sk-cube sk-cube1"></div>
              <div className="sk-cube sk-cube2"></div>
              <div className="sk-cube sk-cube3"></div>
              <div className="sk-cube sk-cube4"></div>
              <div className="sk-cube sk-cube5"></div>
              <div className="sk-cube sk-cube6"></div>
              <div className="sk-cube sk-cube7"></div>
              <div className="sk-cube sk-cube8"></div>
              <div className="sk-cube sk-cube9"></div>
            </div>
          </div>
        )}

        <header id="header" className="site-header header-style-1">
          <Navbar />
        </header>

        <section className="page-title">
          <div className="container">
            <div className="row">
              <div className="col col-xs-12">
                <h2>{allBlogs.title}</h2>
              </div>
            </div>
          </div>
        </section>

        <section className="blog-pg-section blog-pg-fullwidth section-padding">
          <div className="container">
            <div className="row">
              <div className="col col-md-10 col-md-offset-1">
                <div className="blog-content">
                  {allBlogs?.video == '' || allBlogs?.video == undefined ? (
                    <div className="post format-standard-image">
                      <div className="entry-media">
                        <img
                          src={allBlogs.image}
                          alt="blog"
                          style={{
                            width: '500px',
                            height: '400px',
                            objectFit: 'cover',
                          }}
                        />
                      </div>

                      <ul className="entry-meta">
                        <li>
                          <i className="ti-time"></i>
                          {_getDateTime(allBlogs.dateTime, 'MMM Do YYYY')}
                          {/* {new Date(allBlogs.dateTime).toLocaleDateString(
                              'en-us',
                              {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                              }
                            )} */}
                        </li>
                        <li>
                          <i className="ti-book"></i>
                          {allBlogs.category}
                        </li>
                        {/* <li>
                          <a href="#">
                            <i className="ti-comment-alt"></i> 5
                          </a>
                        </li> */}
                      </ul>
                      <h3>{allBlogs.title}</h3>
                      <p style={{ textAlign: 'left' }}>
                        {allBlogs &&
                          allBlogs.description &&
                          ReactHtmlParser(allBlogs.description)}
                      </p>
                      {/* <a href="#" className="theme-btn">
                        Read More
                      </a> */}
                    </div>
                  ) : (
                    <div className="post format-video">
                      <div className="entry-media video-holder">
                        <img
                          src={allBlogs.image}
                          alt="blog"
                          style={{
                            width: '500px',
                            height: '300px',
                            objectFit: 'cover',
                          }}
                        />
                        {/* <a
                          href={` https://www.youtube.com/embed/${getYouTubeID(
                            `(${allBlogs.video})`
                          )}?autoplay=1`}
                          className="video-btn"
                          data-type="iframe"
                        >
                          <i className="fi flaticon-play-button-2"></i>
                        </a> */}

                        <YoutubeIframe video={allBlogs.video} />
                      </div>
                      <ul className="entry-meta">
                        <li>
                          <i className="ti-time"></i>
                          {_getDateTime(allBlogs.dateTime, 'MMM Do YYYY')}
                          {/* {new Date(allBlogs.dateTime).toLocaleDateString(
                              'en-us',
                              {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                              }
                            )} */}
                        </li>
                        <li>
                          <i className="ti-book"></i>
                          {allBlogs.category}
                        </li>
                        {/* <li>
                          <a href="#">
                            <i className="ti-comment-alt"></i> 5
                          </a>
                        </li> */}
                      </ul>
                      <h3>{allBlogs.title}</h3>
                      <p style={{ textAlign: 'left' }}>
                        {allBlogs &&
                          allBlogs.description &&
                          ReactHtmlParser(allBlogs.description)}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- start news-letter-section --> */}
        {/* <section className="news-letter-section">
          <div className="container">
            <div className="row">
              <div className="col col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
                <div className="newsletter">
                  <h3>Email Newsletter</h3>
                  <p>
                    Aenean leo ligula porttitor eu consequat vitae eleifend ac
                    enim. Aliquam lorem ante dapibus in viverra quiss consequat
                    vitae
                  </p>
                  <div className="newsletter-form">
                    <form>
                      <div>
                        <input type="text" className="form-control" />
                        <button type="submit">Subscrib</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <!-- end news-letter-section --> */}

        <Footer />
      </div>
    </div>
  );
}

export default Blogs;
