import logo from "./logo.svg";
import "./App.css";
import Home from "./pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./pages/About";
import Service from "./pages/Service";
import SoftwareLicenseAdvisory from "./pages/SoftwareLicenseAdvisory";
import SoftwareAssetManagement from "./pages/SoftwareAssetManagement";
import Servicethreed from "./pages/Servicethreed";
import Servicefour from "./pages/Servicefour";
import Contact from "./pages/Contact";
import Projects from "./pages/Projects";
import SingleProject from "./Component/SingleProject";
import PartnerWithUs from "./pages/PartnerWithUs";
import Careers from "./pages/Careers";
import ApplyNow from "./pages/ApplyNow";
import SingleCareer from "./pages/SingleCareer";
import Blogs from "./pages/Blogs";
import SingleBlog from "./pages/SingleBlog";
import Privacy from "./pages/Privacy";
import Team from "./pages/Team";
import IT_security from "./pages/IT_security";
import IT_Infrastructure from "./pages/IT_Infrastructure";
import CyberSecuritySol from "./pages/CyberSecuritySol";
import withClearCache from "./ClearCache";
import OurPartners from "./pages/OurPartners";
import Gallery from "./pages/Gallery";

// import AppRoutes from "./routes";
const ClearCacheComponent = withClearCache(MainApp);
function App() {
  return <ClearCacheComponent />;
}
function MainApp() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/service" element={<Service />} />
            <Route
              path="/softwareLicenseAdvisory"
              element={<SoftwareLicenseAdvisory />}
            />
            <Route path="/itSecurity" element={<IT_security />} />
            <Route path="/cyberSecurity" element={<CyberSecuritySol />} />
            <Route path="/itInfrastructure" element={<IT_Infrastructure />} />
            <Route
              path="/softwareAssetManagement"
              element={<SoftwareAssetManagement />}
            />
            <Route path="/servicethreed" element={<Servicethreed />} />
            <Route path="/servicefour" element={<Servicefour />} />
            <Route path="/partnerwithus" element={<PartnerWithUs />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/singleproject/:id" element={<SingleProject />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/applyNow/:id" element={<ApplyNow />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/singleblog/:id" element={<SingleBlog />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/team" element={<Team />} />
            <Route path="/partnerships" element={<OurPartners />} />
            <Route path="/gallery" element={<Gallery />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
