import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Component/Navbar";
import "./servicess.css";
import consulting from "../../src/assets/gif/consulting.gif";
import optimization from "../../src/assets/gif/optimization.gif";
import evangelization from "../../src/assets/gif/evangelization.gif";
import management from "../../src/assets/gif/management.gif";
function OurServices() {
  return (
    <div>
      <section className="services-section-s2 section-padding">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <div className="section-title">
                {/* <span>Our services</span>
                <h2>Services We Provide</h2> */}
                <h2>Our services</h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col col-xs-12">
              <div className="services-grid clearfix">
                <div className="grid">
                  <div>
                    <img src={consulting} className="gif_style" />
                    {/* <i className="fi flaticon-cloud-computing"></i> */}
                  </div>
                  <h4>
                    {" "}
                    <Link
                      to="/softwareLicenseAdvisory"
                      style={{ textDecoration: "none" }}
                    >
                      Software Licensing Advisory
                    </Link>
                  </h4>
                  <div className="wpb_wrapper">
                    <ul>
                      <li>Evaluation of Existing Licensing Portfolio</li>
                      <li>Licensing Transparency and Cost Reduction</li>
                      <li>Contract Evaluation &amp; Optimization Services</li>
                      <li>Alignment of Software Spend with IT Roadmap</li>
                      <li>Benchmarking RFP and Negotiation Support</li>
                    </ul>
                  </div>
                </div>

                {/* <div className="grid">
                  <div>
                    <img src={evangelization} className="gif_style" />
                  </div>
                  <h4>
                    <Link to="/itSecurity" style={{ textDecoration: 'none' }}>
                      Security Solutions
                    </Link>
                  </h4>
                  <div className="wpb_wrapper">
                    <ul>
                      <li>Solution Discovery &amp; Evaluation</li>
                      <li>Solution Architecture &amp; Design</li>
                      <li>IT Roadmap – Design &amp; Advisory Services</li>
                      <li>Cloud Services Design &amp; Transition</li>
                      <li>Migration Assistance</li>
                    </ul>
                  </div>
                </div> */}

                {/* <div className="grid">
                        <div >
                            <i className="fi flaticon-capital"></i>
                        </div>
                        <h4><a href="#">Capital Merkating</a></h4>
                        <p>Hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis </p>
                    </div> */}
                {/* <div className="grid">
                        <div >
                            <i className="fi flaticon-fraud"></i>
                        </div>
                        <h4><a href="#">Data Security</a></h4>
                        <p>Hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis </p>
                    </div> */}

                {/* <div className="grid">
                  <div>
                    <img src={optimization} className="gif_style" />
                  </div>
                  <h4>
                    <Link
                      to="/itInfrastructure"
                      style={{ textDecoration: 'none' }}
                    >
                      Infrastructure Modernization Solutions
                    </Link>
                  </h4>
                  <div className="wpb_wrapper">
                    <ul>
                      <li>
                        Tail End / Industry Specific Software Procurement
                        Services
                      </li>
                      <li>Real Time Online Portal for Software Licenses</li>
                      <li>Price Benchmarking Services</li>
                      <li>Tax Optimized Sourcing</li>
                      <li>Supplier Contract Management</li>
                    </ul>
                  </div>
                </div> */}
                <div className="grid">
                  <div>
                    <img src={management} className="gif_style" />
                    {/* <i className="fi flaticon-copywriting"></i> */}
                  </div>
                  <h4>
                    <Link
                      to="/softwareAssetManagement"
                      style={{ textDecoration: "none" }}
                    >
                      Managed SAM (Software Asset Management)
                    </Link>
                  </h4>
                  <div className="wpb_wrapper">
                    <ul>
                      <li>
                        Managed SAM (Software Asset Management)- Standard &amp;
                        Recurring
                      </li>
                      <li>Software Audit Protection Services</li>
                      <li>Risk Reduction &amp; Compliance</li>
                      <li>Asset Management Roadmap &amp; Vision</li>
                      <li>Application Usage Monitoring (used for renewals)</li>
                    </ul>
                  </div>
                </div>
                <div className="grid">
                  <div>
                    <img src={evangelization} className="gif_style" />
                    {/* <i className="fi flaticon-security"></i> */}
                  </div>
                  <h4>
                    <Link
                      to="/servicethreed"
                      style={{ textDecoration: "none" }}
                    >
                      Technology Evangelization
                    </Link>
                  </h4>
                  <div className="wpb_wrapper">
                    <ul>
                      <li>Solution Discovery &amp; Evaluation</li>
                      <li>Solution Architecture &amp; Design</li>
                      <li>IT Roadmap – Design &amp; Advisory Services</li>
                      <li>Cloud Services Design &amp; Transition</li>
                      <li>Migration Assistance</li>
                    </ul>
                  </div>
                </div>
                <div className="grid">
                  <div>
                    {/* <i className="fi flaticon-database"></i> */}
                    <img src={optimization} className="gif_style" />
                  </div>
                  <h4>
                    <Link to="/servicefour" style={{ textDecoration: "none" }}>
                      Procurement Optimization
                    </Link>
                  </h4>
                  <div className="wpb_wrapper">
                    <ul>
                      <li>
                        Tail End / Industry Specific Software Procurement
                        Services
                      </li>
                      <li>Real Time Online Portal for Software Licenses</li>
                      <li>Price Benchmarking Services</li>
                      <li>Tax Optimized Sourcing</li>
                      <li>Supplier Contract Management</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="grid max-list">
                <div>
                  <img src={evangelization} className="gif_style" />
                </div>
                <h4>
                  <Link to="/cyberSecurity" style={{ textDecoration: "none" }}>
                    CyberSecurity Solutions
                  </Link>
                </h4>
                <div className="wpb_wrapper wpb_wrapper_list">
                  <ul>
                    <li>Next-Generation Firewall</li>
                    <li>Data Loss Prevention (DLP)</li>
                    <li>Network Access Control (NAC)</li>
                    <li>Multi Factor Authentication (MFA)</li>
                    <li> Cloud Access Security Broker (CASB)</li>
                    <li>End Point Detection & Response (EDR) </li>
                  </ul>
                  <ul>
                    <li> VAPT Assessments</li>
                    <li>Security Remote Access</li>
                    <li>Load Balancing Solutions</li>
                    <li>Web Application & API Protection</li>
                    <li> Security Information & Event Management (SIEM)</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col col-xs-12">
                <div className="all-service">
                    <p>If you wnat to know more details about services Please check <Link to="/service" style={{textDecoration:"none"}}>All Service</Link></p>
                </div>
            </div>
        </div> */}
        </div>
        {/* <!-- end container --> */}
      </section>
    </div>
  );
}

export default OurServices;
