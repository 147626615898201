import React, { useEffect } from "react";
import Footer from "../Component/Footer";
import Navbar from "../Component/Navbar";
import { Link } from "react-router-dom";
import cost_optimization from "../../src/assets/gif/cost-optimization.gif";
import asset_management from "../../src/assets/gif/asset-management.gif";
import guaranteed_savings from "../../src/assets/gif/guaranteed-savings.gif";
import governance from "../../src/assets/gif/building.gif";
import consultancy_support from "../../src/assets/gif/consultancy-support.gif";
import precise_planning from "../../src/assets/gif/precise-planning.gif";
import transparent_process from "../../src/assets/gif/transparent-process.gif";
import security from "../../src/assets/gif/security.gif";
import knowledge_base from "../../src/assets/gif/knowledge-base.gif";
import highly from "../../src/assets/gif/highly-experienced.gif";
import euro from "../../src/assets/gif/euro.gif";
import unused_Software from "../../src/assets/gif/line-chart.gif";
import consulting from "../../src/assets/gif/consulting.gif";
import optimization_config from "../../src/assets/gif/bar-chart.gif";
import management from "../../src/assets/gif/management.gif";
import { ScriptFile } from "../constants/server.constants";

function SoftwareLicenseAdvisory() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    // getDataCase();
    const script = document.createElement("script");

    script.src = ScriptFile;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div>
      <div className="page-wrapper">
        {/* <!-- start preloader --> */}
        {/* <div className="preloader">
    <div className="sk-cube-grid">
      <div className="sk-cube sk-cube1"></div>
      <div className="sk-cube sk-cube2"></div>
      <div className="sk-cube sk-cube3"></div>
      <div className="sk-cube sk-cube4"></div>
      <div className="sk-cube sk-cube5"></div>
      <div className="sk-cube sk-cube6"></div>
      <div className="sk-cube sk-cube7"></div>
      <div className="sk-cube sk-cube8"></div>
      <div className="sk-cube sk-cube9"></div>
    </div>
</div> */}
        {/* <!-- end preloader --> */}

        {/* <!-- Start heade?r --> */}
        <header id="header" className="site-header header-style-1">
          <Navbar />
        </header>
        {/* <!-- end of header --> */}

        {/* <!-- start page-title --> */}
        <section
          className="page-title-services


        "
        >
          <div className="container">
            <div className="row">
              <div className="col col-xs-12">
                <h2 style={{ textTransform: "uppercase" }}>
                  Software Licensing Advisory
                </h2>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- end page-title --> */}

        {/* <!-- start service-single-section --> */}
        <section className="service-single-section section-padding">
          <div className="container">
            <div className="row">
              <div className="col col-md-9 col-md-push-3">
                <div className="service-single-content">
                  {/* <div className="service-single-img">
                      <img
                          src="assets/images/service-single/software-licensing-advisory.jpg"
                          alt="software license"
                      />
                    </div> */}
                  {/*<h2 style={{ textTransform: 'uppercase' }}>*/}
                  {/*  Software Licensing Advisory*/}
                  {/*</h2>*/}
                  <div className="benefit clearfix">
                    <div className="consulting__title heading__title top__heading">
                      Concept behind the Advocacy | Demystifying & Enabling
                    </div>
                    <div className="details list-img">
                      <div className="img-holder">
                        <img
                          src="assets/images/service-single/managed_services.jpg"
                          alt="software license"
                        />
                      </div>
                      <ul>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>
                            {" "}
                            Structuring of Global Software Licensing Contracts
                          </p>
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>{" "}
                          <p>Mergers, Acquisitions & Divestitures</p>
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>{" "}
                          <p>Cost Optimization</p>
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>
                            Independent Advisory on Technology Evangelization
                          </p>
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>
                            Governance, Compliance, and Control on Software
                            Licenses
                          </p>
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>Licensing Training</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="consulting__title heading__title top__heading mt30">
                    {/*CONSULTING & ADVISORY <br />*/}
                    We Are Uniquely Positioned For The Advocacy
                  </div>

                  <div className="consulting__container">
                    <div className="consulting__box">
                      <div className="consulting__boxImage">
                        <img src="https://silverleaf.nssplindia.com/static/media/cost-optimization.a2c612bf35eef1334267.gif" />
                      </div>
                      <div className="consulting__textArea">
                        <div className="consulting__boxHeading">
                          Holistic Advocacy
                        </div>
                        <div className="consulting__boxContent">
                          Across Multiple OEM/Publisher software licensing
                          contracts that impacts the client budgets
                          significantly
                        </div>
                      </div>
                    </div>

                    <div className="consulting__box">
                      <div className="consulting__boxImage">
                        <img src="https://silverleaf.nssplindia.com/static/media/guaranteed-savings.7c8a1fede70162c60b3a.gif" />
                      </div>
                      <div className="consulting__textArea">
                        <div className="consulting__boxHeading">
                          Guaranteed Savings
                        </div>
                        <div className="consulting__boxContent">
                          Guaranteed Savings on Existing/Planned Spends with an
                          Outcome Based Service Fee Model option assuring Full
                          Compliance & Risk mitigation
                        </div>
                      </div>
                    </div>

                    <div className="consulting__box">
                      <div className="consulting__boxImage">
                        <img src="https://silverleaf.nssplindia.com/static/media/building.5ab8b5f4b75cb41291a2.gif" />
                      </div>
                      <div className="consulting__textArea">
                        <div className="consulting__boxHeading">
                          Unbiased Advocacy
                        </div>
                        <div className="consulting__boxContent">
                          Delivery approach with total isolation from the
                          OEM/Publisher or the Channel involved
                        </div>
                      </div>
                    </div>

                    <div className="consulting__box">
                      <div className="consulting__boxImage">
                        <img src="https://silverleaf.nssplindia.com/static/media/security.12aee98969a1cf5c30c6.gif" />
                      </div>
                      <div className="consulting__textArea">
                        <div className="consulting__boxHeading">Flexible</div>
                        <div className="consulting__boxContent">
                          Can be engaged at any Stage of the Lifecycle of the
                          Licensing Contract
                        </div>
                      </div>
                    </div>

                    <div className="consulting__box">
                      <div className="consulting__boxImage">
                        <img src="https://silverleaf.nssplindia.com/static/media/knowledge-base.28c0accc7c18331e1b64.gif" />
                      </div>
                      <div className="consulting__textArea">
                        <div className="consulting__boxHeading">
                          Knowledge Base
                        </div>
                        <div className="consulting__boxContent">
                          Post delivery, Managed Services through Global Center
                          of Knowledge
                        </div>
                      </div>
                    </div>

                    <div className="consulting__box">
                      <div className="consulting__boxImage">
                        <img src="https://silverleaf.nssplindia.com/static/media/highly-experienced.ac7ea053089a9e3425c1.gif" />
                      </div>
                      <div className="consulting__textArea">
                        <div className="consulting__boxHeading">
                          Highly Experienced
                        </div>
                        <div className="consulting__boxContent">
                          The Perfect Subject Matter experts with decades of
                          experience serving the finest names in the industry
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-md-3 col-md-pull-9 hide-phone">
                <div className="service-sidebar">
                  <div className="widget service-list-widget">
                    <ul>
                      <li>
                        <Link to="/service" style={{ textDecoration: "none" }}>
                          All Service
                        </Link>
                      </li>
                      <li className="current">
                        <Link
                          to="/softwareLicenseAdvisory"
                          style={{ textDecoration: "none" }}
                        >
                          Software Licensing Advisory
                        </Link>
                      </li>
                      {/* <li><a href="content-engineering.html">Software Licensing Advisory</a></li> */}
                      <li>
                        <Link
                          to="/cyberSecurity"
                          style={{ textDecoration: "none" }}
                        >
                          CyberSecurity Solutions
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          to="/itInfrastructure"
                          style={{ textDecoration: 'none' }}
                        >
                          Infrastructure Modernization Solutions
                        </Link>
                      </li> */}
                      <li>
                        <Link
                          to="/softwareAssetManagement"
                          style={{ textDecoration: "none" }}
                        >
                          Managed SAM (Software Asset Management)
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/servicethreed"
                          style={{ textDecoration: "none" }}
                        >
                          Technology Evangelization
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/servicefour"
                          style={{ textDecoration: "none" }}
                        >
                          Procurement Optimization
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="widget download-widget">
                    <ul>
                      <li>
                        <a
                          // href="/assets/file/Software_Licensing_Advisory.pdf"
                          href="/assets/file/Licensing_Advisory__Cost_Optimization_Services__Latest.pdf"
                          download="Licensing_Advisory__Cost_Optimization_Services__Latest.pdf"
                        >
                          <i className="ti-zip"></i>Company Profile
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="widget contact-widget">
                    <div>
                      <h5>
                        We are Software Licensing Advisory<span>Experts</span>
                      </h5>
                      <a href="/contact">Contact with us</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="containerFluid">
              <div className="consulting nssplSection"></div>

              <div className="universal nssplSection">
                <div className="consulting__title heading__title">
                  Need For Licencing Advisory And Cost Optimization Advisory
                  Services
                </div>

                <div className="universal__box">
                  <div className="universal__imageContainer">
                    <img
                      src="/assets/images/service-single/businesswoman-explaining-infographic-elements-sheet-her-male-partner.jpg"
                      style={{
                        width: "100%",
                        height: "340px",
                        objectFit: "cover",
                        // paddingLeft: '20px',
                      }}
                    />
                  </div>

                  <div className="universal__list">
                    <div className="universal__listItem">
                      <span className="ti-arrow-circle-right"></span>
                      Cost Optimization
                    </div>

                    <div className="universal__listItem">
                      <span className="ti-arrow-circle-right"></span>
                      Shrinking IT budgets
                    </div>

                    <div className="universal__listItem">
                      <span className="ti-arrow-circle-right"></span>
                      {/* Number of Software OEMs */}
                      Growing Number of Software OEMs
                    </div>

                    <div className="universal__listItem">
                      <span className="ti-arrow-circle-right"></span>
                      Quick Technology Changes
                    </div>

                    <div className="universal__listItem">
                      <span className="ti-arrow-circle-right"></span>
                      Legal Uncertainty
                    </div>

                    <div className="universal__listItem">
                      <span className="ti-arrow-circle-right"></span>
                      Increasing Client Expectations
                    </div>

                    <div className="universal__listItem">
                      <span className="ti-arrow-circle-right"></span>
                      Company Growth
                    </div>

                    <div className="universal__listItem">
                      <span className="ti-arrow-circle-right"></span>
                      Process Efficiency and Governance
                    </div>

                    <div className="universal__listItem">
                      <span className="ti-arrow-circle-right"></span>
                      Tax Optimized Purchasing
                    </div>

                    <div className="universal__listItem">
                      <span className="ti-arrow-circle-right"></span>
                      {/* Change Management */}
                      Real Time Compliance across proprietory and open source
                    </div>
                  </div>
                </div>
              </div>

              <div className="greenBox nssplSection pt60">
                <div className="greenBox__boxList">
                  <div className="greenBox__box">
                    <div className="greenBox__boxTitle">
                      <div className="greenBox__titleImage fi flaticon-cloud-computing"></div>
                      <div className="greenBox__titleText">
                        Licensing Contract Renewals
                      </div>
                    </div>
                    <div className="greenBox__content">
                      <div className="greenBox__contentList">
                        <div className="greenBox__contentListItem">
                          <span className="ti-arrow-circle-right"></span>
                          The earlier the better
                        </div>
                        <div className="greenBox__contentListItem">
                          <span className="ti-arrow-circle-right"></span>
                          Engagement Can Also Be Mid-Term Of The Software
                          Licensing Contract
                        </div>
                        <div className="greenBox__contentListItem">
                          <span className="ti-arrow-circle-right"></span>
                          Ideally 3-4 months before the renewal (expiry date of
                          the current contract)
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="greenBox__box">
                    <div className="greenBox__boxTitle">
                      <div className="greenBox__titleImage fi flaticon-copywriting"></div>
                      <div className="greenBox__titleText">
                        One Time New Order
                      </div>
                    </div>
                    <div className="greenBox__content">
                      <div className="greenBox__contentList">
                        <div className="greenBox__contentListItem">
                          <span className="ti-arrow-circle-right"></span>
                          M&A
                        </div>

                        <div className="greenBox__contentListItem">
                          <span className="ti-arrow-circle-right"></span>
                          Divestitures
                        </div>
                        <div className="greenBox__contentListItem">
                          <span className="ti-arrow-circle-right"></span>
                          Adhoc Order
                        </div>
                        <div className="greenBox__contentListItem">
                          <span className="ti-arrow-circle-right"></span>
                          Upcoming True Ups
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="greenBox__box">
                    <div className="greenBox__boxTitle">
                      <div className="greenBox__titleImage fi flaticon-security"></div>
                      <div className="greenBox__titleText">
                        Upcoming / Deciding New Contracts
                      </div>
                    </div>
                    <div className="greenBox__content">
                      <div className="greenBox__contentList">
                        <div className="greenBox__contentListItem">
                          <span className="ti-arrow-circle-right"></span>
                          Evaluation of Procurement under a New Long Term
                          Contract
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="greenBox__box">
                    <div className="greenBox__boxTitle">
                      <div className="greenBox__titleImage fi flaticon-database"></div>
                      <div className="greenBox__titleText">
                        Restructuring of Contracts
                      </div>
                    </div>
                    <div className="greenBox__content">
                      <div className="greenBox__contentList">
                        <div className="greenBox__contentListItem">
                          <span className="ti-arrow-circle-right"></span>
                          While planning to restructure existing perpetual
                          licensing contracts to cloud based licensing
                          contracts/models
                        </div>
                        <div className="greenBox__contentListItem">
                          <span className="ti-arrow-circle-right"></span>
                          To Incorporate Cloud Based Licensing Models
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="greenBox nssplSection">
                {/* <div className="greenBox__title">
                  The Earlier The Better. However, Engagement Can Also Be
                  Mid-Term Of The Software Licensing Contract.
                </div> */}
                <div className="consulting__title heading__title">
                  Dimensions of Cost Optimizaton
                </div>
                <div className="greenBox__boxList">
                  <div className="greenBox__box">
                    <div className="greenBox__boxTitle">
                      <div className="greenBox__titleImage fi flaticon-cloud-computing"></div>
                      <div className="greenBox__titleText">
                        Contract Evaluation
                      </div>
                    </div>
                    <div className="greenBox__content">
                      <div className="greenBox__contentList">
                        <div className="greenBox__contentListItem">
                          <span className="ti-arrow-circle-right"></span>
                          Evaluating the type of customer contract & the SKUs in
                          the agreement
                        </div>

                        <div className="greenBox__contentListItem">
                          <span className="ti-arrow-circle-right"></span>
                          Licensing Model evangelization – Perpetual vs
                          Subscription vs Cloud
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="greenBox__box">
                    <div className="greenBox__boxTitle">
                      <div className="greenBox__titleImage fi flaticon-copywriting"></div>
                      <div className="greenBox__titleText">
                        BOM Optimization
                      </div>
                    </div>
                    <div className="greenBox__content">
                      <div className="greenBox__contentList">
                        <div className="greenBox__contentListItem">
                          <span className="ti-arrow-circle-right"></span>
                          This includes decision required around products as per
                          various user profiles
                        </div>

                        <div className="greenBox__contentListItem">
                          <span className="ti-arrow-circle-right"></span>
                          Workload & Deployment Assessment Reports
                        </div>

                        <div className="greenBox__contentListItem">
                          <span className="ti-arrow-circle-right"></span>
                          Aligning the Customer Technology Roadmap to the OEM
                          Licensing Roadmap of 3/5 year
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="greenBox__box">
                    <div className="greenBox__boxTitle">
                      <div className="greenBox__titleImage fi flaticon-security"></div>
                      <div className="greenBox__titleText">
                        Licensing Exceptions
                      </div>
                    </div>
                    <div className="greenBox__content">
                      <div className="greenBox__contentList">
                        <div className="greenBox__contentListItem">
                          <span className="ti-arrow-circle-right"></span>
                          Discount offered
                        </div>

                        <div className="greenBox__contentListItem">
                          <span className="ti-arrow-circle-right"></span>
                          Contract term
                        </div>

                        <div className="greenBox__contentListItem">
                          <span className="ti-arrow-circle-right"></span>
                          Maximizing Grants
                        </div>

                        <div className="greenBox__contentListItem">
                          <span className="ti-arrow-circle-right"></span>
                          Custom profiles
                        </div>

                        <div className="greenBox__contentListItem">
                          <span className="ti-arrow-circle-right"></span>
                          Consumption & User Rights
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="greenBox__box">
                    <div className="greenBox__boxTitle">
                      <div className="greenBox__titleImage fi flaticon-database"></div>
                      <div className="greenBox__titleText">
                        Restructuring of Contracts
                      </div>
                    </div>
                    <div className="greenBox__content">
                      <div className="greenBox__contentList">
                        <div className="greenBox__contentListItem">
                          <span className="ti-arrow-circle-right"></span>
                          Bundling & Un-bundling of Products
                        </div>

                        <div className="greenBox__contentListItem">
                          <span className="ti-arrow-circle-right"></span>
                          Evaluation of Alternate technologies to keep the OEM
                          team fair
                        </div>
                        <div className="greenBox__contentListItem">
                          <span className="ti-arrow-circle-right"></span>
                          To Incorporate Cloud Based Licensing Models
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="greenBox__box">
                    <div className="greenBox__boxTitle">
                      <div className="greenBox__titleImage fi flaticon-copywriting"></div>
                      <div className="greenBox__titleText">
                        Price Benchmarking & Negotiations
                      </div>
                    </div>
                    <div className="greenBox__content">
                      <div className="greenBox__contentList">
                        <div className="greenBox__contentListItem">
                          <span className="ti-arrow-circle-right"></span>
                          Benchmark discounting against revenue
                        </div>

                        <div className="greenBox__contentListItem">
                          <span className="ti-arrow-circle-right"></span>
                          Back-end Rebates transparency
                        </div>

                        <div className="greenBox__contentListItem">
                          <span className="ti-arrow-circle-right"></span>
                          Local Accelerators
                        </div>

                        <div className="greenBox__contentListItem">
                          <span className="ti-arrow-circle-right"></span>
                          Negotiating on the final BOM for prices & discounts
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="greenBox__box">
                    <div className="greenBox__boxTitle">
                      <div className="greenBox__titleImage fi flaticon-cloud-computing"></div>
                      <div className="greenBox__titleText">Price Arbitrage</div>
                    </div>
                    <div className="greenBox__content">
                      <div className="greenBox__contentList">
                        <div className="greenBox__contentListItem">
                          <span className="ti-arrow-circle-right"></span>
                          Leveraging massive price differential in base prices
                          in emerging vs developed markets
                        </div>

                        <div className="greenBox__contentListItem">
                          <span className="ti-arrow-circle-right"></span>
                          Tax Optimization & Price Arbitrage are other important
                          levers
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="consulting nssplSection">
                <div className="consulting__title heading__title">
                  Engagement Methodology
                </div>

                <div className="consulting__container">
                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/euro.5d717e7fae97ac64a3ab.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">Scoping</div>
                      <div className="consulting__boxContent">
                        Business Discovery Workshop IT Discovery Workshop –
                        Environment Purchase History and Entitlements (SAM
                        Checkup)
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/line-chart.ae23a3ced4a621229e3c.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">Assessment</div>
                      <div className="consulting__boxContent">
                        Publisher License Analysis – Product Licensing Specific
                        Workload Optimization Assessment Workload Optimization
                        Assessment
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/consulting.0e0be23e6d9c942f7083.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">Roadmap</div>
                      <div className="consulting__boxContent">
                        Agreements Discussion – Options, Exceptions, & Scenarios
                        Pricing & Goal Variance Agreement on Final Construct &
                        Commercial Goals
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/bar-chart.c17d17201cb54c72ecb8.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">Design</div>
                      <div className="consulting__boxContent">
                        Build Negotiation Strategy & Roles Role plays & Rehearse
                        the Strategy Tax & Price Arbitrage
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/management.76a05a61fca7b16aa7c3.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Implementation
                      </div>
                      <div className="consulting__boxContent">
                        Contract Closure & Sign off Follow the Sun Global
                        Licensing Helpdesk Managed Services & Asset Management
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="consulting nssplSection">
                <div className="consulting__title heading__title">
                  What We Stay Away From
                </div>

                <div className="consulting__container">
                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/cost-optimization.a2c612bf35eef1334267.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Calling The OEM For Better Discounts
                      </div>
                      <div className="consulting__boxContent">
                        We do not indulge in becoming just another transaction
                        partner, leaving this to the customer
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/asset-management.3f003261fef002b0d2e7.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Negotiating On Your Compliance
                      </div>
                      <div className="consulting__boxContent">
                        We ensure Full Compliance while bringing the best out of
                        a contract/deal for you
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/security.12aee98969a1cf5c30c6.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        We Don’t Challenge The Intellect Of The SCM / Purchase
                        Office Team
                      </div>
                      <div className="consulting__boxContent">
                        We respect the authority of the Customer’s SCM /
                        Procurement offices & duly understand their wide
                        experience in engaging with large contracts
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/building.5ab8b5f4b75cb41291a2.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Committing Any Revenue Number For Any Of The OEMs
                      </div>
                      <div className="consulting__boxContent">
                        We strategically don’t have agreed partnership contracts
                        with any of the OEMs which asks us to drive revenue
                        numbers for them
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="consulting nssplSection">
                <div className="consulting__title heading__title">
                  Offer On The Table
                </div>

                <div className="consulting__container odd">
                  <div className="consulting__box">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/cost-optimization.a2c612bf35eef1334267.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Cost Optimization
                      </div>
                      <div className="consulting__boxContent">
                        Reduction of Cash Outflow; Guaranteed Savings
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/asset-management.3f003261fef002b0d2e7.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Smart Structuring
                      </div>
                      <div className="consulting__boxContent">
                        Global Software Licensing Contracts Structuring through
                        Scientific model
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/security.12aee98969a1cf5c30c6.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Advisory & Support
                      </div>
                      <div className="consulting__boxContent">
                        Alternative Technology Evaluation and Solution Pricing
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/transparent-process.e873a494683fda955451.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Transparent Process
                      </div>
                      <div className="consulting__boxContent">
                        Build transparency around discounts, negotiations, and
                        exceptions
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/precise-planning.520b462827cfbf49831b.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Precise Planning
                      </div>
                      <div className="consulting__boxContent">
                        Build Right Licensing model
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/building.5ab8b5f4b75cb41291a2.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Tactile Approach
                      </div>
                      <div className="consulting__boxContent">
                        Develop flexibility to reduce dependency on
                        contract/lock in
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/consultancy-support.d9094f69ccdf2c733e1a.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Consultancy Support
                      </div>
                      <div className="consulting__boxContent">
                        Mergers, Acquisitions, and Divestitures
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/line-chart.ae23a3ced4a621229e3c.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Streamlining BOM
                      </div>
                      <div className="consulting__boxContent">
                        BOM Optimization (Mid-term / Renewal time)
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/bar-chart.c17d17201cb54c72ecb8.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">Hassle Free</div>
                      <div className="consulting__boxContent">
                        Compliance and Audit Protection
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="universal nssplSection">
                <div className="universal__title heading__title">
                  How We Are Different ? <br />
                  Market Competition
                </div>

                <div className="universal__box reverse">
                  <div className="universal__imageContainer">
                    <img
                      src="/assets/images/service-single/human-resources-concept-with-hand.jpg"
                      style={{
                        width: "100%",
                        height: "340px",
                        objectFit: "cover",
                        // paddingLeft: '20px',
                      }}
                    />
                  </div>

                  <div className="universal__list ">
                    <div className="universal__listItem singleRow">
                      <span className="ti-arrow-circle-right"></span>
                      Due to their sales targets, they are not able to provide
                      unbiased decisions
                    </div>

                    <div className="universal__listItem singleRow">
                      <span className="ti-arrow-circle-right"></span>
                      Mergers, Acquisitions & Divestitures
                    </div>

                    <div className="universal__listItem singleRow">
                      <span className="ti-arrow-circle-right"></span>
                      Cost Optimization
                    </div>

                    <div className="universal__listItem singleRow">
                      <span className="ti-arrow-circle-right"></span>
                      Independent Advisory on Technology Evangelization
                    </div>

                    <div className="universal__listItem singleRow">
                      <span className="ti-arrow-circle-right"></span>
                      Governance, Compliance, and Control on Software Licenses
                    </div>

                    <div className="universal__listItem singleRow">
                      <span className="ti-arrow-circle-right"></span>
                      Licensing Training
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={"row"}>
              <div className="col col-md-3 col-md-pull-9 hide-desktop">
                <div className="service-sidebar">
                  <div className="widget service-list-widget">
                    <ul>
                      <li>
                        <Link to="/service" style={{ textDecoration: "none" }}>
                          All Service
                        </Link>
                      </li>
                      <li className="current">
                        <Link
                          to="/softwareLicenseAdvisory"
                          style={{ textDecoration: "none" }}
                        >
                          Software Licensing Advisory
                        </Link>
                      </li>
                      {/* <li><a href="content-engineering.html">Software Licensing Advisory</a></li> */}
                      <li>
                        <Link
                          to="/cyberSecurity"
                          style={{ textDecoration: "none" }}
                        >
                          CyberSecurity Solutions
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          to="/itInfrastructure"
                          style={{ textDecoration: 'none' }}
                        >
                          Infrastructure Modernization Solutions
                        </Link>
                      </li> */}
                      <li>
                        <Link
                          to="/softwareAssetManagement"
                          style={{ textDecoration: "none" }}
                        >
                          Managed SAM (Software Asset Management)
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/servicethreed"
                          style={{ textDecoration: "none" }}
                        >
                          Technology Evangelization
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/servicefour"
                          style={{ textDecoration: "none" }}
                        >
                          Procurement Optimization
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="widget download-widget">
                    <ul>
                      <li>
                        <a
                          href="/assets/file/Silver_Leaf_Solutions_Who_Are_We.pdf"
                          download="Silver_Leaf_Solutions_Who_Are_We.pdf"
                        >
                          <i className="ti-zip"></i>Company Profile
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="widget contact-widget">
                    <div>
                      <h5>
                        We are Software Licensing Advisory<span>Experts</span>
                      </h5>
                      <a href="/contact">Contact with us</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- end service-single-section --> */}

        {/* <!-- start news-letter-section --> */}
        {/* <section className="news-letter-section">
          <div className="container">
            <div className="row">
              <div className="col col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
                <div className="newsletter">
                  <h3>Email Newsletter</h3>
                  <p>
                    Aenean leo ligula porttitor eu consequat vitae eleifend ac
                    enim. Aliquam lorem ante dapibus in viverra quiss consequat
                    vitae
                  </p>
                  <div className="newsletter-form">
                    <form>
                      <div>
                        <input type="text" className="form-control" />
                        <button type="submit">Subscrib</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </section> */}
        {/* <!-- end news-letter-section --> */}

        {/* <!-- start site-footer --> */}
        <Footer />
        {/* <footer className="site-footer">
    <div className="upper-footer">
        <div className="container">
            <div className="row">
                <div className="separator"></div>
                <div className="col col-lg-4 col-md-3 col-sm-6">
                    <div className="widget about-widget">
                        <div className="logo widget-title">
                            <img src="assets/images/footer-logo.png" alt="software license">
                        </div>
                        <p>Condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar</p>
                        <ul>
                            <li><a href="#"><i className="ti-facebook"></i></a></li>
                            <li><a href="#"><i className="ti-twitter-alt="software license""></i></a></li>
                            <li><a href="#"><i className="ti-linkedin"></i></a></li>
                            <li><a href="#"><i className="ti-pinterest"></i></a></li>
                            <li><a href="#"><i className="ti-youtube"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div className="col col-lg-3 col-md-3 col-sm-6">
                    <div className="widget contact-widget service-link-widget">
                        <div className="widget-title">
                            <h3>Contact</h3>
                        </div>
                        <ul>
                            <li>54/1 New dhas sorini Asut, <br>Melbord Austria.</li>
                            <li><span>Phone:</span> 54786547521</li>
                            <li><span>Email:</span> demo@example.com</li>
                            <li><span>Office Time:</span> 9AM- 5PM</li>
                        </ul>
                    </div>
                </div>
                <div className="col col-lg-3 col-md-3 col-sm-6">
                    <div className="widget link-widget">
                        <div className="widget-title">
                            <h3>Services</h3>
                        </div>
                        <ul>
                            <li><a href="#">Managed IT services</a></li>
                            <li><a href="#">IT Support & helpdesk</a></li>
                            <li><a href="#">IT Consultancy</a></li>
                            <li><a href="#">Custom Software</a></li>
                            <li><a href="#">Cyber Security</a></li>
                        </ul>
                    </div>
                </div>
                <div className="col col-lg-2 col-md-3 col-sm-6">
                    <div className="widget link-widget">
                        <div className="widget-title">
                            <h3>Company</h3>
                        </div>
                        <ul>
                            <li><a href="#">About our company</a></li>
                            <li><a href="#">Client’s Testimonial</a></li>
                            <li><a href="#">Privacy policy</a></li>
                            <li><a href="#">Pricing and plans</a></li>
                            <li><a href="#">Cookies Policy</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div> <!-- end container -->
    </div>
    <div className="lower-footer">
        <div className="container">
            <div className="row">
                <div className="separator"></div>
                <div className="col col-xs-12">
                    <p className="copyright">Copyright &copy; 2019 netserv. All rights reserved.</p>
                </div>
            </div>
        </div>
    </div>
</footer> */}
        {/* <!-- end site-footer --> */}
      </div>

      <script src="assets/js/jquery.min.js"></script>
      <script src="assets/js/bootstrap.min.js"></script>

      {/* <!-- Plugins for this template --> */}
      <script src="assets/js/jquery-plugin-collection.js"></script>

      {/* <!-- Custom script for this template --> */}
      <script src="assets/js/script.js"></script>
    </div>
  );
}

export default SoftwareLicenseAdvisory;
