import React from 'react';
import { useState, useEffect } from 'react';
import { GetAllAwards } from '../handelrs/awards.handler';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

const Awards = () => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [docs, setDocs] = useState([]);
  const getAwardData = () => {
    setIsLoadingMore(true);
    const data = {
      page: 1,
      limit: 15,
    };
    GetAllAwards(data).then((response) => {
      setDocs(response.data.data.awardsList);
      if (response.status === true) {
        setIsLoadingMore(false);
      }
    });
  };
  useEffect(() => {
    getAwardData();
  }, []);
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
          infinite: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          // initialSlide: 2
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };

  return (
    <>
      {docs?.length > 0 && (
        <section className="addAward-section section-padding">
          <div className="container awards-container">
            <div className="consulting__title heading__title">Awards</div>
            <div className="row">
              <Slider {...settings}>
                {docs?.map((item) => (
                  <>
                    <div className="grid" key={item.title}>
                      <div className="img-holder">
                        <img
                          src={item.image}
                          width={'400px'}
                          height={'350px'}
                        />
                      </div>
                      <div className="details">
                        <h5>{item.title}</h5>
                      </div>
                    </div>
                  </>
                ))}
              </Slider>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Awards;
