import React, { useEffect } from 'react';
import Footer from '../Component/Footer';
import Navbar from '../Component/Navbar';
import { Link } from 'react-router-dom';
import consulting from '../../src/assets/gif/consulting.gif';
import optimization_config from '../../src/assets/gif/bar-chart.gif';
import management from '../../src/assets/gif/management.gif';
import data from '../../src/assets/gif/document.gif';
import network from '../../src/assets/gif/network.gif';
import customer from '../../src/assets/gif/customer.gif';
import socialMedia from '../../src/assets/gif/social-media.gif';
import building from '../../src/assets/gif/building.gif';
import security from '../../src/assets/gif/security.gif';
import quality from '../../src/assets/gif/optimization.gif';
import search from '../../src/assets/gif/search.gif';
import folder from '../../src/assets/gif/folder.gif';
import checklist from '../../src/assets/gif/checklist.gif';
import retent from '../../src/assets/gif/software-audit.gif';
import evangelization from '../../src/assets/gif/evangelization.gif';
import rocket from '../../src/assets/gif/rocket.gif';
import complete from '../../src/assets/gif/complete.gif';
import diagram from '../../src/assets/gif/diagram.gif';
import presentation from '../../src/assets/gif/presentation.gif';
import { ScriptFile } from '../constants/server.constants';

function IT_Infrastructure() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    // getDataCase();
    const script = document.createElement('script');

    script.src = ScriptFile;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div>
      <div className="page-wrapper">
        {/* <!-- start preloader --> */}
        {/* <div className="preloader">
    <div className="sk-cube-grid">
      <div className="sk-cube sk-cube1"></div>
      <div className="sk-cube sk-cube2"></div>
      <div className="sk-cube sk-cube3"></div>
      <div className="sk-cube sk-cube4"></div>
      <div className="sk-cube sk-cube5"></div>
      <div className="sk-cube sk-cube6"></div>
      <div className="sk-cube sk-cube7"></div>
      <div className="sk-cube sk-cube8"></div>
      <div className="sk-cube sk-cube9"></div>
    </div>
</div> */}
        {/* <!-- end preloader --> */}

        {/* <!-- Start heade?r --> */}
        <header id="header" className="site-header header-style-1">
          <Navbar />
        </header>
        {/* <!-- end of header --> */}

        {/* <!-- start page-title --> */}
        <section className="page-title-services">
          <div className="container">
            <div className="row">
              <div className="col col-xs-12">
                <h2>INFRASTRUCTURE MODERNIZATION SOLUTIONS</h2>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- end page-title --> */}

        {/* <!-- start service-single-section --> */}
        <section className="service-single-section section-padding">
          <div className="container">
            <div className="row">
              <div className="col col-md-9 col-md-push-3">
                <div className="service-single-content">
                  {/* <div className="service-single-img">
                                        <img
                                            src="assets/images/service-single/infrastructure-modernization-solutions.jpg"
                                            alt="service"
                                        />
                                    </div> */}

                  {/*One Time Assessment Approach end  */}
                  {/*The earlier the better  */}
                  <div className="consulting__title heading__title">
                    IT Infrastructure | Solution Offerings
                  </div>
                  <section
                    className="services-section-s3"
                    // style={{ padding: '30px' }}
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col col-xs-12">
                          <div className="services-grid clearfix">
                            <div className="grid">
                              <div className="grid-header">
                                <i className="fi flaticon-cloud-computing"></i>
                                <h4>Active Networking</h4>
                              </div>
                              <div className="wpb_wrapper">
                                <p>
                                  <ul>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>Data Center Setup</span>
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>Network Refresh/Upgradation</span>
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>
                                        Renewals/AMC of existing services
                                      </span>
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>Digital Transformation</span>
                                    </li>
                                  </ul>
                                </p>
                              </div>
                            </div>
                            <div className="grid">
                              <div className="grid-header">
                                <i className="fi flaticon-cloud-computing"></i>
                                <h4>Storage Solutions</h4>
                              </div>
                              <div className="wpb_wrapper">
                                <p>
                                  <ul>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>100% data availability</span>
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>Data security & redundancy</span>
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>Workload optimization</span>
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>Long time data retention</span>
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>Data lakes & data warehouse</span>
                                    </li>
                                  </ul>
                                </p>
                              </div>
                            </div>

                            <div className="grid">
                              <div className="grid-header">
                                {/* <img
                                  src={evangelization}
                                  className="gif_style"
                                /> */}
                                <i className="fi flaticon-security"></i>
                                <h4>Wireless Solutions</h4>
                              </div>

                              <div className="wpb_wrapper">
                                <p>
                                  {/* Customers can get exceptions that are not
                                  known to them in the following: */}
                                  <ul>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>
                                        Office Wifi (Indoor wireless solutions)
                                      </span>
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>
                                        Campus Wifi (Outdoor wireless solutions)
                                      </span>
                                    </li>

                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>
                                        Smart Retail / Smart building / Smart
                                        City
                                      </span>
                                    </li>
                                    {/* </div> */}
                                    {/* <div> */}
                                  </ul>
                                </p>
                              </div>
                            </div>
                            <div className="grid">
                              <div className="grid-header">
                                <i className="fi flaticon-database"></i>
                                {/* <img src={optimization} className="gif_style" /> */}
                                <h4>Virtual Desktop Infrastructure</h4>
                              </div>

                              <div className="wpb_wrapper">
                                <p>
                                  <ul>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>
                                        Protect critical applications by
                                        isolation
                                      </span>
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>Safe internet browsing</span>
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>
                                        Empower Remote users & Byod users
                                      </span>
                                    </li>
                                  </ul>
                                </p>
                              </div>
                            </div>
                            <div className="grid">
                              <div className="grid-header">
                                <i className="fi flaticon-database"></i>
                                {/* <img src={optimization} className="gif_style" /> */}
                                <h4>Performance Monitoring</h4>
                              </div>

                              <div className="wpb_wrapper">
                                <p>
                                  <ul>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>Network monitoring</span>
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>Application layer analytics</span>
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>
                                        QoS (Quality of Service) monitoring
                                      </span>
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>
                                        Identifying network & application
                                        performance issues with transaction
                                        tracing
                                      </span>
                                    </li>
                                  </ul>
                                </p>
                              </div>
                            </div>
                            <div className="grid">
                              <div className="grid-header">
                                <i className="fi flaticon-database"></i>
                                {/* <img src={optimization} className="gif_style" /> */}
                                <h4>Network Access Control</h4>
                              </div>

                              <div className="wpb_wrapper">
                                <p>
                                  <ul>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>
                                        Complete visibility & zero trust
                                        security
                                      </span>
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>
                                        {' '}
                                        Pro-active control over users & devices
                                        connecting to network
                                      </span>
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>
                                        Automated security posture assessment &
                                        remediation
                                      </span>
                                    </li>
                                  </ul>
                                </p>
                              </div>
                            </div>
                            {/* <div className="grid">
                              <div className="grid-header">
                                <i className="fi flaticon-copywriting"></i>
                                <h4>Endpoint Security</h4>
                              </div>

                              <div className="wpb_wrapper">
                                <p>
                                  <ul>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      Complete cloud-based solution
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      Integrated Threat Intelligence
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      Proactive Threat Hunting
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      Gain visibility on Emerging Threats
                                    </li>
                                  </ul>
                                </p>
                              </div>
                            </div> */}
                            {/* <div className="grid">
                              <div className="grid-header">
                                <i className="fi flaticon-database"></i>
                                <h4>Multi-factor Authentication</h4>
                              </div>

                              <div className="wpb_wrapper">
                                <p>
                                  <ul>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      Allow access to only authorized users
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      Mitigate risks associated with remote
                                      access
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      Ensure compliance with regulatory norms
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      Reduces Risk from Compromised Passwords
                                    </li>
                                  </ul>
                                </p>
                              </div>
                            </div> */}
                            {/* <div className="grid">
                              <div className="grid-header">
                                <i className="fi flaticon-database"></i>
                                <h4>Network Security</h4>
                              </div>

                              <div className="wpb_wrapper">
                                <p>
                                  <ul>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      Single appliance for firewall, IPS, Web
                                      Filtering, Email Filtering and Antivirus
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      Visibility into users, hosts, apps and
                                      content on enterprise network
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      Protection against known and unknown
                                      threats
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      Zero Day Protection
                                    </li>
                                  </ul>
                                </p>
                              </div>
                            </div> */}
                            {/* <div className="grid">
                              <div className="grid-header">
                                <i className="fi flaticon-cloud-computing"></i>
                                <h4>Secure Remote Access</h4>
                              </div>

                              <div className="wpb_wrapper">
                                <p>
                                  <ul>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      Enable secure access to office PCs,
                                      internal apps from home
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      Secure access for mobile users, vendors,
                                      BYOD user
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      Monitor user behavior
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      Granular access policies
                                    </li>
                                  </ul>
                                </p>
                              </div>
                            </div> */}
                            {/* <div className="grid">
                              <div className="grid-header">
                                <i className="fi flaticon-security"></i>
                                <h4>Web Application Security</h4>
                              </div>

                              <div className="wpb_wrapper">
                                <p>
                                  <ul>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      Defend against bots
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      Prevent account takeover
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      Protect apps from DoS
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      API Security
                                    </li>
                                  </ul>
                                </p>
                              </div>
                            </div> */}
                            {/* <div className="grid">
                              <div className="grid-header">
                                <i className="fi flaticon-copywriting"></i>
                                <h4>Load Balancer</h4>
                              </div>

                              <div className="wpb_wrapper">
                                <p>
                                  <ul>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      Balance the load between the servers
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      Monitor the health status of backend
                                      server
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      Provide the secure connection between the
                                      client and server
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      Reduce the workload of backend server
                                    </li>
                                  </ul>
                                </p>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  {/*The earlier the better  */}
                  {/* <h3 className="paddingTop">
                    The earlier the better. However, engagement can also be
                    mid-term of the Software Licensing Contract.
                  </h3>
                 
                 
                
                  {/*One Time Assessment Approach end  */}
                  {/* SAM Deliverables */}
                </div>
              </div>
              <div className="col col-md-3 col-md-pull-9 hide-phone">
                <div className="service-sidebar">
                  <div className="widget service-list-widget">
                    <ul>
                      <li>
                        <Link to="/service" style={{ textDecoration: 'none' }}>
                          All Service
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/softwareLicenseAdvisory"
                          style={{ textDecoration: 'none' }}
                        >
                          Software Licensing Advisory
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/itSecurity"
                          style={{ textDecoration: 'none' }}
                        >
                          Security Solutions
                        </Link>
                      </li>
                      <li className="current">
                        <Link
                          to="/itInfrastructure"
                          style={{ textDecoration: 'none' }}
                        >
                          Infrastructure Modernization Solutions
                        </Link>
                      </li>
                      {/* <li><a href="content-engineering.html">Software Licensing Advisory</a></li> */}
                      <li>
                        <Link
                          to="/softwareAssetManagement"
                          style={{ textDecoration: 'none' }}
                        >
                          Managed SAM (Software Asset Management)
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/servicethreed"
                          style={{ textDecoration: 'none' }}
                        >
                          Technology Evangelization
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/servicefour"
                          style={{ textDecoration: 'none' }}
                        >
                          Procurement Optimization
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="widget download-widget">
                    <ul>
                      <li>
                        <a
                          href="/assets/file/Silver_Leaf_Solutions_Who_Are_We.pdf"
                          download="Silver_Leaf_Solutions_Who_Are_We.pdf"
                        >
                          <i className="ti-zip"></i>Company Profile
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="widget contact-widget">
                    <div>
                      <h5>
                        We are Software Licensing Advisory<span>Experts</span>
                      </h5>
                      <a href="/contact">Contact with us</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="containerFluid">
              <div className="consulting nssplSection">
                <div className="consulting__title heading__title">
                  Infrastructure Modernization Solutions | Active Networking
                </div>

                <div className="consulting__container">
                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/complete.14023e24c56ea288dbf9.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Data Center Setup
                      </div>
                      <div className="consulting__boxContent">
                        A data center is a physical facility that organizations
                        use to house their critical applications and data. A
                        data center's design is based on a network of computing
                        and storage resources that enable the delivery of shared
                        applications and data. The key components of a data
                        center design include routers, switches, firewalls,
                        storage systems, servers, and application-delivery
                        controllers.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/virus.2676197b6281b8e0dfd7.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Network Refresh/Upgradation
                      </div>
                      <div className="consulting__boxContent">
                        A network refresh is designed to make sure that all of
                        your networking equipment is updated, supported and
                        maintained. You don't want to be stuck with old
                        equipment that the original manufacturer has stopped
                        supporting, as you may find yourself at increased risk
                        of cyberthreats and attacks.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/search.c616487775916d05b720.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Renewals/AMC Of Existing Services
                      </div>
                      <div className="consulting__boxContent">
                        AMC in IT/Networking is Annual Maintenance Contract for
                        the IT softwares or hardwares procured by an
                        organization. An AMC is an agreement with a service
                        provider for repair and maintenance of property used by
                        your company. Support renewals can be on yearly basis/
                        3-year basis/ 5-year basis.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/security.12aee98969a1cf5c30c6.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Digital Transformation
                      </div>
                      <div className="consulting__boxContent">
                        Digital transformation is the adoption of digital
                        technology by an organization. Common goals for its
                        implementation are to improve efficiency, value or
                        innovation. It enables businesses to modernize legacy
                        processes, accelerate efficient workflows, strengthen
                        security, and increase profitability.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="consulting nssplSection">
                <div className="consulting__title heading__title">
                  Infrastructure Modernization Solutions | Wireless Solutions
                </div>

                <div className="consulting__container">
                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/customer.bc65d71ed7ddd370c539.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Office Wifi (Indoor Wireless Solutions)
                      </div>
                      <div className="consulting__boxContent">
                        Indoor wireless planning can be defined as the process
                        to plan and optimise wireless networks that use indoor
                        antennas and transmitters to deliver and improve indoor
                        coverage and capacity. Indoor wireless planning aims at
                        delivering a seamless wireless (4G, 5G, WiFi, IoT…)
                        connectivity to improve coverage and capacity in those
                        challenging indoor areas. Silver Leaf services include
                        site-survey/ WiFi-planning/ WiFi-Solution/
                        Solution-Delivery/ Solution-Implementation.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/diagram.2a6d4848e3864a0ab10f.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Campus Wifi (Outdoor Wireless Solutions)
                      </div>
                      <div className="consulting__boxContent">
                        The entire campus is Wi-Fi enabled with a high-speed
                        internet connection to allow the users to access the
                        internet no-matter wherever they are. The coverage of
                        Wi-Fi is not only restricted to indoor rooms/cabins but
                        also extends to all the areas including library,
                        Auditorium, cafeteria, and hostels. Silver Leaf Solution
                        provides organizations with the end-to-end solution from
                        Design to Deployment, Secure User Authentication through
                        SMS, Effective Bandwidth distribution, traffic
                        management, Per User Log details, 24/7 back-end support.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/rocket.40329a34c4c138180450.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Smart Retail / Smart Building / Smart City
                      </div>
                      <div className="consulting__boxContent">
                        Retail WiFi networks are converting – and keeping –
                        customers. With the market for mobile apps and
                        technologies skyrocketing, savvy retailers are using
                        their WiFi network to interact with their customers on a
                        personal level. Silver Leaf offers Wifi-Solutions that
                        plays key-role in developing a building/city into
                        Smart-building/Smart-city. All the IOT devices required
                        for setting up a Smart-building/smart-city can be
                        connected wirelessly with Smart-WiFi solutions.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="consulting nssplSection">
                <div className="consulting__title heading__title">
                  Infrastructure Modernization Solutions | Performance
                  Monitoring
                </div>

                <div className="consulting__container">
                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/asset-management.3f003261fef002b0d2e7.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Network Monitoring
                      </div>
                      <div className="consulting__boxContent">
                        Silver Leaf Solution offers Network monitoring solutions
                        that are designed to monitor and manage the network
                        traffic flow over a network. It is mainly used by
                        network administrators and security staff to monitor the
                        operations of a network. It automates most of the
                        network monitoring processes and workflow.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/padlock.870fa14f218d6a19e5da.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Application Layer Analytics
                      </div>
                      <div className="consulting__boxContent">
                        Application performance monitoring extends observability
                        into applications and key network infrastructure,
                        providing granular insights on application performance &
                        application performance related issues. Centralized
                        probe-based monitoring. Insights complimenting
                        host-based approaches.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/optimization.daa65e97283b314010b8.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        QoS (Quality Of Service) Monitoring
                      </div>
                      <div className="consulting__boxContent">
                        Silver Leaf Solution offers quality of service (QoS)
                        monitoring solution to analyse your IP traffic through
                        the system. The QoS monitoring feature helps to
                        determine where congestion is occurring within your
                        network. This is not only useful during QoS planning. It
                        can also be helpful as a troubleshooting tool.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/legal.346518e574b650c321e3.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Identify Network & Application Performance Issues With
                        Transaction Tracing
                      </div>
                      <div className="consulting__boxContent">
                        Transaction monitoring, also known as business
                        transaction management, is the supervision of critical
                        business applications/services or a network by auditing
                        the individual transactions that flow across the
                        network/application infrastructure. Transaction
                        monitoring tools measure the response time performance
                        of each component, as well as the links between any of
                        the components.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="consulting nssplSection">
                <div className="consulting__title heading__title">
                  Infrastructure Modernization Solutions | Storage Solutions
                </div>

                <div className="consulting__container">
                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/locked.706a53bf199126e054b1.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        100% Data Availability
                      </div>
                      <div className="consulting__boxContent">
                        Silver Leaf Solution offering ensures that your company
                        meets its information availability requirements. Any
                        interruption to data availability jeopardizes revenue
                        generation, brand reputation and contractual
                        obligations. Avoid any loss of revenue or brand
                        reputation degradation by best-in-class storage
                        solutions offered by Silver Leaf.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/search.c616487775916d05b720.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Data Security & Redundancy
                      </div>
                      <div className="consulting__boxContent">
                        Backup-to-storage solutions offered by Silver Leaf
                        provides redundancy to your primary data storage. It
                        also adds an extra layer of security by creating a
                        backup of data thus you can always recover your data in
                        case on any ransomware attacks without having any
                        business loss. Data redundancy also enables you to avoid
                        business loss in times of any incidents like Outage/Data
                        Center Failure.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/checklist.93ec4af093387c5bb6d9.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Workload Optimization
                      </div>
                      <div className="consulting__boxContent">
                        Our storage solution allow an application or group of
                        applications (also known as workloads) to optimize the
                        underlying hardware & infrastructure/middleware layers
                        to achieve maximum performance. It improves the
                        performance, scale with ease & lowers the cost of
                        current storage infrastructure.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/bar-chart.c17d17201cb54c72ecb8.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Long Time Data Retention
                      </div>
                      <div className="consulting__boxContent">
                        Long Time Data retention is mandatory for some of the
                        organizations while for others it’s best to have
                        continued storage of organisation's data for compliance
                        or business reasons.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/bar-chart.c17d17201cb54c72ecb8.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Data Lake & Data Warehouse
                      </div>
                      <div className="consulting__boxContent">
                        Data lakes and data warehouses are both widely used for
                        storing big data, but they are not interchangeable
                        terms. A data lake is a vast pool of raw data, the
                        purpose for which is not yet defined. A data warehouse
                        is a repository for structured, filtered data that has
                        already been processed for a specific purpose. Silver
                        Leaf offers both Data Lake & Data Warehouse solutions as
                        per customers requirement.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="consulting nssplSection">
                <div className="consulting__title heading__title">
                  Infrastructure Modernization Solutions | Virtual Desktop
                  Infrastructure
                </div>

                <div className="consulting__container">
                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/building.5ab8b5f4b75cb41291a2.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Virtual Desktop Infrastructure
                      </div>
                      <div className="consulting__boxContent">
                        Control every device, apps & OS in your network to
                        prevent security breach, by virtualizing & delivering
                        desktop apps from centralized Datacenter. Using our
                        desktop and application virtualization solution, allow
                        vendors to access business apps from their own devices &
                        offices enabling cost-effective outsourcing.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/presentation.87d95ef1b7b0d1b11962.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Protect Critical Applications By Isolation
                      </div>
                      <div className="consulting__boxContent">
                        Silver Leaf’s solution offering protects your critical
                        applications by help you run critical apps in an
                        isolated environment, hiding them from users' devices
                        and networks, preventing malware attacks and data
                        leakage attempts.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/complete.14023e24c56ea288dbf9.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Safe Internet Browsing
                      </div>
                      <div className="consulting__boxContent">
                        We enables secure browsing for an organization by
                        isolating internet from corporate networks via remote
                        virtual browser, this enables a clean network and fend
                        off cyber attacks.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/evangelization.ef372834e2a4ce3eef91.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Empower Remote Users & BYOD Users
                      </div>
                      <div className="consulting__boxContent">
                        Our Solution allows employees, consultants and vendors
                        to use devices of their choice, without having to
                        compromise with security or to hinder workflow. Provide
                        anywhere, anytime access to business apps to increase
                        mobility & productivity.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="consulting nssplSection">
                <div className="consulting__title heading__title">
                  Infrastructure Modernization Solutions | Network Access
                  Control
                </div>

                <div className="consulting__container">
                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/cloud-network.d84810a37ed169af8bc8.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Network Access Control
                      </div>
                      <div className="consulting__boxContent">
                        A NAC solution provides the necessity of knowing what
                        devices IT is tasked with securing. A NAC solution can
                        identify and profile each subsequent device wanting to
                        connect, as well as: Perform health check assessments.
                        Enforce access control policies.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/folder.81c7b6bc2b7762d2a720.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Complete Visibility & Zero Trust Security
                      </div>
                      <div className="consulting__boxContent">
                        Silver Leaf offers best-in-market Network Access Control
                        solution that helps organizations have complete
                        visibility across their multi-vendor distributed
                        wired/wireless/vpn networks. Zero Trust is a security
                        framework requiring all users, whether in or outside the
                        organization's network, to be authenticated, authorized
                        & accounted for their access to organizations network.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/social-media.c425a61ef0966f7c2ac0.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Proactive Control Over Users & Devices Connecting To
                        Network
                      </div>
                      <div className="consulting__boxContent">
                        Proactive control allows an organization to control who,
                        where & when connects to their network. And what
                        parameters an endpoint should have or should not have to
                        gain complete network access or partial network access.
                        Endpoints can also be authenticated against
                        organization’s Active Directory.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/bar-chart.c17d17201cb54c72ecb8.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Automated Security Posture Assessment & Remediation
                      </div>
                      <div className="consulting__boxContent">
                        Our solution helps you to automate access control by
                        defining the device posture policies. Auto-remediation
                        features helps quarantined users (endpoints which failed
                        health-check/posture-policies) to remediate and comply
                        with the posture policy and get the network access as
                        defined by the organization. You can control the access
                        of an end device by multiple factors like – Device OS,
                        Presence of antivirus, files, registry key, services,
                        patch updates etc.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={'row'}>
              <div className="col col-md-3 col-md-pull-9 hide-desktop">
                <div className="service-sidebar">
                  <div className="widget service-list-widget">
                    <ul>
                      <li>
                        <Link to="/service" style={{ textDecoration: 'none' }}>
                          All Service
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/softwareLicenseAdvisory"
                          style={{ textDecoration: 'none' }}
                        >
                          Software Licensing Advisory
                        </Link>
                      </li>
                      {/* <li><a href="content-engineering.html">Software Licensing Advisory</a></li> */}
                      <li>
                        <Link
                          to="/itSecurity"
                          style={{ textDecoration: 'none' }}
                        >
                          Security Solutions
                        </Link>
                      </li>
                      <li className="current">
                        <Link
                          to="/itInfrastructure"
                          style={{ textDecoration: 'none' }}
                        >
                          Infrastructure Modernization Solutions
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/softwareAssetManagement"
                          style={{ textDecoration: 'none' }}
                        >
                          Managed SAM (Software Asset Management)
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/servicethreed"
                          style={{ textDecoration: 'none' }}
                        >
                          Technology Evangelization
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/servicefour"
                          style={{ textDecoration: 'none' }}
                        >
                          Procurement Optimization
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="widget download-widget">
                    <ul>
                      <li>
                        <a
                          href="/assets/file/Silver_Leaf_Solutions_Who_Are_We.pdf"
                          download="Silver_Leaf_Solutions_Who_Are_We.pdf"
                        >
                          <i className="ti-zip"></i>Company Profile
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="widget contact-widget">
                    <div>
                      <h5>
                        We are Software Licensing Advisory<span>Experts</span>
                      </h5>
                      <a href="/contact">Contact with us</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- end service-single-section --> */}

        {/* <!-- start news-letter-section --> */}
        {/* <section className="news-letter-section">
          <div className="container">
            <div className="row">
              <div className="col col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
                <div className="newsletter">
                  <h3>Email Newsletter</h3>
                  <p>
                    Aenean leo ligula porttitor eu consequat vitae eleifend ac
                    enim. Aliquam lorem ante dapibus in viverra quiss consequat
                    vitae
                  </p>
                  <div className="newsletter-form">
                    <form>
                      <div>
                        <input type="text" className="form-control" />
                        <button type="submit">Subscrib</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
       
        </section> */}
        {/* <!-- end news-letter-section --> */}

        {/* <!-- start site-footer --> */}
        <Footer />
        {/* <footer className="site-footer">
    <div className="upper-footer">
        <div className="container">
            <div className="row">
                <div className="separator"></div>
                <div className="col col-lg-4 col-md-3 col-sm-6">
                    <div className="widget about-widget">
                        <div className="logo widget-title">
                            <img src="assets/images/footer-logo.png" alt>
                        </div>
                        <p>Condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar</p>
                        <ul>
                            <li><a href="#"><i className="ti-facebook"></i></a></li>
                            <li><a href="#"><i className="ti-twitter-alt"></i></a></li>
                            <li><a href="#"><i className="ti-linkedin"></i></a></li>
                            <li><a href="#"><i className="ti-pinterest"></i></a></li>
                            <li><a href="#"><i className="ti-youtube"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div className="col col-lg-3 col-md-3 col-sm-6">
                    <div className="widget contact-widget service-link-widget">
                        <div className="widget-title">
                            <h3>Contact</h3>
                        </div>
                        <ul>
                            <li>54/1 New dhas sorini Asut, <br>Melbord Austria.</li>
                            <li><span>Phone:</span> 54786547521</li>
                            <li><span>Email:</span> demo@example.com</li>
                            <li><span>Office Time:</span> 9AM- 5PM</li>
                        </ul>
                    </div>
                </div>
                <div className="col col-lg-3 col-md-3 col-sm-6">
                    <div className="widget link-widget">
                        <div className="widget-title">
                            <h3>Services</h3>
                        </div>
                        <ul>
                            <li><a href="#">Managed IT services</a></li>
                            <li><a href="#">IT Support & helpdesk</a></li>
                            <li><a href="#">IT Consultancy</a></li>
                            <li><a href="#">Custom Software</a></li>
                            <li><a href="#">Cyber Security</a></li>
                        </ul>
                    </div>
                </div>
                <div className="col col-lg-2 col-md-3 col-sm-6">
                    <div className="widget link-widget">
                        <div className="widget-title">
                            <h3>Company</h3>
                        </div>
                        <ul>
                            <li><a href="#">About our company</a></li>
                            <li><a href="#">Client’s Testimonial</a></li>
                            <li><a href="#">Privacy policy</a></li>
                            <li><a href="#">Pricing and plans</a></li>
                            <li><a href="#">Cookies Policy</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div> <!-- end container -->
    </div>
    <div className="lower-footer">
        <div className="container">
            <div className="row">
                <div className="separator"></div>
                <div className="col col-xs-12">
                    <p className="copyright">Copyright &copy; 2019 netserv. All rights reserved.</p>
                </div>
            </div>
        </div>
    </div>
</footer> */}
        {/* <!-- end site-footer --> */}
      </div>

      <script src="assets/js/jquery.min.js"></script>
      <script src="assets/js/bootstrap.min.js"></script>

      {/* <!-- Plugins for this template --> */}
      <script src="assets/js/jquery-plugin-collection.js"></script>

      {/* <!-- Custom script for this template --> */}
      <script src="assets/js/script.js"></script>
    </div>
  );
}

export default IT_Infrastructure;
