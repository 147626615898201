import React, { useState } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import { useForm, useFormState } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { GetApplyCareer } from '../handelrs/career.handler';
import {
  AvInput,
  Card,
  CardBody,
  Col,
  Form,
  Row,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  Container,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
function SingleCareer(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [apiRes, setApiRes] = useState({
    msg: '',
    success: true,
  });
  const phoneRegExp = RegExp(
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),

    email: Yup.string().required('Email is required').email('Email is invalid'),
    // mobile: Yup.string()
    //   //.required('Mobile is required')
    //   .matches(phoneRegExp, 'Phone number is not valid'),
    mobile: Yup.string()
      .matches(phoneRegExp, 'Invalid Mobile number ')
      .required('Mobile number  is required'),

    resume: Yup.mixed()
      .required('Resume is required')

      // .test("required", "Resume is required", value => value && value.length === 0|| value === "undefined" || value === null)
      .test('fileSize', 'File Size is too large', (value) => {
        return value && value.length && value[0].size <= 5242880;
      })
      .test(
        'fileType',
        'Unsupported File Format, Upload Resume in .pdf format only',
        (value) => {
          return (
            value && value.length && ['application/pdf'].includes(value[0].type)
          );
        }
      ),
  });

  const {
    setValue,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    const formdata = new FormData();
    formdata.append('resume', data.resume[0]);
    formdata.append('name', data.name);
    formdata.append('email', data.email);
    formdata.append('mobile', data.mobile);
    formdata.append('description', data.description);

    formdata.append('jobId', props.id);
    GetApplyCareer(formdata)
      .then((res) => {
        if (res.data.status !== 200) {
          enqueueSnackbar('Something went wrong', { variant: 'danger' });
          setApiRes({
            msg: `Something went wrong! ${res.data?.message}`,
            success: false,
          });
        } else {
          enqueueSnackbar('Resume uploaded successfully', {
            variant: 'success',
          });
          setApiRes({
            msg: `user added successfully`,
            success: true,
          });
          props.onClose();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Modal isOpen={true} onRequestClose={() => {}} style={customStyles}>
        <Form
          method="post"
          className="contact-validation-active"
          id="contact-form-main"
          onSubmit={handleSubmit(onSubmit)}
        >
          <ModalHeader>
            {' '}
            Share your information with our recruiting team
          </ModalHeader>

          <ModalBody>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="name">Name</Label>
                  <input
                    name="name"
                    type="text"
                    placeholder="Name*"
                    {...register('name')}
                    className={`form-control  ${
                      errors.name ? 'is-invalid' : ''
                    }`}
                  />
                  <div className="text-danger">{errors.name?.message}</div>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="Email">Email</Label>

                  <input
                    name="email"
                    type="text"
                    placeholder="Email*"
                    {...register('email')}
                    className={`form-control ${
                      errors.email ? 'is-invalid' : ''
                    }`}
                  />
                  <div className="text-danger">{errors.email?.message}</div>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="Mobile">Mobile</Label>

                  <input
                    name="mobile"
                    type="text"
                    placeholder="mobile*"
                    {...register('mobile')}
                    className={`form-control ${
                      errors.mobile ? 'is-invalid' : ''
                    }`}
                  />
                  <div className="text-danger">{errors.mobile?.message}</div>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="Resume">Resume</Label>

                  <input
                    name="resume"
                    type="file"
                    placeholder="resume*"
                    {...register('resume')}
                    className={`form-control ${
                      errors.resume ? 'is-invalid' : ''
                    }`}
                  />
                  <div className="text-danger">{errors.resume?.message}</div>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label for="description"> Description</Label>

                  <textarea
                    name="description"
                    type="text"
                    placeholder="Description...*"
                    {...register('description')}
                    className={`form-control ${
                      errors.description ? 'is-invalid' : ''
                    }`}
                  ></textarea>
                  <div className="text-danger">
                    {errors.description?.message}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <div className="text-center"></div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary">Submit</Button>
            {props.onClose && (
              <Button type="button" onClick={props.onClose} color="secondary">
                Cancel
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
}

export default SingleCareer;
