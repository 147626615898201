import AppConstants from '../constants';
import AxiosInstance from './http.handlers';
let url = AppConstants.ServerConstants.API_ROUTES;

export const GetAllBlogs = async (body, headers) => {
  return await AxiosInstance.post(url.BLOGS.GET_BLOGS, body, {
    headers,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const GetBlogsById = async (id, data, headers) => {
  return await AxiosInstance.get(
    url.BLOGS.GET_BLOGS_BY_ID + '/' + id,

    data,
    {
      headers,
    }
  )
    .then((response) => response)

    .catch((error) => error);
};
