import { color } from "@mui/system";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import silverLeafLogo from "../assets/images/footer-logo.png";
function Navbar() {
  //

  useEffect(() => {
    const toggleMobileNavigation = () => {
      const navbar = document.querySelector(".navigation-holder");
      const openBtn = document.querySelector(".navbar-header .open-btn");
      const closeBtn = document.querySelector(
        ".navigation-holder .close-navbar"
      );
      const body = document.querySelector(".page-wrapper");

      openBtn.addEventListener("click", () => {
        if (!navbar.classList.contains("slideInn")) {
          navbar.classList.add("slideInn");
          body.classList.add("body-overlay");
        }
        return false;
      });

      closeBtn.addEventListener("click", () => {
        if (navbar.classList.contains("slideInn")) {
          navbar.classList.remove("slideInn");
        }
        body.classList.remove("body-overlay");
        return false;
      });
    };

    toggleMobileNavigation();

    return () => {
      const openBtn = document?.querySelector(".navbar-header .open-btn");
      const closeBtn = document?.querySelector(
        ".navigation-holder .close-navbar"
      );

      openBtn?.removeEventListener("click", toggleMobileNavigation);
      closeBtn?.removeEventListener("click", toggleMobileNavigation);
    };
  }, []);

  return (
    <div>
      <nav className="navigation navbar navbar-default">
        <div className="container-fluid">
          <div className="nav-row clearfix">
            <div className="navbar-header">
              <button type="button" className="open-btn">
                {/* <span className="sr-only">Toggle navigation</span> */}
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <Link
                to="/"
                style={{ textDecoration: "none" }}
                className="navbar-brand"
              >
                <img
                  src={silverLeafLogo}
                  alt="brand logo"
                  style={{ marginTop: "-26px" }}
                />
                {/* <img
                  src="https://silverleafsolutions.com/site/wp-content/uploads/2018/09/logo.png"
                  style={{ marginTop: '-26px' }}
                  alt="logo"
                /> */}
              </Link>
            </div>
            <div
              id="navbar"
              className="navbar-collapse collapse navbar-right navigation-holder"
            >
              <button className="close-navbar">
                <i className="ti-close"></i>
              </button>
              <ul className="nav navbar-nav" style={{ color: "#0b0a0a" }}>
                <li>
                  <Link
                    to="/"
                    style={{ textDecoration: "none", color: "black" }}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Home
                  </Link>
                  {/* <ul className="sub-menu">
                            <li><a href="index.html">Home Default</a></li>
                            <li><a href="index-2.html">Home Style 2</a></li>
                            <li><a href="index-3.html">Home Style 3</a></li>
                            <li className="menu-item-has-children">
                                <a href="#Level3">Header style</a>
                                <ul className="sub-menu">
                                    <li><a href="index.html">Header Style 1</a></li>
                                    <li><a href="index-2.html">Header Style 2</a></li>
                                    <li><a href="index-3.html">Header Style 3</a></li>
                                </ul>
                            </li>
                        </ul> */}
                </li>
                <li>
                  <Link to="/about" style={{ color: "black" }}>
                    About Us
                  </Link>
                  {/* <ul className="sub-menu"> */}
                  {/* <li><a href="about.html">About</a></li> */}
                  {/* <li>
                      <Link to="/about" style={{ textDecoration: "none" }}>
                        About
                      </Link>
                    </li>
                    <li>
                      <a href="contact.html">Contact</a>
                    </li>
                    <li>
                      <a href="team.html">Leadership Team</a>
                    </li> */}
                  {/* <li><a href="testimonials.html">Testimonials</a></li>
                            <li><a href="testimonials-s2.html">Testimonials style 2</a></li>
                            <li><a href="faq.html">FAQ</a></li>
                            <li><a href="404.html">404</a></li> */}
                  {/* </ul> */}
                </li>
                <li className="menu-item-has-children">
                  <Link to="/service" style={{ color: "black" }}>
                    Services
                  </Link>
                  <ul className="sub-menu">
                    {/* <li><Link to="/service" style={{textDecoration:"none"}}>Services</Link></li> */}
                    {/* <li><a href="services-s2.html">Services style 2</a></li> */}
                    <li>
                      <Link
                        to="/softwareLicenseAdvisory"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        Software Licensing Advisory
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/cyberSecurity"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        CyberSecurity Solutions
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        to="/itInfrastructure"
                        style={{ textDecoration: 'none', color: 'black' }}
                      >
                        Infrastructure Modernization Solutions
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        to="/softwareAssetManagement"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        Managed SAM (Software Asset Management)
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/servicethreed"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        Technology Evangelization
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/servicefour"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        Procurement Optimization
                      </Link>
                    </li>
                    {/* <li><a href="cyber-security.html">Cyber Security</a></li> */}
                  </ul>
                </li>
                <li>
                  <Link
                    to="/partnerwithus"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Partner With Us
                  </Link>
                </li>

                <li>
                  <Link to="/team" style={{ color: "black" }}>
                    {" "}
                    Team
                  </Link>
                </li>

                <li>
                  <Link to="/partnerships" style={{ color: "black" }}>
                    Partnership
                  </Link>
                </li>
                <li>
                  <Link to="/gallery" style={{ color: "black" }}>
                    Gallery
                  </Link>
                </li>
                <li>
                  <Link
                    to="/projects"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Case studies
                  </Link>
                </li>
                <li>
                  <Link
                    to="/blogs"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Blog
                  </Link>
                </li>
                <li>
                  <Link
                    to="/careers"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Careers
                  </Link>
                </li>

                <li>
                  <Link
                    to="/contact"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            {/* <!-- end of nav-collapse --> */}

            {/* <div className="cart-search-contact">
                <div className="header-search-form-wrapper">
                    <button className="search-toggle-btn"><i className="fi flaticon-search"></i></button>
                    <div className="header-search-form">
                        <form>
                            <div>
                                <input type="text" className="form-control" placeholder="Search here..." />
                                <button type="submit"><i className="ti-search"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="mini-cart">
                    <button className="cart-toggle-btn"> <i className="fi flaticon-bag-1"></i> <span className="cart-count">02</span></button>
                    <div className="mini-cart-content">
                        <div className="mini-cart-items">
                            <div className="mini-cart-item clearfix">
                                <div className="mini-cart-item-image">
                                    <Link href="#"><img src="assets/images/shop/mini-cart/img-1.jpg" alt /></a>
                                </div>
                                <div className="mini-cart-item-des">
                                    <a href="#">Hoodi with zipper</a>
                                    <span className="mini-cart-item-price">$20.15</span>
                                    <span className="mini-cart-item-quantity">x 1</span>
                                </div>
                            </div>
                            <div className="mini-cart-item clearfix">
                                <div className="mini-cart-item-image">
                                    <a href="#"><img src="assets/images/shop/mini-cart/img-2.jpg" alt /></a>
                                </div>
                                <div className="mini-cart-item-des">
                                    <a href="#">Ninja T-shirt</a>
                                    <span className="mini-cart-item-price">$13.25</span>
                                    <span className="mini-cart-item-quantity">x 2</span>
                                </div>
                            </div>
                        </div>
                        <div className="mini-cart-action clearfix">
                            <span className="mini-checkout-price">$215.14</span>
                            <a href="#" className="view-cart-btn">View Cart</a>
                        </div>
                    </div>
                </div>
            </div> */}
          </div>
        </div>
        {/* <!-- end of container --> */}
      </nav>
    </div>
  );
}

export default Navbar;
