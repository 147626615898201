import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { GetAllCaseStudies } from '../handelrs/caseStudies.handler';

function CaseStudy() {
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState(1);
  const [count, setCount] = useState(0);
  const [allcaseStudies, setAllcaseStudies] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    getDataCase();
  }, [page]);
  useEffect(() => {
    const storedPage = localStorage.getItem('page');
    if (storedPage) {
      setPage(parseInt(storedPage));
    }
  }, []);
  const handlePageClick = (event, newPage) => {
    setPage(newPage);
    localStorage.setItem('page', newPage.toString());
  };

  const getDataCase = () => {
    const body = {
      page: localStorage.getItem('page'),
      limit: 6,
    };
    setLoading(true);
    GetAllCaseStudies(body).then((resp) => {
      if (resp.data.status === 200) {
        setAllcaseStudies(resp.data.data.caseStudyList);
        setCount(resp.data.data.totalPages);
        setLoading(false);
      }
    });
  };

  return (
    <div>
      <section className="case-study-section-s2 section-padding">
        <div className="container">
          <div className="row">
            {/* <div className="col col-lg-5 col-md-5">
              <div className="section-title-s3">
                <h2>Case studies</h2>
              </div>
            </div> */}
            <div className="col col-lg-6 col-lg-offset-1 col-md-7">
              <div className="text">
                {/* <p>Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel</p> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col col-xs-12">
              <div className="content-area clearfix">
                <div className="case-grids clearfix">
                  {allcaseStudies?.map((value) => {
                    return (
                      <div key={value?._id} className="grid">
                        <div className="inner">
                          <div className="img-holder">
                            <img
                              src={value.image}
                              alt="case stydy"
                              style={{
                                width: '366.63px',
                                height: '477.28px',
                                objectFit: 'cover',
                              }}
                            />
                          </div>
                          <div
                            className="details"
                            style={{ background: '#2c5b9c' }}
                          >
                            <div className="info">
                              <h3>
                                <Link
                                  to={`/singleproject/${value._id}`}
                                  style={{
                                    textDecoration: 'none',
                                    textTransform: 'capitalize',
                                  }}
                                >
                                  {value.title}
                                </Link>
                              </h3>
                              <Link
                                to={`/singleproject/${value._id}`}
                                style={{ textDecoration: 'none' }}
                              >
                                View details{' '}
                                <i className="fi flaticon-next"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- end container --> */}
      </section>
      <Stack style={{ alignItems: 'center', marginBottom: '32px' }}>
        <Pagination
          count={count}
          page={page}
          onChange={handlePageClick}
          variant="outlined"
          color="primary"
        />
      </Stack>
    </div>
  );
}

export default CaseStudy;
