import React, { useEffect } from 'react';
import Footer from '../Component/Footer';
import Navbar from '../Component/Navbar';
import { Link } from 'react-router-dom';
import optimization from '../../src/assets/gif/optimization.gif';
import cost_optimization from '../../src/assets/gif/cost-optimization.gif';
import asset_management from '../../src/assets/gif/asset-management.gif';
import governance from '../../src/assets/gif/building.gif';
import security from '../../src/assets/gif/security.gif';
import legal from '../../src/assets/gif/legal.gif';
import risk from '../../src/assets/gif/risk.gif';
import euro from '../../src/assets/gif/euro.gif';
import unused_Software from '../../src/assets/gif/line-chart.gif';
import consulting from '../../src/assets/gif/consulting.gif';
import optimization_config from '../../src/assets/gif/bar-chart.gif';
import management from '../../src/assets/gif/management.gif';

import { ScriptFile } from '../constants/server.constants';
function SoftwareAssetManagement() {
  useEffect(() => {
    window.scrollTo(0, 0);
    // getDataCase();
    const script = document?.createElement('script');

    script.src = ScriptFile;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div>
      <div className="page-wrapper">
        {/* <!-- start preloader --> */}
        {/* <div className="preloader">
          <div className="sk-cube-grid">
            <div className="sk-cube sk-cube1"></div>
            <div className="sk-cube sk-cube2"></div>
            <div className="sk-cube sk-cube3"></div>
            <div className="sk-cube sk-cube4"></div>
            <div className="sk-cube sk-cube5"></div>
            <div className="sk-cube sk-cube6"></div>
            <div className="sk-cube sk-cube7"></div>
            <div className="sk-cube sk-cube8"></div>
            <div className="sk-cube sk-cube9"></div>
          </div>
        </div> */}
        {/* <!-- end preloader --> */}

        {/* <!-- Start header --> */}
        <header id="header" className="site-header header-style-1">
          <Navbar />
        </header>
        {/* <!-- end of header --> */}

        {/* <!-- start page-title --> */}
        <section className="page-title-services">
          <div className="container">
            <div className="row">
              <div className="col col-xs-12 page-title-service">
                <h2 style={{ textTransform: 'uppercase' }}>
                  Managed SAM (Software Asset Management)
                </h2>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- end page-title --> */}

        {/* <!-- start service-single-section --> */}
        <section className="service-single-section section-padding">
          <div className="container">
            <div className="row">
              <div className="col col-md-9 col-md-push-3">
                <div className="service-single-content">
                  {/* <div className="service-single-img">
                    <img
                      src="assets/images/service-single/managed-SAM.jpg"
                      alt="software"
                    />
                  </div> */}
                  {/* The need | SAM */}
                  <div className="consulting__title heading__title">
                    The Need | Managed SAM (Software Asset Management)
                  </div>
                  <div className="col col-xs-12">
                    <section className="services-section">
                      <div className="container">
                        <div className="row">
                          <div className="services-grid clearfix">
                            <div className="grid">
                              <div className="icon">
                                {/*<img
                                  className="fi grid-image"
                                  src={variables}
                                /> */}
                                <img
                                  className="fi grid-image"
                                  src={cost_optimization}
                                />
                              </div>
                              <h4>Cost Optimization</h4>
                              <p>
                                <ul>
                                  <li>
                                    <i
                                      className="ti-arrow-circle-right"
                                      style={{ marginTop: '2px' }}
                                    ></i>
                                    <span>
                                      Software is a significant component of IT
                                      spend.
                                    </span>
                                  </li>
                                  <li>
                                    <i
                                      className="ti-arrow-circle-right"
                                      style={{ marginTop: '2px' }}
                                    ></i>
                                    <span>
                                      Customer may be over-licensed and paying
                                      maintenance costs for software licenses
                                      not being used.
                                    </span>
                                  </li>
                                </ul>
                              </p>
                            </div>
                            <div className="grid">
                              <div className="icon">
                                {/* <i className="fi flaticon-cloud-computing"></i> */}
                                <img
                                  className="fi grid-image"
                                  src={asset_management}
                                />
                              </div>
                              <h4>Asset Management</h4>
                              <p>
                                <ul>
                                  <li>
                                    <i
                                      className="ti-arrow-circle-right"
                                      style={{ marginTop: '2px' }}
                                    ></i>
                                    <span>
                                      Monitoring & Tracking of software in use
                                      is difficult.
                                    </span>
                                  </li>
                                  <li>
                                    <i
                                      className="ti-arrow-circle-right"
                                      style={{ marginTop: '2px' }}
                                    ></i>
                                    <span>
                                      No “silver bullet” technology solution.
                                    </span>
                                  </li>
                                  <li>
                                    <i
                                      className="ti-arrow-circle-right"
                                      style={{ marginTop: '2px' }}
                                    ></i>
                                    <span>
                                      Diverse and complex software licensing
                                      models.
                                    </span>
                                  </li>
                                  <li>
                                    <i
                                      className="ti-arrow-circle-right"
                                      style={{ marginTop: '2px' }}
                                    ></i>
                                    <span>
                                      Reallocation of software licenses when
                                      hardware is moved or decommissioned.
                                    </span>
                                  </li>
                                </ul>
                              </p>
                            </div>
                            <div className="grid">
                              <div className="icon">
                                {/* <i className="fi flaticon-cloud-computing"></i> */}
                                <img
                                  className="fi grid-image"
                                  src={governance}
                                />
                              </div>
                              <h4>Organizational Governance</h4>
                              <p>
                                <ul>
                                  <li>
                                    <i
                                      className="ti-arrow-circle-right"
                                      style={{ marginTop: '2px' }}
                                    ></i>
                                    <span>
                                      Getting compliant and staying that way
                                      also helps eliminate the potential damage
                                      to reputation that could arise from a
                                      legal dispute.
                                    </span>
                                  </li>
                                  <li>
                                    <i
                                      className="ti-arrow-circle-right"
                                      style={{ marginTop: '2px' }}
                                    ></i>
                                    <span>
                                      Compliance with industry standards.
                                    </span>
                                  </li>
                                </ul>
                              </p>
                            </div>
                            <div className="grid">
                              <div className="icon">
                                {/* <i className="fi flaticon-cloud-computing"></i> */}
                                <img className="fi grid-image" src={security} />
                              </div>
                              <h4>Security</h4>
                              <p>
                                <ul>
                                  <li>
                                    <i
                                      className="ti-arrow-circle-right"
                                      style={{ marginTop: '2px' }}
                                    ></i>
                                    <span>
                                      Without the ability to inventory and
                                      control software installed and allowed to
                                      run on their hardware, organizations make
                                      their systems more vulnerable to security
                                      threats.
                                    </span>
                                  </li>
                                  <li>
                                    <i
                                      className="ti-arrow-circle-right"
                                      style={{ marginTop: '2px' }}
                                    ></i>
                                    <span>
                                      Inventory Open Source software to
                                      understand what is in use and what could
                                      potentially introduce security risks to
                                      the organization.
                                    </span>
                                  </li>
                                </ul>
                              </p>
                            </div>
                            <div className="grid">
                              <div className="icon">
                                {/* <i className="fi flaticon-cloud-computing"></i> */}
                                <img className="fi grid-image" src={legal} />
                              </div>
                              <h4>Risk – Legal & Regulatory</h4>
                              <p>
                                <ul>
                                  <li>
                                    <i
                                      className="ti-arrow-circle-right"
                                      style={{ marginTop: '2px' }}
                                    ></i>
                                    <span>
                                      Properly implementing SAM limits legal and
                                      financial exposure should problems with
                                      software licenses arise.
                                    </span>
                                  </li>
                                  <li>
                                    <i
                                      className="ti-arrow-circle-right"
                                      style={{ marginTop: '2px' }}
                                    ></i>
                                    <span>
                                      Select industries have regulatory
                                      requirements on SAM.
                                    </span>
                                  </li>
                                </ul>
                              </p>
                            </div>
                            <div className="grid">
                              <div className="icon">
                                {/* <i className="fi flaticon-cloud-computing"></i> */}
                                <img className="fi grid-image" src={risk} />
                              </div>
                              <h4>Risk – Software Audits</h4>
                              <p>
                                <ul>
                                  <li>
                                    <i
                                      className="ti-arrow-circle-right"
                                      style={{ marginTop: '2px' }}
                                    ></i>
                                    <span>
                                      License Compliance Audits are on the rise➔
                                      Gartner continues to predict an increase
                                      in vendor audits.
                                    </span>
                                  </li>
                                  <li>
                                    <i
                                      className="ti-arrow-circle-right"
                                      style={{ marginTop: '2px' }}
                                    ></i>
                                    <span>
                                      Software vendors use license compliance
                                      audits to decrease squeeze on margins.
                                    </span>
                                  </li>
                                  <li>
                                    <i
                                      className="ti-arrow-circle-right "
                                      style={{
                                        marginTop: '2px',
                                      }}
                                    ></i>
                                    <span>
                                      Software industry alliance “bounties”.
                                    </span>
                                  </li>
                                </ul>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  {/* The Need | SAM End */}

                  {/* start how we do---- */}

                  {/* <h2>How We Do</h2>
                  <div id="do-content">
                    <p className="para1">
                      From in-house SAM tool evaluation to Continuous Process
                      adoption and improvement as a part of Managed Services
                    </p>

                    <h4>Phase – I: Determination of Scope</h4>
                    <div>
                      <h4>Phase – II: IT Discovery Workshop</h4>
                      <ul>
                        <li>
                          Manual: Checklist based approach which involves
                          manually visiting each machine
                        </li>
                        <li>
                          Semi-Automated: Involves execution of custom-made
                          scripts (BAT/VB) which can be executed centrally
                        </li>
                        <li>
                          Automated: Involves inventory collection via tools
                          from a centralized location
                        </li>
                      </ul>
                    </div>
                    <h4>
                      Phase – III: Purchase History & Entitlement Analysis
                    </h4>
                    <h4>
                      Phase – IV: Gap Analysis & Recommendations Phase – V:
                      Optimization
                    </h4>
                  </div> */}
                </div>
              </div>
              <div className="col col-md-3 col-md-pull-9 hide-phone">
                <div className="service-sidebar">
                  <div className="widget service-list-widget">
                    <ul>
                      <li>
                        <Link to="/service" style={{ textDecoration: 'none' }}>
                          All Service
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/softwareLicenseAdvisory"
                          style={{ textDecoration: 'none' }}
                        >
                          Software Licensing Advisory
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/cyberSecurity"
                          style={{ textDecoration: 'none' }}
                        >
                          CyberSecurity Solutions
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          to="/itInfrastructure"
                          style={{ textDecoration: 'none' }}
                        >
                          Infrastructure Modernization Solutions
                        </Link>
                      </li> */}
                      <li className="current">
                        <Link
                          to="/softwareAssetManagement"
                          style={{ textDecoration: 'none' }}
                        >
                          Managed SAM (Software Asset Management)
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/servicethreed"
                          style={{ textDecoration: 'none' }}
                        >
                          Technology Evangelization
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/servicefour"
                          style={{ textDecoration: 'none' }}
                        >
                          Procurement Optimization
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="widget download-widget">
                    <ul>
                      <li>
                        <a
                          href="/assets/file/Software_Asset_Management_Services.pdf"
                          download="Software_Asset_Management_Services.pdf"
                        >
                          <i className="ti-zip"></i>Company Profile
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="widget contact-widget">
                    <div>
                      <h5>
                        We are Managed SAM (Software Asset Management){' '}
                        <span>Experts</span>
                      </h5>
                      <a href="/contact">Contact with us</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="containerFluid">
              {/*<div className="consulting nssplSection">*/}
              {/*  <div className="consulting__title">The Need | Managed SAM (Software Asset Management)</div>*/}

              {/*  <div className="consulting__container">*/}

              {/*    <div className="consulting__box">*/}
              {/*      <div className="consulting__boxImage">*/}
              {/*        <img*/}
              {/*            src="https://silverleaf.nssplindia.com/static/media/cost-optimization.a2c612bf35eef1334267.gif"/>*/}
              {/*      </div>*/}
              {/*      <div className="consulting__textArea">*/}
              {/*        <div className="consulting__boxHeading">*/}
              {/*          Cost Optimization*/}
              {/*        </div>*/}
              {/*        <div className="consulting__boxContent">*/}
              {/*          <div className="consulting__contentList">*/}
              {/*            <div className="consulting__contentListItem">*/}
              {/*              <span className="ti-arrow-circle-right"></span>*/}
              {/*              Customer may be over-licensed and paying maintenance costs for software licenses not*/}
              {/*              being used*/}
              {/*            </div>*/}

              {/*            <div className="consulting__contentListItem">*/}
              {/*              <span className="ti-arrow-circle-right"></span>*/}
              {/*              Software is a significant component of IT spend*/}
              {/*            </div>*/}

              {/*          </div>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    <div className="consulting__box">*/}
              {/*      <div className="consulting__boxImage">*/}
              {/*        <img*/}
              {/*            src="https://silverleaf.nssplindia.com/static/media/cost-optimization.a2c612bf35eef1334267.gif"/>*/}
              {/*      </div>*/}
              {/*      <div className="consulting__textArea">*/}
              {/*        <div className="consulting__boxHeading">*/}
              {/*          Asset Management*/}
              {/*        </div>*/}
              {/*        <div className="consulting__boxContent">*/}
              {/*          <div className="consulting__contentList">*/}
              {/*            <div className="consulting__contentListItem">*/}
              {/*              <span className="ti-arrow-circle-right"></span>*/}
              {/*              Monitoring & Tracking of software in use is difficult*/}
              {/*            </div>*/}

              {/*            <div className="consulting__contentListItem">*/}
              {/*              <span className="ti-arrow-circle-right"></span>*/}
              {/*              Monitoring & Tracking of software in use is difficult*/}
              {/*            </div>*/}

              {/*            <div className="consulting__contentListItem">*/}
              {/*              <span className="ti-arrow-circle-right"></span>*/}
              {/*              Diverse and complex software licensing models*/}
              {/*            </div>*/}

              {/*            <div className="consulting__contentListItem">*/}
              {/*              <span className="ti-arrow-circle-right"></span>*/}
              {/*              Reallocation of software licenses when hardware is moved or decommissioned*/}
              {/*            </div>*/}

              {/*          </div>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}

              {/*    <div className="consulting__box">*/}
              {/*      <div className="consulting__boxImage">*/}
              {/*        <img*/}
              {/*            src="https://silverleaf.nssplindia.com/static/media/cost-optimization.a2c612bf35eef1334267.gif"/>*/}
              {/*      </div>*/}
              {/*      <div className="consulting__textArea">*/}
              {/*        <div className="consulting__boxHeading">*/}
              {/*          Organizational Governance*/}
              {/*        </div>*/}
              {/*        <div className="consulting__boxContent">*/}
              {/*          <div className="consulting__contentList">*/}
              {/*            <div className="consulting__contentListItem">*/}
              {/*              <span className="ti-arrow-circle-right"></span>*/}
              {/*              Getting compliant and staying that way also helps eliminate the potential damage to*/}
              {/*              reputation that could arise from a legal dispute*/}
              {/*            </div>*/}

              {/*            <div className="consulting__contentListItem">*/}
              {/*              <span className="ti-arrow-circle-right"></span>*/}
              {/*              Compliance with industry standards*/}
              {/*            </div>*/}

              {/*          </div>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}

              {/*    <div className="consulting__box">*/}
              {/*      <div className="consulting__boxImage">*/}
              {/*        <img*/}
              {/*            src="https://silverleaf.nssplindia.com/static/media/cost-optimization.a2c612bf35eef1334267.gif"/>*/}
              {/*      </div>*/}
              {/*      <div className="consulting__textArea">*/}
              {/*        <div className="consulting__boxHeading">*/}
              {/*          Security*/}
              {/*        </div>*/}
              {/*        <div className="consulting__boxContent">*/}
              {/*          <div className="consulting__contentList">*/}
              {/*            <div className="consulting__contentListItem">*/}
              {/*              <span className="ti-arrow-circle-right"></span>*/}
              {/*              Without the ability to inventory and control software installed and allowed to run on*/}
              {/*              their hardware, organizations make their systems more vulnerable to security threats*/}
              {/*            </div>*/}

              {/*            <div className="consulting__contentListItem">*/}
              {/*              <span className="ti-arrow-circle-right"></span>*/}
              {/*              Inventory Open Source software to understand what is in use and what could potentially*/}
              {/*              introduce security risks to the organization*/}
              {/*            </div>*/}

              {/*          </div>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}

              {/*    <div className="consulting__box">*/}
              {/*      <div className="consulting__boxImage">*/}
              {/*        <img*/}
              {/*            src="https://silverleaf.nssplindia.com/static/media/cost-optimization.a2c612bf35eef1334267.gif"/>*/}
              {/*      </div>*/}
              {/*      <div className="consulting__textArea">*/}
              {/*        <div className="consulting__boxHeading">*/}
              {/*          Risk – Legal & Regulatory*/}
              {/*        </div>*/}
              {/*        <div className="consulting__boxContent">*/}
              {/*          <div className="consulting__contentList">*/}
              {/*            <div className="consulting__contentListItem">*/}
              {/*              <span className="ti-arrow-circle-right"></span>*/}
              {/*              Properly implementing SAM limits legal and financial exposure should problems with*/}
              {/*              software licenses arise*/}
              {/*            </div>*/}

              {/*            <div className="consulting__contentListItem">*/}
              {/*              <span className="ti-arrow-circle-right"></span>*/}
              {/*              Select industries have regulatory requirements on SAM*/}
              {/*            </div>*/}

              {/*          </div>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}

              {/*    <div className="consulting__box">*/}
              {/*      <div className="consulting__boxImage">*/}
              {/*        <img*/}
              {/*            src="https://silverleaf.nssplindia.com/static/media/cost-optimization.a2c612bf35eef1334267.gif"/>*/}
              {/*      </div>*/}
              {/*      <div className="consulting__textArea">*/}
              {/*        <div className="consulting__boxHeading">*/}
              {/*          Risk – Software Audits*/}
              {/*        </div>*/}
              {/*        <div className="consulting__boxContent">*/}
              {/*          <div className="consulting__contentList">*/}
              {/*            <div className="consulting__contentListItem">*/}
              {/*              <span className="ti-arrow-circle-right"></span>*/}
              {/*              License Compliance Audits are on the rise➔ Gartner continues to predict an increase in*/}
              {/*              vendor audits*/}
              {/*            </div>*/}

              {/*            <div className="consulting__contentListItem">*/}
              {/*              <span className="ti-arrow-circle-right"></span>*/}
              {/*              Software vendors use license compliance audits to decrease squeeze on margins*/}
              {/*            </div>*/}

              {/*            <div className="consulting__contentListItem">*/}
              {/*              <span className="ti-arrow-circle-right"></span>*/}
              {/*              Software industry alliance “bounties”*/}
              {/*            </div>*/}

              {/*          </div>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}

              {/*  </div>*/}
              {/*</div>*/}

              <div className="stepperNew nssplSection">
                <div className="consulting__title heading__title">
                  How We Do?
                </div>

                <div className="stepperNew__content center__content">
                  <div className="stepperNew__text">
                    From in-house SAM tool evaluation to Continuous Process
                    adoption and improvement as a part of Managed Services
                  </div>
                  <div className="stepperNew__text">
                    Phase – I: Determination of Scope
                  </div>
                  <div className="stepperNew__text">
                    Phase – II: IT Discovery Workshop
                  </div>

                  <div className="consulting__contentList mtb30">
                    <div className="consulting__contentListItem">
                      <span className="ti-arrow-circle-right"></span>
                      Manual: Checklist based approach which involves manually
                      visiting each machine
                    </div>

                    <div className="consulting__contentListItem">
                      <span className="ti-arrow-circle-right"></span>
                      Semi-Automated: Involves execution of custom-made scripts
                      (BAT/VB) which can be executed centrally
                    </div>
                    <div className="consulting__contentListItem">
                      <span className="ti-arrow-circle-right"></span>
                      Automated: Involves inventory collection via tools from a
                      centralized location
                    </div>
                  </div>

                  <div className="stepperNew__text">
                    Phase – III: Purchase History & Entitlement Analysis
                  </div>

                  <div className="stepperNew__text">
                    Phase – IV: Gap Analysis & Recommendations
                  </div>

                  <div className="stepperNew__text">
                    Phase – V: Optimization
                  </div>
                </div>
              </div>

              <div className="stepperNew nssplSection">
                <div className="consulting__title heading__title">
                  Key Deliverables
                </div>

                <div className="stepperNew__content center__content">
                  <div className="consulting__contentList">
                    <div className="consulting__contentListItem">
                      <span className="ti-arrow-circle-right"></span>
                      License Compliance Skills and Attributes – SAM Process and
                      Best Practice
                    </div>

                    <div className="consulting__contentListItem">
                      <span className="ti-arrow-circle-right"></span>
                      License and Subscription Optimization Skills and
                      Attributes – Cost Optimization Experience
                    </div>
                    <div className="consulting__contentListItem">
                      <span className="ti-arrow-circle-right"></span>
                      Optimized Configurations Skills and Attributes – Economies
                      of Scale
                    </div>

                    <div className="consulting__contentListItem">
                      <span className="ti-arrow-circle-right"></span>
                      Minimal Unnecessary Costs Skills and Attributes – Data
                      Custodianship and Integration
                    </div>

                    <div className="consulting__contentListItem">
                      <span className="ti-arrow-circle-right"></span>
                      Minimized unused Software Skills and Attributes – Detailed
                      usage rights intelligence
                    </div>
                  </div>
                </div>
              </div>

              <div className="stepperNew nssplSection">
                <div className="consulting__title heading__title">
                  Why SLS (Key Differentiators)
                </div>

                <div className="stepperNew__content center__content">
                  <div className="consulting__contentList">
                    <div className="consulting__contentListItem">
                      <span className="ti-arrow-circle-right"></span>
                      Independent SLS does not work with any publisher either as
                      a funded Auditor or Large Account Reseller
                    </div>

                    <div className="consulting__contentListItem">
                      <span className="ti-arrow-circle-right"></span>
                      SAM Roadmap and Vision SAM Maturity Assessment is a part
                      of our standard process, and we provide necessary vision
                      and recommendations to bring cost efficiency.
                    </div>
                    <div className="consulting__contentListItem">
                      <span className="ti-arrow-circle-right"></span>
                      Complete Ownership SLS takes end to end complete ownership
                      of SAM reviews in case of Audits/ reviews funded by
                      Publishers
                    </div>

                    <div className="consulting__contentListItem">
                      <span className="ti-arrow-circle-right"></span>
                      ISO Standards Our approach and methodology follow ISO
                      19770-1 and related best practices
                    </div>

                    <div className="consulting__contentListItem">
                      <span className="ti-arrow-circle-right"></span>
                      Forensic Approach Methodology to identify not only
                      deployments but also un-installations, cracks, keygens,
                      publisher specific advanced checks and component analysis
                    </div>
                  </div>
                </div>
              </div>

              <div className="consulting nssplSection">
                <div className="consulting__title heading__title">
                  SAM Deliverables | Service Outcomes
                </div>

                <div className="consulting__container odd">
                  <div className="consulting__box">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/cost-optimization.a2c612bf35eef1334267.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Minimal Unnecessary Costs
                      </div>
                      <div className="consulting__boxContent">
                        Skills and Attributes – Data Custodianship and
                        Integration
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/asset-management.3f003261fef002b0d2e7.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        License Compliance
                      </div>
                      <div className="consulting__boxContent">
                        Skills and Attributes - SAM Process and best practice
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/security.12aee98969a1cf5c30c6.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Minimized Unused Software
                      </div>
                      <div className="consulting__boxContent">
                        Skills and Attributes – Detailed usage rights
                        intelligence
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/transparent-process.e873a494683fda955451.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        License And Subscription Optimization
                      </div>
                      <div className="consulting__boxContent">
                        Skills and Attributes – Cost Optimization Experience
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/precise-planning.520b462827cfbf49831b.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Optimized Configurations
                      </div>
                      <div className="consulting__boxContent">
                        Skills and Attributes – Economies of Scale
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="showcase nssplSection">
                <div className="consulting__title heading__title">
                  SAM Services | Compliance, Governance And Control
                </div>

                <div className="showcase__boxContainer">
                  <div className="showcase__box">
                    <div className="showcase__image">
                      <img src="https://silverleaf.nssplindia.com/assets/images/service-single/one-time-assessment.jpg" />
                    </div>
                    <div className="showcase__content">
                      <div className="showcase__contentTitle">
                        One-Time Assessment
                      </div>
                      <div className="showcase__contentText">
                        One-time snapshot of software license compliance for
                        single or multiple publishers
                      </div>
                    </div>
                  </div>

                  <div className="showcase__box">
                    <div className="showcase__image">
                      <img src="https://silverleaf.nssplindia.com/assets/images/service-single/audit-assistance.jpg" />
                    </div>
                    <div className="showcase__content">
                      <div className="showcase__contentTitle">
                        Audit Assistance
                      </div>
                      <div className="showcase__contentText">
                        Identify the areas of risk and help mitigate the same
                        using best practices and approach
                      </div>
                    </div>
                  </div>

                  <div className="showcase__box">
                    <div className="showcase__image">
                      <img src="https://silverleaf.nssplindia.com/assets/images/service-single/managed-services.png" />
                    </div>
                    <div className="showcase__content">
                      <div className="showcase__contentTitle">
                        Managed Services
                      </div>
                      <div className="showcase__contentText">
                        Real-time compliance management, usage metering and
                        license optimization services based on the publisher
                        risk assessment
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={'row'}>
              <div className="col col-md-3 col-md-pull-9 hide-desktop">
                <div className="service-sidebar">
                  <div className="widget service-list-widget">
                    <ul>
                      <li>
                        <Link to="/service" style={{ textDecoration: 'none' }}>
                          All Service
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/softwareLicenseAdvisory"
                          style={{ textDecoration: 'none' }}
                        >
                          Software Licensing Advisory
                        </Link>
                      </li>
                      {/* <li><a href="content-engineering.html">Software Licensing Advisory</a></li> */}
                      <li>
                        <Link
                          to="/cyberSecurity"
                          style={{ textDecoration: 'none' }}
                        >
                          CyberSecurity Solutions
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          to="/itInfrastructure"
                          style={{ textDecoration: 'none' }}
                        >
                          Infrastructure Modernization Solutions
                        </Link>
                      </li> */}
                      <li className="current">
                        <Link
                          to="/softwareAssetManagement"
                          style={{ textDecoration: 'none' }}
                        >
                          Managed SAM (Software Asset Management)
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/servicethreed"
                          style={{ textDecoration: 'none' }}
                        >
                          Technology Evangelization
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/servicefour"
                          style={{ textDecoration: 'none' }}
                        >
                          Procurement Optimization
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="widget download-widget">
                    <ul>
                      <li>
                        <a
                          href="/assets/file/Silver_Leaf_Solutions_Who_Are_We.pdf"
                          download="Silver_Leaf_Solutions_Who_Are_We.pdf"
                        >
                          <i className="ti-zip"></i>Company Profile
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="widget contact-widget">
                    <div>
                      <h5>
                        We are Software Licensing Advisory<span>Experts</span>
                      </h5>
                      <a href="/contact">Contact with us</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- end row --> */}
          </div>
          {/* <!-- end container --> */}
        </section>

        {/* SAM Services | Compliance, Governance and Control */}

        <Footer />

        {/* <!-- end site-footer --> */}
      </div>

      <script src="assets/js/jquery.min.js"></script>
      <script src="assets/js/bootstrap.min.js"></script>

      {/* <!-- Plugins for this template --> */}
      <script src="assets/js/jquery-plugin-collection.js"></script>

      {/* <!-- Custom script for this template --> */}
      <script src="assets/js/script.js"></script>
    </div>
  );
}

export default SoftwareAssetManagement;
