import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Component/Footer';
import Navbar from '../Component/Navbar';
import { Pagination, Box, Paper } from '@mui/material';
import { useTheme } from '@emotion/react';
import SingleCareer from './SingleCareer';
import ReactHtmlParser from 'html-react-parser';
import { Button } from 'reactstrap';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { GetJobListCareer } from '../handelrs/career.handler';
import { ScriptFile } from '../constants/server.constants';
// import Awards from '../Component/Awards';
const pageSize = 6;
const defaultValues = {
  name: '',
  email: '',
  mobile: '',
  description: '',
  resume: null,
  jobId: '',
};

function Careers() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const theme = useTheme();
  const [pagination, setpagination] = useState({
    coount: 0,
    from: 0,
    to: pageSize,
  });
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [counts, setCounts] = useState(0);
  const [openings, setOpenings] = useState('');
  const [modal, setModal] = useState(false);
  const [jobId, setJobId] = useState();
  const [user, setUser] = useState(null);
  const [apiRes, setApiRes] = useState({
    msg: '',
    success: true,
  });
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    id: ' ',
  });
  const [successModal, setSuccessModal] = useState(false);
  const [page, setPage] = React.useState(1);
  const phoneRegExp = RegExp(
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),

    email: Yup.string().required('Email is required').email('Email is invalid'),
    // mobile: Yup.string()
    //   //.required('Mobile is required')
    //   .matches(phoneRegExp, 'Phone number is not valid'),
    mobile: Yup.string()
      .matches(phoneRegExp, 'Invalid Mobile number ')
      .required('Mobile number  is required'),

    resume: Yup.mixed()
      .required('Resume is required')

      // .test("required", "Resume is required", value => value && value.length === 0|| value === "undefined" || value === null)
      .test('fileSize', 'File Size is too large', (value) => {
        return value && value.length && value[0].size <= 5242880;
      })
      .test(
        'fileType',
        'Unsupported File Format, Upload Resume in .pdf format only',
        (value) => {
          return (
            value && value.length && ['application/pdf'].includes(value[0].type)
          );
        }
      ),
  });

  const {
    setValue,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(
    () => {
      getjobList();
      window.scrollTo(0, 0);
      const script = document.createElement('script');

      script.src = ScriptFile;
      script.async = true;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    },
    [page]
    // [pagination.from, pagination.to]
  );

  const handlePageClick = (event, newPage) => {
    setPage(newPage);
  };
  const data = {
    page: page,
    limit: 6,
  };
  const getjobList = async () => {
    setLoading(true);
    GetJobListCareer(data)
      .then((res) => {
        if (res.data.status === 200) {
          setCounts(res.data.data.totalPages);
          //  setOpenings(res.data.data.slice(pagination.from, pagination.to));
          setOpenings(res.data.data.jobList);
          // setpagination({ ...pagination, coount: res.data.counts });

          setLoading(false);
        }
      })
      .catch((error) => setError(error.message));
  };

  const handlePageChange = (event, page) => {
    const from = (page - 1) * pageSize;
    const to = (page - 1) * pageSize + pageSize;

    setpagination({ ...pagination, from: from, to: to });
  };

  const applyjob = (e) => {
    const id = e.target.id;
    showModalForPublish(id);
  };

  const showModalForPublish = (_id) => {
    let id = _id;

    setConfirmationModal({
      show: true,
      id: id,
    });
  };

  const onClose = () => {
    setConfirmationModal({
      show: false,
      id: '',
    });
  };
  const title = () => {
    if (counts === null || counts === 0) {
      return <h1>we don't have any job vacancies available</h1>;
    } else {
      return <h1>Current openings</h1>;
    }
  };

  //
  const clearState = () => {
    setUser(defaultValues);
    reset(user);

    //setformValue({...defaultValues})
    //setJobId('')
  };

  //

  //
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <div className="page-wrapper">
        {/* <!-- Start header --> */}
        <header id="header" className="site-header header-style-1">
          {/* <nav className="navigation navbar navbar-default">
       
    </nav> */}
          <Navbar />
          {confirmationModal.show && (
            <SingleCareer id={confirmationModal.id} onClose={onClose} />
          )}
        </header>
        {/* <!-- end of header --> */}

        {/* <!-- start page-title --> */}
        <section className="page-title-career">
          <div className="container">
            <div className="row">
              <div className="col col-xs-12">
                <h2 style={{ textTransform: 'uppercase' }}>Careers</h2>
              </div>
            </div>
            {/* <!-- end row --> */}
          </div>
          {/* <!-- end container --> */}
        </section>
        {/* <!-- end page-title --> */}
        <section className="features-section section-padding features-section-padding">
          <div className="container">
            <div className="privacy-policy-row">
              {/* <h1>Come Join Us</h1>
              <p className="privacy-policy-paragraph">
                {' '}
                A career at Silver Leaf Solutions not only equips you with the
                know-how to handle technology of the future, but also links you
                to a distinctive community.
              </p> */}
              <h2>Recruitment process</h2>

              <br />

              <p className="privacy-policy-paragraph">
                Your first step in the recruitment process is to apply to an
                open position, at which point a member of our recruiting team
                will connect with you and identify any next steps. The
                recruiting process includes interviews and conversations with
                peers, managers, and leaders who will clarify the opportunity
                for you and articulate what it means to truly live as a SLS
                member. Our aim is through our interactions during the
                recruiting process is to provide you with a clear picture of the
                opportunities at SLS, and get to know our clients through
                conversations as SLS is a client-centric, relationship-based
                organization focusing on achieving success in a collaborative
                manner.
              </p>

              <p className="privacy-policy-paragraph">
                Throughout the process, our goal is to get to know you as a
                candidate so that we can better understand your potential fit
                within our organization. All we ask is that you be yourself so
                that we get to know you better by learning about your
                background, your experiences, learnings, challenges, and
                achievements.
              </p>

              <p className="privacy-policy-paragraph">
                SLS’s core values believes strongly in diversity and inclusion.
                Hence our recruitment program includes recruiting efforts at
                colleges and universities, a referral program, and direct
                sourcing through a team of recruiters who recognize the distinct
                strengths of each and every candidate.
              </p>

              <p className="privacy-policy-paragraph">
                SLS members make a difference to our clients, our company, and
                our communities.  As you get to know us during the recruitment
                process, you will get a clear picture of how we bring careers to
                life in ways big and small, professional, and personal.
              </p>

              <p className="privacy-policy-paragraph">
                The recruitment process is transparent & bi-directional i.e. not
                only we want to know more about “you” but “you” should also be
                aware of the career development path SLS offers as an
                opportunity
              </p>
            </div>

            <h2>How To Apply</h2>
            <br />
            <div className="benefit clearfix main-content">
              <div className="details row">
                <ul className="privacy-li">
                  <li>
                    <i className="ti-arrow-circle-right"></i>
                    <p>
                      Click on Job Opening, Job Description and Form will Open..
                    </p>{' '}
                  </li>

                  <li>
                    <i className="ti-arrow-circle-right"></i>
                    <p>Fill the Application Form.</p>{' '}
                  </li>

                  <li>
                    <i className="ti-arrow-circle-right"></i>
                    <p>
                      Ensure that Resume is attached, forms/Applications without
                      a Resume will be disqualified from any further engagement
                      in the recruitment process.
                    </p>{' '}
                  </li>

                  <li>
                    <i className="ti-arrow-circle-right"></i>
                    <p>
                      After Submission, within 3-5 business days a member of our
                      recruitment team will connect with the Applicant.
                    </p>{' '}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section
          className="features-section "
          style={{ paddingBottom: '30px' }}
        >
          <div className="container">
            <div className="row">
              <div className="header">{title()}</div>
            </div>
          </div>
        </section>

        <section className="shop-section ">
          <div className="container">
            <div className="row">
              <div className="col col-xs-12">
                <div className="shop-grids clearfix">
                  {openings &&
                    openings.map((data, k) => (
                      <div className="grid" key={data._id}>
                        <div className="img-holder"></div>
                        <div className="details" style={{ height: '262px' }}>
                          <h3>
                            <Link to={`/applynow/${data._id}`}>
                              {data.job_title}
                            </Link>
                          </h3>
                          {/* <p>{data.job_description}</p> */}
                          <p>
                            {data &&
                            data.job_description &&
                            data.job_description.length < 30
                              ? ReactHtmlParser(data.job_description)
                              : ''}
                            {data &&
                            data.job_description &&
                            data.job_description.length > 65
                              ? ReactHtmlParser(
                                  data.job_description.slice(0, 65) + '...'
                                )
                              : ''}
                          </p>
                          <div className="add-to-cart">
                            <Button
                              id={data._id}
                              value={data._id}
                              onClick={applyjob}
                              color="primary"
                            >
                              Apply Now
                            </Button>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                <Box my={2} display="flex" justifyContent="center">
                  <Pagination
                    count={counts}
                    onChange={handlePageClick}
                    //  count={Math.ceil(pagination.coount / pageSize)}
                    variant="outlined"
                    color="primary"
                  />
                </Box>
              </div>
            </div>
          </div>
        </section>

        {/* add awards compunents added here  */}

        <div
          style={{
            display: 'block',
            width: 700,
            padding: 30,
          }}
        >
          {/* <Col xl={3} lg={4} sm={6} className="mb-2">
                      {successModal ? (
                        <SweetAlert
                          title=""
                          success={apiRes.success}
                          danger={!apiRes.success}
                          confirmBtnBsStyle="success"
                          cancelBtnBsStyle="danger"
                          onConfirm={() => setSuccessModal(false)}
                          onCancel={() => setSuccessModal(false)}
                        >
                          {apiRes.msg}
                        </SweetAlert>
                      ) : null}
                  </Col> */}
        </div>

        {/* <!-- start features-section --> */}

        {/* <!-- end features-section --> */}

        {/* <!-- start about-section-s2 --> */}
        {/* <section className="about-section-s2 section-padding">
   
        {/* <!-- end contaienr --> */}
        {/* // </section> */}
        {/* <!-- end work-process --> */}

        {/* <!-- start why-choose-us-section --> */}

        {/* <!-- end why-choose-us-section --> */}

        {/* <!-- start our-skill-section --> */}

        {/* <!-- end our-skill-section --> */}

        {/* <!-- start team-section --> */}

        {/* <!-- end team-section --> */}

        {/* <!-- start pricing-section --> */}
        {/* <section className="pricing-section section-padding p-t-0">
    
        {/* <!-- start news-letter-section --> */}
        {/* <section className="news-letter-section">
          <div className="container">
            <div className="row">
              <div className="col col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
                <div className="newsletter">
                  <h3>Email Newsletter</h3>
                  <p>
                    Aenean leo ligula porttitor eu consequat vitae eleifend ac
                    enim. Aliquam lorem ante dapibus in viverra quiss consequat
                    vitae
                  </p>
                  <div className="newsletter-form">
                    <form>
                      <div>
                        <input type="text" className="form-control" />
                        <button type="submit">Subscrib</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
        </section> */}
        {/* <!-- end news-letter-section --> */}
        <Footer />

        {/* <!-- start site-footer --> */}
        {/* <footer className="site-footer">
   </footer> */}
        {/* <!-- end site-footer --> */}
      </div>
      {/* <!-- end of page-wrapper --> */}

      {/* <!-- All JavaScript files
================================================== --> */}
      <script src="assets/js/jquery.min.js"></script>
      <script src="assets/js/bootstrap.min.js"></script>

      {/* <!-- Plugins for this template --> */}
      <script src="assets/js/jquery-plugin-collection.js"></script>

      {/* <!-- Custom script for this template --> */}
      <script src="assets/js/script.js"></script>
    </React.Fragment>
  );
}

export default Careers;
