import React, { useEffect } from 'react';
import Footer from '../Component/Footer';
import Navbar from '../Component/Navbar';
import OurServices from '../Component/OurServices';
import { ScriptFile } from '../constants/server.constants';
function Service() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    // getDataCase();
    const script = document.createElement('script');

    script.src = ScriptFile;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div>
      <div className="page-wrapper">
        {/* <!-- start preloader --> */}
        {/* <div className="preloader">
    <div className="sk-cube-grid">
      <div className="sk-cube sk-cube1"></div>
      <div className="sk-cube sk-cube2"></div>
      <div className="sk-cube sk-cube3"></div>
      <div className="sk-cube sk-cube4"></div>
      <div className="sk-cube sk-cube5"></div>
      <div className="sk-cube sk-cube6"></div>
      <div className="sk-cube sk-cube7"></div>
      <div className="sk-cube sk-cube8"></div>
      <div className="sk-cube sk-cube9"></div>
    </div>
</div> */}
        {/* <!-- end preloader -->

<!-- Start header --> */}
        <header id="header" className="site-header header-style-1">
          <Navbar />
          {/* <nav className="navigation navbar navbar-default">
        <div className="container">
            <div className="nav-row clearfix">
                <div className="navbar-header">
                    <button type="button" className="open-btn">
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </button>
                    <a className="navbar-brand" href="index.html"><img src="assets/images/logo.png" alt/></a>
                </div>
                <div id="navbar" className="navbar-collapse collapse navbar-right navigation-holder">
                    <button className="close-navbar"><i className="ti-close"></i></button>
                    <ul className="nav navbar-nav">
                        <li className="menu-item-has-children">
                            <a href="#">Home</a>
                            <ul className="sub-menu">
                                <li><a href="index.html">Home Default</a></li>
                                <li><a href="index-2.html">Home Style 2</a></li>
                                <li><a href="index-3.html">Home Style 3</a></li>
                                <li className="menu-item-has-children">
                                    <a href="#Level3">Header style</a>
                                    <ul className="sub-menu">
                                        <li><a href="index.html">Header Style 1</a></li>
                                        <li><a href="index-2.html">Header Style 2</a></li>
                                        <li><a href="index-3.html">Header Style 3</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="menu-item-has-children">
                            <a href="#">Pages</a>
                            <ul className="sub-menu">
                                <li><a href="about.html">About</a></li>
                                <li><a href="contact.html">Contact</a></li>
                                <li><a href="team.html">Team</a></li>
                                <li><a href="testimonials.html">Testimonials</a></li>
                                <li><a href="testimonials-s2.html">Testimonials style 2</a></li>
                                <li><a href="faq.html">FAQ</a></li>
                                <li><a href="404.html">404</a></li>
                            </ul>
                        </li>
                        <li className="menu-item-has-children">
                            <a href="#">Services</a>
                            <ul className="sub-menu">
                                <li><a href="services.html">Services</a></li>
                                <li><a href="services-s2.html">Services style 2</a></li>
                                <li><a href="service-single.html">IT Management</a></li>
                                <li><a href="content-engineering.html">Content Engineering</a></li>
                                <li><a href="capital-marketing.html">Capital Marketing</a></li>
                                <li><a href="data-security.html">Data Security</a></li>
                                <li><a href="cyber-security.html">Cyber Security</a></li>
                            </ul>
                        </li>
                        <li className="menu-item-has-children">
                            <a href="#">Case studies</a>
                            <ul className="sub-menu">
                                <li><a href="projects.html">Case study</a></li>
                                <li><a href="projects-s2.html">Case study style 2</a></li>
                                <li><a href="project-single.html">Case Single</a></li>
                            </ul>
                        </li>
                        <li className="menu-item-has-children">
                            <a href="#">Shop</a>
                            <ul className="sub-menu">
                                <li><a href="shop.html">Shop</a></li>
                                <li><a href="shop-single.html">Shop single</a></li>
                            </ul>
                        </li>
                        <li className="menu-item-has-children">
                            <a href="#">Blog</a>
                            <ul className="sub-menu">
                                <li><a href="blog.html">Blog Default</a></li>
                                <li><a href="blog-left-sidebar.html">Blog left sidebar</a></li>
                                <li><a href="blog-fullwidth.html">Blog full width</a></li>
                                <li className="menu-item-has-children">
                                    <a href="#Level3">Blog Details</a>
                                    <ul className="sub-menu">
                                        <li><a href="blog-single.html">Blog Single Default</a></li>
                                        <li><a href="blog-single-left-sidebar.html">Blog single left sidebar</a></li>
                                        <li><a href="blog-single-fullwidth.html">Blog single full width</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li><a href="contact.html">Contact</a></li>
                    </ul>
                </div>
          

                <div className="cart-search-contact">
                    <div className="header-search-form-wrapper">
                        <button className="search-toggle-btn"><i className="fi flaticon-search"></i></button>
                        <div className="header-search-form">
                            <form>
                                <div>
                                    <input type="text" className="form-control" placeholder="Search here..."/>
                                    <button type="submit"><i className="ti-search"></i></button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="mini-cart">
                        <button className="cart-toggle-btn"> <i className="fi flaticon-bag-1"></i> <span className="cart-count">02</span></button>
                        <div className="mini-cart-content">
                            <div className="mini-cart-items">
                                <div className="mini-cart-item clearfix">
                                    <div className="mini-cart-item-image">
                                        <a href="#"><img src="assets/images/shop/mini-cart/img-1.jpg" alt/></a>
                                    </div>
                                    <div className="mini-cart-item-des">
                                        <a href="#">Hoodi with zipper</a>
                                        <span className="mini-cart-item-price">$20.15</span>
                                        <span className="mini-cart-item-quantity">x 1</span>
                                    </div>
                                </div>
                                <div className="mini-cart-item clearfix">
                                    <div className="mini-cart-item-image">
                                        <a href="#"><img src="assets/images/shop/mini-cart/img-2.jpg" alt/></a>
                                    </div>
                                    <div className="mini-cart-item-des">
                                        <a href="#">Ninja T-shirt</a>
                                        <span className="mini-cart-item-price">$13.25</span>
                                        <span className="mini-cart-item-quantity">x 2</span>
                                    </div>
                                </div>
                            </div>
                            <div className="mini-cart-action clearfix">
                                <span className="mini-checkout-price">$215.14</span>
                                <a href="#" className="view-cart-btn">View Cart</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav> */}
        </header>
        {/* <!-- end of header --> */}

        {/* <!-- start page-title --> */}
        <section className="page-title">
          <div className="container">
            <div className="row">
              <div className="col col-xs-12">
                <h2>SERVICES</h2>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- end page-title --> */}

        {/* <!-- start services-section-s2 --> */}
        <OurServices />
        {/* <section className="services-section-s2 services-pg-section-s2 section-padding">
    <div className="container">
        <div className="row">
            <div className="col col-xs-12">
                <div className="section-title">
                 //   <span>Our services</span>
                //<h2>Services We Provide</h2> 
                <h2>Our services</h2>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col col-xs-12">
                <div className="services-grid clearfix">
                    <div className="grid">
                        <div className="icon">
                            <i className="fi flaticon-cloud-computing"></i>
                        </div>
                        <h4><a href="#">IT Management</a></h4>
                        <p>Hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis </p>
                    </div>
                    <div className="grid">
                        <div className="icon">
                            <i className="fi flaticon-copywriting"></i>
                        </div>
                        <h4><a href="#">Content Engineering</a></h4>
                        <p>Hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis </p>
                    </div>
                    <div className="grid">
                        <div className="icon">
                            <i className="fi flaticon-capital"></i>
                        </div>
                        <h4><a href="#">Capital Merkating</a></h4>
                        <p>Hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis </p>
                    </div>
                    <div className="grid">
                        <div className="icon">
                            <i className="fi flaticon-fraud"></i>
                        </div>
                        <h4><a href="#">Data Security</a></h4>
                        <p>Hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis </p>
                    </div>
                    <div className="grid">
                        <div className="icon">
                            <i className="fi flaticon-security"></i>
                        </div>
                        <h4><a href="#">Cyber security</a></h4>
                        <p>Hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis </p>
                    </div>
                    <div className="grid">
                        <div className="icon">
                            <i className="fi flaticon-database"></i>
                        </div>
                        <h4><a href="#">Cloud Services</a></h4>
                        <p>Hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis </p>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</section> */}
        {/* <!-- end services-section-s2 --> */}

        {/* <!-- start news-letter-section --> */}
        {/* <section className="news-letter-section">
    <div className="container">
        <div className="row">
            <div className="col col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
                <div className="newsletter">
                    <h3>Email Newsletter</h3>
                    <p>Aenean leo ligula porttitor eu consequat vitae eleifend ac enim. Aliquam lorem ante dapibus in viverra quiss consequat vitae</p>
                    <div className="newsletter-form">
                        <form>
                            <div>
                                <input type="text" className="form-control"/>
                                <button type="submit">Subscrib</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</section> */}
        {/* <!-- end news-letter-section --> */}

        {/* <!-- start site-footer --> */}
        <Footer />
        {/* <!-- end site-footer --> */}
      </div>

      <script src="assets/js/jquery.min.js"></script>
      <script src="assets/js/bootstrap.min.js"></script>

      {/* <!-- Plugins for this template --> */}
      <script src="assets/js/jquery-plugin-collection.js"></script>

      {/* <!-- Custom script for this template --> */}
      <script src="assets/js/script.js"></script>
    </div>
  );
}

export default Service;
