import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Footer from '../Component/Footer';
import Navbar from '../Component/Navbar';
import AboutUs from '../Component/AboutUs';
import Manager from '../Component/Manager';
import OurServices from '../Component/OurServices';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import EmailContact from '../Component/EmailContact';
import { GetAllBanners } from '../handelrs/banner.handler';
import { GetAllCaseStudies } from '../handelrs/caseStudies.handler';
import consulting from '../../src/assets/gif/consulting.gif';
import optimization from '../../src/assets/gif/optimization.gif';
import evangelization from '../../src/assets/gif/evangelization.gif';
import management from '../../src/assets/gif/management.gif';
import { ScriptFile } from '../constants/server.constants';
import GlobalPresence from '../Component/GlobalPresence';
// import background from "../../public/assets/images/slider/slide-1.png"

function Home() {
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState(1);
  const [count, setCount] = useState(0);
  const [allcaseStudies, setAllcaseStudies] = useState([]);
  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
    // getDataCase();
    const script = document.createElement('script');

    script.src = ScriptFile;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [page]);
  // const addScriptJS = (url) => {
  //     var newScript = document.createElement("script");
  //     newScript.src = url;
  //     if (url.indexOf("static.zdassets.com/") > -1) {
  //       newScript.id = "ze-snippet";
  //     }

  //     document.body.appendChild(newScript);
  //   };

  const getData = async () => {
    // setLoading(true);
    GetAllBanners().then((resp) => {
      if (resp.data.status === 200) {
        setBanner(resp.data.data);
        // setLoading(false);
      }
    });
  };
  //   const handlePageClick = (event, newPage) => {
  //     setPage(newPage);
  //   };
  //   const data = {
  //     page: page,
  //     limit: 6,
  //   };
  //   const getDataCase = async () => {
  //     setLoading(true);
  //     GetAllCaseStudies(data)

  //       .then((resp) => {
  //         if (resp.data.status === 200) {

  //           setAllcaseStudies(resp.data.data.caseStudyList);
  //           setCount(resp.data.data.totalPages);
  //           setLoading(false);
  //         }
  //       });
  //   };
  // const getData= async() =>{
  //     const res = await fetch("http://52.250.20.141/silverleaf-api/getBanners")
  //     const data = await res.json()
  // }
  return (
    <React.Fragment>
      <div className="page-wrapper">
        {/* <!-- Start header --> */}
        <header id="header" className="site-header header-style-1">
          <Navbar />
        </header>
        {/* {banner && banner.length == 0 ? (
          <section className="hero-slider hero-slider-s2">
            <div className="swiper-container">
              <div className="swiper-wrapper">
                {/* <div className="swiper-slide">
                  <div
                    className="slide-inner slide-bg-image"
                    style={{
                      backgroundImage:
                        'url(https://themegeniuslab.com/html/netserv-live/assets/images/slider/slide-3.jpg)',
                    }}
                  >
                    <div className="container">
                      <div data-swiper-parallax="300" className="slide-title">
                        <h2>Best IT Service Around the world</h2>
                      </div>
                      <div data-swiper-parallax="400" className="slide-text">
                        <p>
                          Etiam sit amet orci eget eros faucibus tincidunt. Duis
                          leo. Sed fringilla mauris sit amet nibh. Donec sodales
                          sagittis magna.{' '}
                        </p>
                      </div>
                      <div className="clearfix"></div>
                      {/* <div data-swiper-parallax="500" className="slide-btns">
                                    <a href="#" className="theme-btn">Discover More</a> 
                                </div> 
                    </div>
                  </div>
                </div> */}

        {/* <div className="swiper-slide">
                  <div
                    className="slide-inner slide-bg-image"
                    style={{
                      backgroundImage:
                        'url(https://themegeniuslab.com/html/netserv-live/assets/images/slider/slide-3.jpg)',
                    }}
                  >
                    <div className="container">
                      <div data-swiper-parallax="300" className="slide-title">
                        <h2>We are IT Service Consulting parul</h2>
                      </div>
                      <div data-swiper-parallax="400" className="slide-text">
                        <p>
                          Etiam sit amet orci eget eros faucibus tincidunt. Duis
                          leo. Sed fringilla mauris sit amet nibh. Donec sodales
                          sagittis magna.{' '}
                        </p>
                      </div>
                      <div className="clearfix"></div>
                      <div data-swiper-parallax="500" className="slide-btns">
                        <a href="#" className="theme-btn">
                          Discover More
                        </a>
                      </div>
                    </div>
                  </div>
                </div> 
              </div>

              <div className="swiper-pagination"></div>
              <div className="swiper-button-next"></div>

              <div className="swiper-button-prev"></div>
            </div>
          </section>
        ) : (
          ''
        )} */}
        {/* <!-- end of header --> */}
        <section className="hero-slider hero-slider-s2">
          <div className="swiper-container">
            <div className="swiper-wrapper">
              {banner.length > 0 &&
                banner.map((value) => (
                  <div className="swiper-slide" key={value.title}>
                    <div
                      className="slide-inner slide-bg-image"
                      style={{ backgroundImage: `url(${value.image})` }}
                    >
                      <div className="container">
                        <div data-swiper-parallax="300" className="slide-title">
                          <h2>{value.title}</h2>
                        </div>
                        <div data-swiper-parallax="400" className="slide-text">
                          <p>{value.description} </p>
                        </div>
                        <div className="clearfix"></div>
                        {/* <div data-swiper-parallax="500" className="slide-btns">
                                    <a href="#" className="theme-btn">Discover More</a> 
                                </div> */}
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            <div className="swiper-pagination"></div>
            <div className="swiper-button-next"></div>

            <div className="swiper-button-prev"></div>
          </div>
        </section>

        {/* <!-- start features-section --> */}
        {/* <section className="features-section section-padding">
          <div className="container">
            <div className="row">
              <div className="col col-xs-12">
                <div className="feature-grids">
                  <div className="grid">
                    <div>
                     // <i className="fi flaticon-atom"></i> 
                      <img
                        src={consulting}
                        style={{
                          width: '90px',
                          height: '90px',
                          marginBottom: '30px',
                        }}
                      />
                    </div>
                    <h3>Software Licensing Advisory Services </h3>
                  </div>
                  <div className="grid">
                    <div>
                    //  <i className="fi flaticon-customer-service"></i> 
                      <img
                        src={management}
                        style={{
                          width: '90px',
                          height: '90px',
                          marginBottom: '30px',
                        }}
                      />
                    </div>
                    <h3>Managed SAM (Software Asset Management) Services</h3>
                  </div>
                  <div className="grid">
                    <div>
                   //  <i className="fi flaticon-computer"></i> 
                      <img
                        src={evangelization}
                        style={{
                          width: '90px',
                          height: '90px',
                          marginBottom: '30px',
                        }}
                      />
                    </div>
                    <h3>Technology Evangelization</h3>
                  </div>
                  // <div className="grid">
                  //   <div>
                      
                  //     <img
                  //       src={optimization}
                  //       style={{
                  //         width: '90px',
                  //         height: '90px',
                  //         marginBottom: '30px',
                  //       }}
                  //     />
                  //   </div>
                  //   <h3>Procurement Optimization</h3>
                  // </div>
                </div>
              </div>
            </div>
          </div>
          // <!-- end container -->
        </section> */}
        {/* <!-- end features-section --> */}

        {/* <AboutUs /> */}

        <OurServices />
        {/* <!-- end services-section-s2 --> */}

        {/* <!-- start cta-section-s2 --> */}
        <section className="cta-section-s2">
          <div className="container">
            <div className="row">
              <div className="col col-xs-12">
                <div className="cta-content" style={{ textAlign: 'left' }}>
                  <h4>
                    Guaranteed savings of 10% & more on all Software Licensing
                    Spends Annually
                  </h4>
                  <p>&nbsp;</p>
                  <p>
                    <span style={{ color: '#ddd' }}>
                      Partner with us to demystify software licensing, manage
                      complex licensing contracts, reduce costs, align spend to
                      consumption,
                      <br /> build governance across processes/policies and
                      manage compliance
                    </span>{' '}
                  </p>
                  <a href="/contact" className="theme-btn-s2">
                    Contact us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- end cta-section-s2 --> */}

        <EmailContact />

        <Manager />
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <div className="section-title-s5">
                <GlobalPresence />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

      {/* <!-- All JavaScript files
================================================== --> */}

      <script src="assets/js/jquery.min.js"></script>
      <script src="assets/js/bootstrap.min.js"></script>

      {/* <!-- Plugins for this template --> */}
      <script src="assets/js/jquery-plugin-collection.js"></script>
      <script
        defer
        src="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js"
        integrity="sha512-gY25nC63ddE0LcLPhxUJGFxa2GoIyA5FLym4UJqHDEMHjp8RET6Zn/SHo1sltt3WuVtqfyxECP38/daUc/WVEA=="
        referrerpolicy="no-referrer"
      ></script>

      {/* <!-- Custom script for this template --> */}
      <script src="assets/js/script.js"></script>
    </React.Fragment>
  );
}

export default Home;
