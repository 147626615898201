import React from 'react';
import { Link } from 'react-router-dom';

function AboutUs() {
  return (
    <div>
      <section className="about-section-s2">
        <div className="container">
          <div className="row">
            <div className="col col-md-6">
              <div className="section-title-s3">
                {/* <span>About us</span> */}
                <h2>Take a quick tour about us!</h2>
              </div>
              <div className="details">
                <p style={{ textAlign: 'left' }}>
                  Silver Leaf Solutions is a Global & Independent Organization
                  of Software Licensing Consultants, Licensing Contract
                  Specialists, Legal Experts and Tax Advisors that help our
                  clients in realizing Guaranteed savings of 10% and more
                  annually, across Multi-Year Software Licensing Contracts of
                  major software publishers like Microsoft, Oracle, SAP, IBM,
                  VMWare & Adobe.
                </p>
                <p style={{ textAlign: 'left' }}>
                  {' '}
                  Our global team of consultants has an extensive and exhaustive
                  past experience of working with World Wide Licensing & Pricing
                  Teams of major software publishers as well as in the ecosystem
                  surrounding large software contracts that require related
                  compliance, legal and tax advisory.{' '}
                </p>
                <ul style={{ textAlign: 'left' }}>
                  {/* <li>Incorporated in July 2015.</li> */}
                  <li>
                    Diverse team of 45+ technology consultants, licensing
                    specialists and security solutions architects
                  </li>
                  <li>Independent & Privately owned</li>
                  <li>
                    Worldwide offices at India, Australia, Singapore, United
                    States & Western Europe
                  </li>
                  <li>
                    Global Knowledge Resource Center (GKRC) – Research &
                    Development at Gurugram, India
                  </li>
                  <li>
                    {' '}
                    Experts in Mergers, Acquisitions, and Divestitures, Global
                    Contracts, Price Benchmarking, Taxation & Compliance
                  </li>
                </ul>
                {/* <a href="#" >More About us</a> */}
                {/* <Link to="/about" style={{textDecoration:"none"}} className="theme-btn-s3">More About us</Link> */}
              </div>
            </div>
            <div className="col col-md-6">
              <div className="img-holder">
                <img
                  src="assets/images/AboutUs.jpg"
                  style={{ width: '430px', height: '500px' }}
                  alt="about"
                />
                {/* <img src="assets/images/AboutUs2.jpg" alt /> */}
              </div>
            </div>
          </div>
        </div>
        {/* <!-- end container --> */}
      </section>
    </div>
  );
}

export default AboutUs;
