import React, { useEffect } from "react";
// import { Link } from 'react-router-dom';
import AboutUs from "../Component/AboutUs";
import Footer from "../Component/Footer";
import Manager from "../Component/Manager";
import Navbar from "../Component/Navbar";
// import consulting from '../../src/assets/gif/consulting.gif';
// import evangelization from '../../src/assets/gif/evangelization.gif';
// import management from '../../src/assets/gif/management.gif';
// import security from '../../src/assets/gif/legal.gif';
// import infrastructure from '../../src/assets/gif/cloud-network.gif';
// import optimization from '../../src/assets/gif/optimization.gif';
import { ScriptFile } from "../constants/server.constants";
// import Awards from '../Component/Awards';
import AboutUsCarousal from "../Component/AboutUsCarousal";
import Awards from "../Component/Awards";

import sliderImg1 from "../assets/images/slider/about-page-1.jpg";
import sliderImg2 from "../assets/images/slider/about-page-2.jpg";
import sliderImg3 from "../assets/images/slider/about-page-3.jpg";
import Slider from "react-slick";

function About() {
  useEffect(() => {
    const script = document.createElement("script");

    // script.src = 'http://52.250.20.141/silverleaf-backend/script/script.js';
    script.src = ScriptFile;
    script.async = true;
    window.scrollTo(0, 0);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <React.Fragment>
      <div className="page-wrapper">
        {/* <!-- Start header --> */}
        <header id="header" className="site-header header-style-1">
          <Navbar />
        </header>
        {/* <!-- end of header --> */}

        {/* <!-- start page-title --> */}
        {/* <section className="page-title-about">
          <div className="container">
            <div className="row">
              <div className="col col-xs-12">
                <div className="section-title-s6 section-title-s6-about">
                  <h2>ABOUT US</h2>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="about-page-slider-section">
          <h2 className="about-page-slider-headingContainer">ABOUT US</h2>
          <div className="about-page-slider-overlay" />
          <Slider {...settings}>
            <div className="about-page-slider-imageContainer">
              <img src={sliderImg1} alt="slider-img-1" />
            </div>
            <div className="about-page-slider-imageContainer">
              <img src={sliderImg2} alt="slider-img-2" />
            </div>
            <div className="about-page-slider-imageContainer">
              <img src={sliderImg3} alt="slider-img-3" />
            </div>
          </Slider>
        </section>

        {/* <!-- end page-title --> */}
        {/* About us carousal */}
        {/* <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <div className="section-title-s5">
                <AboutUsCarousal />
              </div>
            </div>
          </div>
        </div> */}
        {/* About us carousal end*/}
        {/* <!-- start features-section --> */}

        {/* <!-- end features-section --> */}
        <div className="about--container">
          <AboutUs />
        </div>
        <div className="vision-mission-wrapper">
          <div className="vision-mission-card">
            <div className="vision-mission-img">
              <img src="assets/images/vision.jpg" alt="vision" />
            </div>
            <div className="consulting__title heading__title">Vision</div>

            <p>
              To be #1 Global IT Cost Optimization Advisory, Software Asset
              Management, IT Infrastructure & Security Services Organization.{" "}
            </p>
          </div>
          <div className="vision-mission-card">
            <div className="vision-mission-img">
              <img src="assets/images/mission.jpg" alt="vision" />
            </div>
            <div className="consulting__title heading__title">Mission</div>
            <p>
              To provide Research based, Independent, Software Licensing
              Advisory, Software Asset Management, IT Infrastructure & Security
              Services.{" "}
            </p>
          </div>
        </div>
        {/* <!-- start about-section-s2 --> */}
        {/* <section className="about-section-s2 section-padding">
    <div className="container">
        <div className="row">
            <div className="col col-md-6">
                <div className="section-title-s3">
                    <span>About us</span>
                    <h2>Take a quick tour of about us!</h2>
                </div>
                <div className="details">
                    <p>Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar</p>
                    <ul>
                        <li>Adipiscing sem neque sed ipsum. Nam quam </li>
                        <li>Honcus, sem quam semper libero, sit amet adipiscing sem neque</li>
                        <li>Ulamcorper ultricies nisi. Nam eget dui. Etiam rhoncus</li>
                        <li>Maecenas tempus, tellus eget condimentum rhoncus</li>
                    </ul>
                    <a href="#" className="theme-btn-s3">More About us</a>
                </div>
            </div>
            <div className="col col-md-6">
                <div className="img-holder">
                    <img src="assets/images/about-1.jpg" alt=""/>
                    <img src="assets/images/about-2.jpg" alt=""/>
                </div>
            </div>
        </div>
    </div> 
    ="<!-- end contaier -->
</section> */}
        {/* <!-- end about-section-s2 --> */}

        {/* <!-- start work-process --> */}
        {/* <section className="work-process section-padding p-t-0">
    <div className="container">
        <div className="row">
            <div className="col col-xs-12">
                <div className="work-process-grids clearfix">
                    <div className="grid">
                        <div className="count">01</div>
                        <h3>Converstion with <br/>Client</h3>
                        <p>Quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo. Sed fringilla mauris sit amet nibh.</p>
                    </div>
                    <div className="grid">
                        <div className="count">02</div>
                        <h3>Find the relevent <br/>problem</h3>
                        <p>Quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo. Sed fringilla mauris sit amet nibh.</p>
                    </div>
                    <div className="grid">
                        <div className="count">03</div>
                        <h3>Start the <br/>project</h3>
                        <p>Quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo. Sed fringilla mauris sit amet nibh.</p>
                    </div>
                    <div className="separator"></div>
                </div>
            </div>
        </div>
    </div>  */}
        {/* <!-- end contaienr --> */}
        {/* // </section> */}
        {/* <!-- end work-process --> */}

        {/* <!-- start why-choose-us-section --> */}

        {/* <section className="why-choose-us-section section-padding">
          <div className="container">
            
            <div className="privacy-policy-row">
              <h3>Why choose us?</h3>
              <h2 style={{ marginTop: '0px' }}>
                Trusted company in services and support
              </h2>

              <p
                className="privacy-policy-paragraph"
                style={{ marginBottom: '40px' }}
              >
                Silver Leaf Solutions offers License Advisory Services that
                provide software licensing knowledge for mid-to-large size
                enterprises having software portfolios such as Microsoft,
                Oracle, SAP, IBM, Adobe, and VMWare. Benefits include but not
                limited to reduced spend, risks mitigation, increased awareness
                & transparency.
              </p>
            </div>
            <div className="row">
              <div className="col col-xs-12" style={{ width: '100%' }}>
                <div className="why-choose-grids clearfix">
                  <div className="grid">
                    <div className="img-holder">
                      <img
                        src="assets/images/why-choose/img-4.jpg"
                        alt="About"
                      />
                    </div>
                    <div className="details about_details">
                      <h3>Outcome Based</h3>
                     
                    </div>
                  </div>
                  <div className="grid">
                    <div className="img-holder">
                      <img
                        src="assets/images/why-choose/img-5.jpg"
                        alt="About"
                      />
                    </div>
                    <div className="details about_details">
                      <h3>Independent Confidant</h3>
                      
                    </div>
                  </div>
                  <div className="grid">
                    <div className="img-holder">
                      <img
                        src="assets/images/why-choose/img-6.jpg"
                        alt="About"
                      />
                    </div>
                    <div className="details about_details">
                      <h3>Research Based</h3>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </section> */}

        {/* <!-- end why-choose-us-section --> */}
        <Awards />
        {/* <!-- start our-skill-section --> */}
        {/* <section className="our-skill-section section-padding">
          <div className="container">
            <div className="row">
              <div className="col col-md-7 col-md-offset-5">
                <div className="section-title-s4">
                  <span>Our skills</span>
                  <h2>Trusted company in services and support</h2>
                </div>
                <div className="details">
                  <p>
                    Our global team of consultants has an extensive and
                    exhaustive past experience of working with World Wide
                    Licensing & Pricing Teams of major software publishers as
                    well as in the ecosystem surrounding large software
                    contracts that require related compliance, legal and tax
                    advisory.
                  </p>
                  <div className="skills">
                    <div className="skill">
                      <h6>Software Licensing Advisory</h6>
                      <div className="progress">
                        <div className="progress-bar" data-percent="85"></div>
                      </div>
                    </div>
                    <div className="skill">
                      <h6>
                        Managed SAM (Managed SAM (Software Asset Management))
                      </h6>
                      <div className="progress">
                        <div className="progress-bar" data-percent="95"></div>
                      </div>
                    </div>
                    <div className="skill">
                      <h6>Technology Evangelization</h6>
                      <div className="progress">
                        <div className="progress-bar" data-percent="92"></div>
                      </div>
                    </div>
                    <div className="skill">
                      <h6>Procurement Optimization</h6>
                      <div className="progress">
                        <div className="progress-bar" data-percent="92"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </section> */}
        {/* <!-- end container --> */}
        {/* <!-- end our-skill-section --> */}

        {/* start core value section */}
        <div className="core_value">
          <div className="consulting__title heading__title">
            These Core Values Define Who We Are And The Work We Do.
          </div>
          <div className="col col-xs-12 ">
            <section className="services-section section-padding core-value-section">
              <div className="container">
                <div className="row">
                  <div className="services-grid clearfix core-value-section">
                    <div className="grids core-value-girds">
                      <h4>Customer Obsession</h4>
                      <p>
                        <ul>
                          <li>
                            At Silver Leaf Solutions, customer obsession means
                            consistently prioritizing the needs and goals of the
                            customer over the company's interests. It involves
                            understanding the customer's business challenges,
                            goals, and expectations and providing tailored
                            solutions that meet their unique requirements. By
                            being customer-obsessed, we can build lasting
                            relationships based on trust, transparency, and
                            value. It also means being proactive in anticipating
                            customer needs, providing timely support and
                            exceptional service, and continuously improving the
                            customer experience. Ultimately, customer obsession
                            leads to increased customer satisfaction, loyalty,
                            and advocacy, which drive long-term success and
                            growth.
                          </li>
                        </ul>
                      </p>
                    </div>
                    <div className="grids core-value-girds">
                      <h4>Ownership & Accountability</h4>
                      <p>
                        <ul>
                          <li>
                            Ownership and accountability are essential core
                            values at Silver Leaf Solutions. It means taking
                            full responsibility for one's work and outcomes and
                            being proactive in finding solutions to problems. It
                            involves taking ownership of projects, tasks, and
                            decisions, and following through on commitments. By
                            embracing ownership and accountability, we can
                            foster a culture of trust, transparency, and
                            continuous improvement. It also means learning from
                            mistakes and using them as opportunities to grow and
                            improve. Through ownership and accountability, we
                            deliver high-quality work, exceed client
                            expectations, and build strong relationships based
                            on mutual trust and respect. Overall, ownership and
                            accountability are critical to achieving long-term
                            success and growth.
                          </li>
                        </ul>
                      </p>
                    </div>
                    <div className="grids core-value-girds">
                      <h4>Empathy</h4>
                      <p>
                        <ul>
                          <li>
                            Empathy is a crucial core value for Silver Leaf
                            Solutions. As a perpetual Start-Up Company, Empathy
                            means understanding and connecting with the needs,
                            challenges, and aspirations of clients, employees,
                            and stakeholders.
                            <br />
                            It involves actively listening, demonstrating
                            sensitivity, and being responsive to the
                            perspectives and feelings of others. Only by
                            embodying empathy, we can build relationships based
                            on mutual trust, respect, and understanding. It also
                            means creating a supportive and inclusive work
                            environment where everyone feels valued and
                            empowered. Through empathy, we foster creativity,
                            innovation, and collaboration, leading to better
                            solutions, products, and services. Ultimately,
                            empathy is essential to creating a positive impact
                            on society and achieving sustainable growth for
                            Silver Leaf Solutions.
                          </li>
                        </ul>
                      </p>
                    </div>
                    <div className="grids core-value-girds">
                      <h4>Integrity</h4>
                      <p>
                        <ul>
                          <li>
                            Integrity is a fundamental core value for any
                            company. It means consistently adhering to ethical
                            and moral principles, being transparent, honest, and
                            trustworthy in all interactions. It involves
                            upholding high standards of conduct and
                            professionalism, even in challenging situations. By
                            embracing integrity, we build a reputation for
                            reliability, credibility, and excellence. It also
                            means treating clients, employees, and stakeholders
                            with respect and fairness, and honouring commitments
                            and promises. Through integrity, our company creates
                            a positive impact on society, foster trust and
                            loyalty, and attract top talent and clients.
                          </li>
                        </ul>
                      </p>
                    </div>
                    {/* <div className="grids core-value-girds">
                        <h4>
                          WE BELIEVE IN INNOVATION AND CREATIVE PROBLEM SOLVING
                        </h4>
                        <p>
                          <ul>
                            <li>data pending</li>
                          </ul>
                        </p>
                      </div> */}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        {/* end core value section */}

        {/* <!-- start team-section --> */}
        <section className="team-section section-padding">
          <div className="container">
            <div className="row">
              <div className="col col-lg-4  col-lg-offset-4 col-sm-6 col-sm-offset-3"></div>
            </div>
            <div className="row">
              <div className="col col-xs-12">
                <div className="team-grids"></div>
              </div>
            </div>
          </div>
          {/* <!-- end container --> */}
        </section>
        {/* <!-- end team-section --> */}

        {/* <!-- start pricing-section --> */}
        {/* <section className="pricing-section section-padding p-t-0">
    <div className="container">
        <div className="row">
            <div className="col col-lg-6 col-lg-offset-3">
                <div className="section-title-s6">
                    <span>Pricing</span>
                    <h2>Choose Your Best Plan</h2>
                    <p>Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus</p>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col col-xs-12">
                <div className="pricing-grids clearfix">
                    <div className="grid">
                        <div className="pricing-header">
                            <h4>Basic</h4>
                            <p>tellus eget condimentum rhoncus, sem quam semper libero</p>
                            <div className="price">
                                <h3>$45.99 <span>/ mo</span></h3>
                            </div>
                        </div>
                        <div className="pricing-body">
                            <ul>
                                <li><i className="ti-check"></i> Nullam quis ante. Etiam sit amet orci </li>
                                <li><i className="ti-check"></i> Leo eget bibendum sodales </li>
                                <li><i className="ti-check"></i> Etiam sit amet orci eget eros faucibus </li>
                                <li><i className="ti-check"></i> Etiam ultricies nisi vel augue </li>
                                <li><i className="ti-check"></i> Leo eget bibendum sodales </li>
                            </ul>
                        </div>
                        <div className="pricing-footer">
                            <a href="#" className="theme-btn">Choose plan</a>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="pricing-header">
                            <h4>Most Populer</h4>
                            <p>tellus eget condimentum rhoncus, sem quam semper libero</p>
                            <div className="price">
                                <h3>$75.99 <span>/ mo</span></h3>
                            </div>
                        </div>
                        <div className="pricing-body">
                            <ul>
                                <li><i className="ti-check"></i> Nullam quis ante. Etiam sit amet orci </li>
                                <li><i className="ti-check"></i> Leo eget bibendum sodales </li>
                                <li><i className="ti-check"></i> Etiam sit amet orci eget eros faucibus </li>
                                <li><i className="ti-check"></i> Etiam ultricies nisi vel augue </li>
                                <li><i className="ti-check"></i> Leo eget bibendum sodales </li>
                            </ul>
                        </div>
                        <div className="pricing-footer">
                            <a href="#" className="theme-btn">Choose plan</a>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="pricing-header">
                            <h4>Premium</h4>
                            <p>tellus eget condimentum rhoncus, sem quam semper libero</p>
                            <div className="price">
                                <h3>$99.99 <span>/ mo</span></h3>
                            </div>
                        </div>
                        <div className="pricing-body">
                            <ul>
                                <li><i className="ti-check"></i> Nullam quis ante. Etiam sit amet orci </li>
                                <li><i className="ti-check"></i> Leo eget bibendum sodales </li>
                                <li><i className="ti-check"></i> Etiam sit amet orci eget eros faucibus </li>
                                <li><i className="ti-check"></i> Etiam ultricies nisi vel augue </li>
                                <li><i className="ti-check"></i> Leo eget bibendum sodales </li>
                            </ul>
                        </div>
                        <div className="pricing-footer">
                            <a href="#" className="theme-btn">Choose plan</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
    
</section> */}
        {/* <!-- end pricing-section --> */}

        {/* <!-- start testimonials-section-s2 --> */}
        {/* <section className="testimonials-section-s2 section-padding">
    <div className="container">
        <div className="row">
            <div className="col col-xs-12">
                <div className="testimonial-grids testimonials-slider">
                    <div className="grid">
                        <div className="client-info">
                            <div className="client-pic">
                                <img src="assets/images/testimonials/img-1.jpg" alt=""/>
                            </div>
                            <h4>Jhon Dow</h4>
                            <span>Manager of Founcation</span>
                        </div>
                        <div className="client-quote">
                            <p>Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncusDonec vitae sapien ut libero venenatis faucibusDonec vitae sapien ut libero venenatis </p>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="client-info">
                            <div className="client-pic">
                                <img src="assets/images/testimonials/img-2.jpg" alt=""/>
                            </div>
                            <h4>Jain michel</h4>
                            <span>Manager of Founcation</span>
                        </div>
                        <div className="client-quote">
                            <p>Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncusDonec vitae sapien ut libero venenatis faucibusDonec vitae sapien ut libero venenatis </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
    
</section> */}
        {/* <!-- end testimonials-section-s2 --> */}
        {/* <Manager /> */}

        {/* <!-- start cta-section-s2 --> */}
        <section className="cta-section-s2">
          <div className="container">
            <div className="row">
              <div className="col col-xs-12">
                <div className="cta-content" style={{ textAlign: "left" }}>
                  <h4>
                    Guaranteed savings of 10% & more on all Software Licensing
                    Spends Annually
                  </h4>
                  <p>&nbsp;</p>
                  <p>
                    <span style={{ color: "#ddd" }}>
                      Partner with us to demystify software licensing, manage
                      complex licensing contracts, reduce costs, align spend to
                      consumption,
                      <br /> build governance across processes/policies and
                      manage compliance
                    </span>{" "}
                  </p>
                  <a href="/contact" className="theme-btn-s2">
                    Contact us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- end cta-section-s2 --> */}

        {/* <!-- start blog-section --> */}
        {/* <section className="blog-section section-padding">
    <div className="container">
        <div className="row">
            <div className="col col-lg-5 col-md-5">
                <div className="section-title-s3">
                    <span>Blog</span>
                    <h2>See the latest tips & tricks from our blog!</h2>
                </div>
            </div>
            <div className="col col-lg-6 col-lg-offset-1 col-md-7">
                <div className="text">
                    <p>Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel</p>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col col-xs-12">
                <div className="blog-grids">
                    <div className="grid">
                        <div className="entry-media">
                            <img src="assets/images/blog/img-1.jpg" alt=""/>
                        </div>
                        <div className="details">
                            <div className="entry-meta">
                                <ul>
                                    <li>13 Jul, 2019</li>
                                    <li>5 Comments</li>
                                </ul>
                            </div>
                            <h3><a href="#">Incredibly visually appealing, it's also mentally appealing</a></h3>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="entry-media">
                            <img src="assets/images/blog/img-2.jpg" alt=""/>
                        </div>
                        <div className="details">
                            <div className="entry-meta">
                                <ul>
                                    <li>13 Jul, 2019</li>
                                    <li>5 Comments</li>
                                </ul>
                            </div>
                            <h3><a href="#">Technology trends, industry influencers hand </a></h3>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="entry-media">
                            <img src="assets/images/blog/img-3.jpg" alt=""/>
                        </div>
                        <div className="details">
                            <div className="entry-meta">
                                <ul>
                                    <li>13 Jul, 2019</li>
                                    <li>5 Comments</li>
                                </ul>
                            </div>
                            <h3><a href="#">Entrepreneurs and small owners you get net.</a></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
   
</section> */}
        {/* <!-- end blog-section --> */}

        {/* <!-- start news-letter-section --> */}
        {/* <section className="news-letter-section">
          <div className="container">
            <div className="row">
              <div className="col col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
                <div className="newsletter">
                  <h3>Email Newsletter</h3>
                  <p>
                    Aenean leo ligula porttitor eu consequat vitae eleifend ac
                    enim. Aliquam lorem ante dapibus in viverra quiss consequat
                    vitae
                  </p>
                  <div className="newsletter-form">
                    <form>
                      <div>
                        <input type="text" className="form-control" />
                        <button type="submit">Subscribe</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
        </section> */}
        {/* <!-- end news-letter-section --> */}
        <Footer />

        {/* <!-- start site-footer --> */}
        {/* <footer className="site-footer">
    <div className="upper-footer">
        <div className="container">
            <div className="row">
                <div className="separator"></div>
                <div className="col col-lg-4 col-md-3 col-sm-6" style={{ textAlign: "left" }}>
                    <div className="widget about-widget">
                        <div className="logo widget-title">
                            <img src="assets/images/footer-logo.png" alt style={{width:"108px",height:"57px",marginTop:"-31px"}} />
                        </div>
                        <p>Condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar</p>
                        <ul>
                            <li><a href="#"><i className="ti-facebook"></i></a></li>
                            <li><a href="#"><i className="ti-twitter-alt"></i></a></li>
                            <li><a href="#"><i className="ti-linkedin"></i></a></li>
                            <li><a href="#"><i className="ti-pinterest"></i></a></li>
                            <li><a href="#"><i className="ti-youtube"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div className="col col-lg-3 col-md-3 col-sm-6" style={{ textAlign: "left" }}>
                    <div className="widget contact-widget service-link-widget">
                        <div className="widget-title">
                            <h3>Contact</h3>
                        </div>
                        <ul>
                            <li>510, Vipul Trade Centre, Sector 48, <br/>Sohna Road, Gurugram.<br/>Haryana – 122018</li>
                            <li><span>Phone:</span> +91-124-2213197<br/>+91-9818020910 </li>
                            <li><span>Email:</span>info@silverleafsolutions.com</li>
                            <li><span>Global Site:</span> www.silverleafsolutions.com</li>
                        </ul>
                    </div>
                </div>
                <div className="col col-lg-3 col-md-3 col-sm-6" style={{ textAlign: "left" }}>
                    <div className="widget link-widget">
                        <div className="widget-title">
                            <h3>Services</h3>
                        </div>
                        <ul>
                            <li><a href="#">Consulting & Advisory</a></li>
                            <li><a href="#">Managed SAM (Software Asset Management)</a></li>
                            <li><a href="#">Technology Evangelization</a></li>
                            <li><a href="#">Procurement Optimization</a></li>
                            
                        </ul>
                    </div>
                </div>
                <div className="col col-lg-2 col-md-3 col-sm-6" style={{ textAlign: "left" }}>
                    <div className="widget link-widget">
                        <div className="widget-title">
                            <h3>Company</h3>
                        </div>
                        <ul>
                            <li><a href="#">About our company</a></li>
                            <li><a href="#">Client’s Testimonial</a></li>
                            <li><a href="#">Privacy policy</a></li>
                            <li><a href="#">Pricing and plans</a></li>
                            <li><a href="#">Cookies Policy</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>  */}
        {/* <!-- end container --> */}
        {/* </div>
    <div className="lower-footer">
        <div className="container">
            <div className="row">
                <div className="separator"></div>
                <div className="col col-xs-12">
                    <p className="copyright">Copyright &copy; 2019 netserv. All rights reserved.</p>
                </div>
            </div>
        </div>
    </div>
</footer> */}
        {/* <!-- end site-footer --> */}
      </div>
      {/* <!-- end of page-wrapper --> */}

      {/* <!-- All JavaScript files
================================================== --> */}
    </React.Fragment>
  );
}

export default About;
