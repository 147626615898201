import React from "react";
const GlobalPresence = () => {
  return (
    <>
      <section className="contact-info-section section-padding">
        <h2 style={{ marginBottom: "80px" }}>Global Presence</h2>
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <div className="contact-info clearfix">
                <div style={{ height: "350px" }}>
                  <div className="icon">
                    <img
                      src="assets/images/us.png"
                      style={{ borderRadius: "39px" }}
                    />
                    {/* <i className="ti-location-pin"></i> */}
                  </div>
                  <h5>USA</h5>
                  <p>
                    Silver Leaf Solutions LLC
                    <br />
                    1209 Orange Street
                    <br />
                    Wilmington,
                    <br />
                    DE 19801
                  </p>
                </div>
                <div style={{ height: "350px" }}>
                  <div className="icon">
                    {/* <i className="ti-location-pin"></i> */}
                    <img
                      src="assets/images/Singapore.png"
                      style={{ borderRadius: "39px" }}
                    />
                  </div>

                  <h5>Singapore</h5>
                  <p>
                    Silver Leaf Solutions Pte Ltd.
                    <br />
                    105 Cecil Street,
                    <br />
                    #15-02 The Octagon
                    <br />
                    Singapore (069534)
                  </p>
                </div>
                {/* <div style={{ height: '350px' }}>
                  <div className="icon">
                    <img
                      src="assets/images/Germany.png"
                      style={{ borderRadius: '39px' }}
                    />
                  </div>
                  <h5>Germany</h5>
                  <p>
                    Silver Leaf Solutions GmbH c/o Paschen Rechtsanwailte,
                    Kaiserin-Augusta-Allee 113 Berlin (10553)
                  </p>
                </div> */}
                <div style={{ height: "350px" }}>
                  <div className="icon">
                    {/* <i className="ti-location-pin"></i> */}
                    <img
                      src="https://cdn3.iconfinder.com/data/icons/world-flags-circled-vol-2/48/India-512.png"
                      style={{ borderRadius: "39px" }}
                    />
                  </div>
                  <h5>India(HQ)</h5>
                  <p>
                    Silver Leaf Solutions Pvt Ltd.
                    <br />
                    5th floor, Emerald Plaza, <br />
                    Sector 65, Golf Course Extension Road, Gurgaon 122102
                    {/* 528-530, Emerald Plaza,
                    <br />
                    Sector 65,
                    <br />
                    Golf Course Road,
                    <br />
                    Gurgaon 122102 */}
                  </p>
                </div>
                <div style={{ height: "350px" }}>
                  <div className="icon">
                    {/* <i className="ti-location-pin"></i> */}
                    <img
                      src="https://cdn3.iconfinder.com/data/icons/world-flags-circled-vol-2/48/India-512.png"
                      style={{ borderRadius: "39px" }}
                    />
                  </div>
                  <h5>India</h5>
                  <p>
                    Silver Leaf Solutions Pvt Ltd. <br />
                    C-1404, 14th Floor
                    <br />
                    Kailash Business Park,
                    <br />
                    Hiranandani Link Road,
                    <br />
                    Vikhroli (West), <br />
                    Mumbai,India–400079
                  </p>
                </div>

                <div style={{ height: "350px" }}>
                  <div className="icon">
                    {/* <i className="ti-location-pin"></i> */}
                    <img
                      src={require("../assets/images/UAE-flag.png")}
                      style={{ borderRadius: "39px" }}
                    />
                  </div>
                  <h5>UAE</h5>
                  <p>
                    Silver Leaf Technology Solutions CO. L.L.C. 1414,
                    <br />
                    Sabha Build,
                    <br />
                    Jabal Ali Industrial First,
                    <br />
                    Dubai, UAE
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GlobalPresence;
