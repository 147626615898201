import AppConstants from '../constants';
import AxiosInstance from './http.handlers';

let url = AppConstants.ServerConstants.API_ROUTES;

export const GetApplyCareer = async (body, headers) => {
  return await AxiosInstance.post(url.careers.Apply_JOB, body, {
    headers,
  })
    .then((resonse) => resonse)
    .catch((error) => error);
};
export const GetAllCareer = async () => {
  return await AxiosInstance.get(url.careers.GET_All_Jobs)
    .then((response) => response)
    .catch((error) => error);
};

export const getCareerByid = async (id, headers) => {
  return await AxiosInstance.post(url.careers.Apply_JOB, id, {
    headers,
  })
    .then((resonse) => resonse)
    .catch((error) => error);
};

export const GetJobListCareer = async (body, headers) => {
  return await AxiosInstance.post(url.careers.GET_JOB_LIST, body, {
    headers,
  })
    .then((resonse) => resonse)
    .catch((error) => error);
};
