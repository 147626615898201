import AppConstants from "../constants";
import AxiosInstance from "./http.handlers";

let url = AppConstants.ServerConstants.API_ROUTES 

export const ContactUs = async (body, headers) => {
  return await AxiosInstance.post(
    
    url.CONTACT.EMAIL_US,
    body,
    {
      headers,
    }
  )
    .then((resonse) => resonse)
    .catch((error) => error);
};





