import React from 'react';

function Manager() {
  return (
    <div>
      <section className="testimonials-section-s2 section-padding">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <div className="testimonial-grids testimonials-slider">
                <div className="grid">
                  <div className="client-info">
                    <div>
                      <img
                        src="assets/gif/it_service.gif"
                        className="manager_gif"
                      />
                    </div>
                    <h4>IT Service Company</h4>
                    {/* <span>Manager of Founcation</span> */}
                  </div>
                  <div className="client-quote">
                    <p>
                      Our EA contract is to be merged with the newly acquired
                      entity. Silver Leaf Solutions Team helped with the
                      contract restructuring and gaining additional discounts
                      through negotiations which realized 50% Savings on the New
                      BoM. The Team was quite prompt and had a great experience
                      in working with them.
                    </p>
                  </div>
                </div>
                <div className="grid">
                  <div className="client-info">
                    <div>
                      <img
                        src="assets/gif/search.gif"
                        className="manager_gif"
                      />
                    </div>
                    <h4>Global Executive Search Firm</h4>
                    {/* <span>Manager of Founcation</span> */}
                  </div>
                  <div className="client-quote">
                    <p>
                      Growth of the firm across the globe lead to increase the
                      cost of IT exponentially YoY. Silver Leaf Solutions helped
                      in optimizing the contract and cost savings of 22%, along
                      with the best evangelized BoM. The Team also helped with
                      the global contract structure and purchase model.
                    </p>
                  </div>
                </div>
                <div className="grid">
                  <div className="client-info">
                    <div>
                      <img
                        src="assets/gif/company.gif"
                        className="manager_gif"
                      />
                    </div>
                    <h4>Asia's Premium Bio Pharmaceutical Company</h4>
                    {/* <span>Manager of Founcation</span> */}
                  </div>
                  <div className="client-quote">
                    <p>
                      Silver Leaf Solutions helped in realizing savings of
                      around USD 1.3 Million over the agreement term. Thanks to
                      the whole team for thorough research on BoM, Discounts,
                      Exceptions, and Grants. Silver Leaf Solutions Team helped
                      us in implementing alternative technologies and imparting
                      licensing knowledge to the internal team.
                    </p>
                  </div>
                </div>
                <div className="grid">
                  <div className="client-info">
                    <div>
                      <img src="assets/gif/bank.gif" className="manager_gif" />
                    </div>
                    <h4>Indian New Generation Bank</h4>
                    {/* <span>Manager of Founcation</span> */}
                  </div>
                  <div className="client-quote">
                    <p>
                      We saved around 11% on the total contract value for 3
                      years renewal. Silver Leaf Team made it possible through
                      managed SAM, ULA contract analysis, contract negotiations,
                      and secured favourable terms around areas of deployment.
                    </p>
                  </div>
                </div>
                <div className="grid">
                  <div className="client-info">
                    <div>
                      <img
                        src="assets/gif/financial.gif"
                        className="manager_gif"
                      />
                    </div>
                    <h4>Financial Services Company</h4>
                    {/* <span>Manager of Founcation</span> */}
                  </div>
                  <div className="client-quote">
                    <p>
                      Silver Leaf Solutions helped in optimizing through
                      technical SAM assessment, right price benchmarking, buyout
                      strategy for subscription licensing which lead to 30%
                      savings on the renewal BoM. It was a pleasure working with
                      the entire Silver Leaf Team.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- end container --> */}
      </section>
    </div>
  );
}

export default Manager;
