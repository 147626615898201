// import React, { useEffect } from 'react';
// import Footer from '../Component/Footer';
// import Navbar from '../Component/Navbar';
// import { Link } from 'react-router-dom';
// function Servicethreed() {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);
//   return (
//     <div>
//       <div className="page-wrapper">
//         {/* <!-- start preloader --> */}
//         {/* <div className="preloader">
//         <div className="sk-cube-grid">
//           <div className="sk-cube sk-cube1"></div>
//           <div className="sk-cube sk-cube2"></div>
//           <div className="sk-cube sk-cube3"></div>
//           <div className="sk-cube sk-cube4"></div>
//           <div className="sk-cube sk-cube5"></div>
//           <div className="sk-cube sk-cube6"></div>
//           <div className="sk-cube sk-cube7"></div>
//           <div className="sk-cube sk-cube8"></div>
//           <div className="sk-cube sk-cube9"></div>
//         </div>
//     </div> */}
//         {/* <!-- end preloader --> */}

//         {/* <!-- Start header --> */}
//         <header id="header" className="site-header header-style-1">
//           <Navbar />
//           {/* <nav className="navigation navbar navbar-default">
//             <div className="container">
//                 <div className="nav-row clearfix">
//                     <div className="navbar-header">
//                         <button type="button" className="open-btn">
//                             <span className="sr-only">Toggle navigation</span>
//                             <span className="icon-bar"></span>
//                             <span className="icon-bar"></span>
//                             <span className="icon-bar"></span>
//                         </button>
//                         <a className="navbar-brand" href="index.html"><img src="assets/images/logo.png" alt='services'></a>
//                     </div>
//                     <div id="navbar" className="navbar-collapse collapse navbar-right navigation-holder">
//                         <button className="close-navbar"><i className="ti-close"></i></button>
//                         <ul className="nav navbar-nav">
//                             <li className="menu-item-has-children">
//                                 <a href="#">Home</a>
//                                 <ul className="sub-menu">
//                                     <li><a href="index.html">Home Default</a></li>
//                                     <li><a href="index-2.html">Home Style 2</a></li>
//                                     <li><a href="index-3.html">Home Style 3</a></li>
//                                     <li className="menu-item-has-children">
//                                         <a href="#Level3">Header style</a>
//                                         <ul className="sub-menu">
//                                             <li><a href="index.html">Header Style 1</a></li>
//                                             <li><a href="index-2.html">Header Style 2</a></li>
//                                             <li><a href="index-3.html">Header Style 3</a></li>
//                                         </ul>
//                                     </li>
//                                 </ul>
//                             </li>
//                             <li className="menu-item-has-children">
//                                 <a href="#">Pages</a>
//                                 <ul className="sub-menu">
//                                     <li><a href="about.html">About</a></li>
//                                     <li><a href="contact.html">Contact</a></li>
//                                     <li><a href="team.html">Team</a></li>
//                                     <li><a href="testimonials.html">Testimonials</a></li>
//                                     <li><a href="testimonials-s2.html">Testimonials style 2</a></li>
//                                     <li><a href="faq.html">FAQ</a></li>
//                                     <li><a href="404.html">404</a></li>
//                                 </ul>
//                             </li>
//                             <li className="menu-item-has-children">
//                                 <a href="#">Services</a>
//                                 <ul className="sub-menu">
//                                     <li><a href="services.html">Services</a></li>
//                                     <li><a href="services-s2.html">Services style 2</a></li>
//                                     <li><a href="service-single.html">IT Management</a></li>
//                                     <li><a href="content-engineering.html">Content Engineering</a></li>
//                                     <li><a href="capital-marketing.html">Capital Marketing</a></li>
//                                     <li><a href="data-security.html">Data Security</a></li>
//                                     <li><a href="cyber-security.html">Cyber Security</a></li>
//                                 </ul>
//                             </li>
//                             <li className="menu-item-has-children">
//                                 <a href="#">Case studies</a>
//                                 <ul className="sub-menu">
//                                     <li><a href="projects.html">Case study</a></li>
//                                     <li><a href="projects-s2.html">Case study style 2</a></li>
//                                     <li><a href="project-single.html">Case Single</a></li>
//                                 </ul>
//                             </li>
//                             <li className="menu-item-has-children">
//                                 <a href="#">Shop</a>
//                                 <ul className="sub-menu">
//                                     <li><a href="shop.html">Shop</a></li>
//                                     <li><a href="shop-single.html">Shop single</a></li>
//                                 </ul>
//                             </li>
//                             <li className="menu-item-has-children">
//                                 <a href="#">Blog</a>
//                                 <ul className="sub-menu">
//                                     <li><a href="blog.html">Blog Default</a></li>
//                                     <li><a href="blog-left-sidebar.html">Blog left sidebar</a></li>
//                                     <li><a href="blog-fullwidth.html">Blog full width</a></li>
//                                     <li className="menu-item-has-children">
//                                         <a href="#Level3">Blog Details</a>
//                                         <ul className="sub-menu">
//                                             <li><a href="blog-single.html">Blog Single Default</a></li>
//                                             <li><a href="blog-single-left-sidebar.html">Blog single left sidebar</a></li>
//                                             <li><a href="blog-single-fullwidth.html">Blog single full width</a></li>
//                                         </ul>
//                                     </li>
//                                 </ul>
//                             </li>
//                             <li><a href="contact.html">Contact</a></li>
//                         </ul>
//                     </div><!-- end of nav-collapse -->

//                     <div className="cart-search-contact">
//                         <div className="header-search-form-wrapper">
//                             <button className="search-toggle-btn"><i className="fi flaticon-search"></i></button>
//                             <div className="header-search-form">
//                                 <form>
//                                     <div>
//                                         <input type="text" className="form-control" placeholder="Search here...">
//                                         <button type="submit"><i className="ti-search"></i></button>
//                                     </div>
//                                 </form>
//                             </div>
//                         </div>
//                         <div className="mini-cart">
//                             <button className="cart-toggle-btn"> <i className="fi flaticon-bag-1"></i> <span className="cart-count">02</span></button>
//                             <div className="mini-cart-content">
//                                 <div className="mini-cart-items">
//                                     <div className="mini-cart-item clearfix">
//                                         <div className="mini-cart-item-image">
//                                             <a href="#"><img src="assets/images/shop/mini-cart/img-1.jpg" alt='services'></a>
//                                         </div>
//                                         <div className="mini-cart-item-des">
//                                             <a href="#">Hoodi with zipper</a>
//                                             <span className="mini-cart-item-price">$20.15</span>
//                                             <span className="mini-cart-item-quantity">x 1</span>
//                                         </div>
//                                     </div>
//                                     <div className="mini-cart-item clearfix">
//                                         <div className="mini-cart-item-image">
//                                             <a href="#"><img src="assets/images/shop/mini-cart/img-2.jpg" alt='services'></a>
//                                         </div>
//                                         <div className="mini-cart-item-des">
//                                             <a href="#">Ninja T-shirt</a>
//                                             <span className="mini-cart-item-price">$13.25</span>
//                                             <span className="mini-cart-item-quantity">x 2</span>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="mini-cart-action clearfix">
//                                     <span className="mini-checkout-price">$215.14</span>
//                                     <a href="#" className="view-cart-btn">View Cart</a>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div><!-- end of container -->
//         </nav> */}
//         </header>
//         {/* <!-- end of header --> */}

//         {/* <!-- start page-title --> */}
//         <section className="page-title-services">
//           <div className="container">
//             <div className="row">
//               <div className="col col-xs-12">
//                 <h2>Technology Evangelization</h2>
//               </div>
//             </div>
//           </div>
//         </section>
//         {/* <!-- end page-title --> */}

//         {/* <!-- start service-single-section --> */}
//         <section className="service-single-section section-padding">
//           <div className="container">
//             <div className="row">
//               <div className="col col-md-9 col-md-push-3">
//                 <div className="service-single-content">
//                   <div className="service-single-img">
//                     <img
//                       src="assets/images/service-single/img-8.jpg"
//                       alt='services'="ccc"
//                     />
//                   </div>
//                   <h2>Technology Evangelization</h2>
//                   <p>
//                     Donec pede justo, fringilla vel, aliquet nec, vulputate
//                     eget, arcu. In enim justo, rhoncus ut, imperdiet a,
//                     venenatis vitae, justo. Nullam dictum felis eu pede mollis
//                     pretium. Integer tincidunt. Cras dapibus. Vivamus elementum
//                     semper nisi. Aenean vulputate eleifend tellus. Aenean leo
//                     ligula, porttitor eu, consequat vitae, eleifend ac, enim.
//                     Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tel
//                   </p>
//                   <blockquote>
//                     <p>
//                       Sed fringilla mauris sit amet nibh. Donec sodales sagittis
//                       magna. Sed consequat, leo eget bibendum sodales, augue
//                       velit cursus nunc,
//                     </p>
//                     <span>-Jhon smith</span>
//                   </blockquote>
//                   <div className="benefit clearfix">
//                     <div className="details">
//                       <h3>System Benefits</h3>
//                       <ul>
//                         <li>
//                           <i className="ti-arrow-circle-right"></i> Donec vitae
//                           sapien ut libero venenatis faucibus
//                         </li>
//                         <li>
//                           <i className="ti-arrow-circle-right"></i> Etiam sit
//                           amet orci eget eros faucibus tincidunt
//                         </li>
//                         <li>
//                           <i className="ti-arrow-circle-right"></i> Leo eget
//                           bibendum sodales, augue velit cursus nunc,
//                         </li>
//                       </ul>
//                       <p>
//                         Maecenas tempus, tellus eget condimentum rhoncus, sem
//                         quam semper libero, sit amet adipiscing sem neque sed
//                         ipsum. Nam quam nunc, blandit vel, luctus pulvinar,
//                         hendrerit id, lorem. Maecenas nec odio et ante tincidunt
//                         tempus. Donec vitae sapien ut libero venenatis faucibus
//                       </p>
//                     </div>
//                     <div className="img-holder">
//                       <img
//                         src="assets/images/service-single/benefit-pics.jpg"
//                         alt='services'
//                       />
//                     </div>
//                   </div>
//                   <h3>Research</h3>
//                   <p>
//                     Maecenas tempus, tellus eget condimentum rhoncus, sem quam
//                     semper libero, sit amet adipiscing sem neque sed ipsum. Nam
//                     quam nunc, blandit vel, luctus pulvinar, hendrerit id,
//                     lorem. Maecenas nec odio et ante tincidunt tempus. Donec
//                     vitae sapien ut libero venenatis faucibus
//                   </p>
//                   <div className="service-single-tab">
//                     <ul className="nav">
//                       <li className="active">
//                         <a href="#precautions" data-toggle="tab">
//                           Precautions
//                         </a>
//                       </li>
//                       <li>
//                         <a href="#intelligence" data-toggle="tab">
//                           Intelligence
//                         </a>
//                       </li>
//                       <li>
//                         <a href="#specializations" data-toggle="tab">
//                           Specializations
//                         </a>
//                       </li>
//                     </ul>

//                     <div className="tab-content">
//                       <div className="tab-pane fade in active" id="precautions">
//                         <p>
//                           Blandit vel, luctus pulvinar, hendrerit id, lorem.
//                           Maecenas nec odio et ante tincidunt tempus. Donec
//                           vitae sapien ut libero venenatis faucibus. Nullam quis
//                           ante. Etiam sit amet orci eget eros faucibus
//                           tincidunt. Duis leo. Sed fringilla mauris sit amet
//                           nibh. Donec sodales sagittis magna. Sed consequat, leo
//                           eget bibendum sodales, augue velit cursus nunc,Sed
//                           consequat, leo eget bibendum sodales, augue velit
//                           cursus nunc,
//                         </p>
//                       </div>
//                       <div className="tab-pane fade" id="intelligence">
//                         <p>
//                           Blandit vel, luctus pulvinar, hendrerit id, lorem.
//                           Maecenas nec odio et ante tincidunt tempus. Donec
//                           vitae sapien ut libero venenatis faucibus. Nullam quis
//                           ante. Etiam sit amet orci eget eros faucibus
//                           tincidunt. Duis leo. Sed fringilla mauris sit amet
//                           nibh. Donec sodales sagittis magna. Sed consequat, leo
//                           eget bibendum sodales, augue velit cursus nunc,Sed
//                           consequat, leo eget bibendum sodales, augue velit
//                           cursus nunc,
//                         </p>
//                       </div>
//                       <div className="tab-pane fade" id="specializations">
//                         <p>
//                           Blandit vel, luctus pulvinar, hendrerit id, lorem.
//                           Maecenas nec odio et ante tincidunt tempus. Donec
//                           vitae sapien ut libero venenatis faucibus. Nullam quis
//                           ante. Etiam sit amet orci eget eros faucibus
//                           tincidunt. Duis leo. Sed fringilla mauris sit amet
//                           nibh. Donec sodales sagittis magna. Sed consequat, leo
//                           eget bibendum sodales, augue velit cursus nunc,Sed
//                           consequat, leo eget bibendum sodales, augue velit
//                           cursus nunc,
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                   <a href="/contact" className="theme-btn">
//                     Get the service
//                   </a>
//                 </div>
//               </div>
//               <div className="col col-md-3 col-md-pull-9">
//                 <div className="service-sidebar">
//                   <div className="widget service-list-widget">
//                     <ul>
//                       <li>
//                         <Link to="/service" style={{ textDecoration: 'none' }}>
//                           All Service
//                         </Link>
//                       </li>
//                       <li>
//                         <Link
//                           to="/softwareLicenseAdvisory"
//                           style={{ textDecoration: 'none' }}
//                         >
//                           Software Licensing Advisory
//                         </Link>
//                       </li>
//                       <li>
//                         <Link
//                           to="/softwareAssetManagement"
//                           style={{ textDecoration: 'none' }}
//                         >
//                           Managed SAM (Software Asset Management)
//                         </Link>
//                       </li>

//                       <li className="current">
//                         <Link
//                           to="/servicethreed"
//                           style={{ textDecoration: 'none' }}
//                         >
//                           Technology Evangelization
//                         </Link>
//                       </li>
//                       <li>
//                         <Link
//                           to="/servicefour"
//                           style={{ textDecoration: 'none' }}
//                         >
//                           Procurement Optimization
//                         </Link>
//                       </li>
//                     </ul>
//                   </div>
//                   <div className="widget download-widget">
//                     <ul>
//                       <li>
//                         <a
//                           href="/assets/file/Silver_Leaf_Solutions_Who_Are_We.pdf"
//                           download="Silver_Leaf_Solutions_Who_Are_We.pdf"
//                         >
//                           <i className="ti-zip"></i>Company Profile
//                         </a>
//                       </li>
//                     </ul>
//                   </div>
//                   <div className="widget contact-widget">
//                     <div>
//                       <h5>
//                         We are Technology Evangelization <span>Experts</span>
//                       </h5>
//                       <a href="/contact">Contact with us</a>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             {/* <!-- end row --> */}
//           </div>
//           {/* <!-- end container --> */}
//         </section>
//         {/* <!-- end service-single-section --> */}

//         {/* <!-- start news-letter-section --> */}
//         {/* <section className="news-letter-section">
//           <div className="container">
//             <div className="row">
//               <div className="col col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
//                 <div className="newsletter">
//                   <h3>Email Newsletter</h3>
//                   <p>
//                     Aenean leo ligula porttitor eu consequat vitae eleifend ac
//                     enim. Aliquam lorem ante dapibus in viverra quiss consequat
//                     vitae
//                   </p>
//                   <div className="newsletter-form">
//                     <form>
//                       <div>
//                         <input type="text" className="form-control" />
//                         <button type="submit">Subscrib</button>
//                       </div>
//                     </form>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>

//         </section> */}
//         {/* <!-- end news-letter-section --> */}

//         {/* <!-- start site-footer --> */}
//         <Footer />
//         {/* <footer className="site-footer">
//         <div className="upper-footer">
//             <div className="container">
//                 <div className="row">
//                     <div className="separator"></div>
//                     <div className="col col-lg-4 col-md-3 col-sm-6">
//                         <div className="widget about-widget">
//                             <div className="logo widget-title">
//                                 <img src="assets/images/footer-logo.png" alt='services'>
//                             </div>
//                             <p>Condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar</p>
//                             <ul>
//                                 <li><a href="#"><i className="ti-facebook"></i></a></li>
//                                 <li><a href="#"><i className="ti-twitter-alt='services'"></i></a></li>
//                                 <li><a href="#"><i className="ti-linkedin"></i></a></li>
//                                 <li><a href="#"><i className="ti-pinterest"></i></a></li>
//                                 <li><a href="#"><i className="ti-youtube"></i></a></li>
//                             </ul>
//                         </div>
//                     </div>
//                     <div className="col col-lg-3 col-md-3 col-sm-6">
//                         <div className="widget contact-widget service-link-widget">
//                             <div className="widget-title">
//                                 <h3>Contact</h3>
//                             </div>
//                             <ul>
//                                 <li>54/1 New dhas sorini Asut, <br>Melbord Austria.</li>
//                                 <li><span>Phone:</span> 54786547521</li>
//                                 <li><span>Email:</span> demo@example.com</li>
//                                 <li><span>Office Time:</span> 9AM- 5PM</li>
//                             </ul>
//                         </div>
//                     </div>
//                     <div className="col col-lg-3 col-md-3 col-sm-6">
//                         <div className="widget link-widget">
//                             <div className="widget-title">
//                                 <h3>Services</h3>
//                             </div>
//                             <ul>
//                                 <li><a href="#">Managed IT services</a></li>
//                                 <li><a href="#">IT Support & helpdesk</a></li>
//                                 <li><a href="#">IT Consultancy</a></li>
//                                 <li><a href="#">Custom Software</a></li>
//                                 <li><a href="#">Cyber Security</a></li>
//                             </ul>
//                         </div>
//                     </div>
//                     <div className="col col-lg-2 col-md-3 col-sm-6">
//                         <div className="widget link-widget">
//                             <div className="widget-title">
//                                 <h3>Company</h3>
//                             </div>
//                             <ul>
//                                 <li><a href="#">About our company</a></li>
//                                 <li><a href="#">Client’s Testimonial</a></li>
//                                 <li><a href="#">Privacy policy</a></li>
//                                 <li><a href="#">Pricing and plans</a></li>
//                                 <li><a href="#">Cookies Policy</a></li>
//                             </ul>
//                         </div>
//                     </div>
//                 </div>
//             </div> <!-- end container -->
//         </div>
//         <div className="lower-footer">
//             <div className="container">
//                 <div className="row">
//                     <div className="separator"></div>
//                     <div className="col col-xs-12">
//                         <p className="copyright">Copyright &copy; 2019 netserv. All rights reserved.</p>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </footer> */}
//         {/* <!-- end site-footer --> */}
//       </div>

//       <script src="assets/js/jquery.min.js"></script>
//       <script src="assets/js/bootstrap.min.js"></script>

//       {/* <!-- Plugins for this template --> */}
//       <script src="assets/js/jquery-plugin-collection.js"></script>

//       {/* <!-- Custom script for this template --> */}
//       <script src="assets/js/script.js"></script>
//     </div>
//   );
// }

// export default Servicethreed;

import React, { useEffect } from 'react';
import Footer from '../Component/Footer';
import Navbar from '../Component/Navbar';
import { Link } from 'react-router-dom';
import { ScriptFile } from '../constants/server.constants';
import agreement from '../assets/gif/agreement.gif';
import setting from '../assets/gif/setting.gif';
import connection from '../assets/gif/connection.gif';
import php from '../assets/gif/php.gif';
import line_charts from '../assets/gif/line-charts.gif';
import funnel from '../assets/gif/funnel.gif';
import user_profile from '../assets/gif/user-profile.gif';
import checklist from '../assets/gif/checklist.gif';
function SoftwareAssetManagement() {
  useEffect(() => {
    window.scrollTo(0, 0);
    // getDataCase();
    const script = document.createElement('script');

    script.src = ScriptFile;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div>
      <div className="page-wrapper">
        {/* <!-- start preloader --> */}
        {/* <div className="preloader">
          <div className="sk-cube-grid">
            <div className="sk-cube sk-cube1"></div>
            <div className="sk-cube sk-cube2"></div>
            <div className="sk-cube sk-cube3"></div>
            <div className="sk-cube sk-cube4"></div>
            <div className="sk-cube sk-cube5"></div>
            <div className="sk-cube sk-cube6"></div>
            <div className="sk-cube sk-cube7"></div>
            <div className="sk-cube sk-cube8"></div>
            <div className="sk-cube sk-cube9"></div>
          </div>
        </div> */}
        {/* <!-- end preloader --> */}

        {/* <!-- Start header --> */}
        <header id="header" className="site-header header-style-1">
          <Navbar />
        </header>
        {/* <!-- end of header --> */}

        {/* <!-- start page-title --> */}
        <section className="page-title-services">
          <div className="container">
            <div className="row">
              <div className="col col-xs-12 page-title-service">
                <h2 style={{ textTransform: 'uppercase' }}>
                  Technology Evangelization
                </h2>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- end page-title --> */}

        {/* <!-- start service-single-section --> */}
        <section className="service-single-section section-padding">
          <div className="container">
            <div className="row">
              <div className="col col-md-9 col-md-push-3">
                <div className="service-single-content">
                  {/* <div className="service-single-img">
                    <img
                      src="assets/images/service-single/img-7.jpg"
                      alt="services"
                    />
                  </div> */}
                  {/* The need | SAM */}
                  <div className="consulting__title heading__title top__heading">
                    Critical Technology Support
                    {/* <br /> */}
                    {/* Technology Evangelization */}
                  </div>
                  <div className="col col-xs-12">
                    <section className="services-section">
                      <div className="container">
                        <div className="row">
                          <div className="services-grid clearfix">
                            <p className="text-content">
                              The Purpose of Technology Evangelization is to do
                              a fair unbiased evaluation of all the technology{' '}
                              solutions available to address a specific
                              requirement of the customer. This can be done for{' '}
                              existing solutions deployed where there can be a
                              possible alternative or it can be for new
                              technologies required to address new problems or
                              to achieve specific goals.
                            </p>

                            <ul className="text-list ml5">
                              <li>
                                <i className="ti-arrow-circle-right mar-t"></i>
                                <p>New Technology Solutions Evaluation.</p>
                              </li>

                              <li>
                                <i className="ti-arrow-circle-right mar-t"></i>
                                <p> Alternative Technology Evaluation</p>
                              </li>
                            </ul>
                            <div className="evangelization-notes">
                              <div
                                // className="grid"
                                style={{
                                  width: 'auto',
                                  border: '1px solid green',
                                  borderRadius: '10px',
                                  padding: '10px',
                                  // marginLeft: '20px',
                                }}
                                className="text-center"
                              >
                                <p style={{ textAlign: 'center' }}>
                                  Typically, today customer evaluates multiple
                                  solution based on the advice of their Partners{' '}
                                  or OEM/Publishers directly, but there is a
                                  great chance that the proposed advice options
                                  are biased to the OEM /Publisher and there is
                                  may be no independent view available to do a
                                  detailed technical evaluation of the
                                  solutions.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="universal nssplSection">
                        <div className="universal__title">
                          The Independent Advocacy
                        </div>

                        <div className="universal__text">
                          This is where Silver Leaf Solutions does not represent
                          any OEM in any technology area, rather provide
                          independent advisory on the evaluation of the
                          technology solutions required by the customer.
                        </div>

                        <div className="universal__box reverse">
                          <div className="universal__imageContainer">
                            <img src="https://silverleaf.nssplindia.com/assets/images/service-single/independent1.png" />
                          </div>

                          <div className="universal__list">
                            <div className="universal__shortTitle">
                              There May Be Multiple Evaluation Criteria For The
                              Customer:
                            </div>

                            <div className="universal__listItem singleRow">
                              <span className="ti-arrow-circle-right"></span>
                              Business need fulfilment.
                            </div>

                            <div className="universal__listItem singleRow">
                              <span className="ti-arrow-circle-right"></span>
                              Cost Reduction Potential.
                            </div>

                            <div className="universal__listItem singleRow">
                              <span className="ti-arrow-circle-right"></span>
                              Performance.
                            </div>
                            <div
                              className="grid service_thread_"
                              style={{
                                minWidth: '60%',

                                border: '1px solid green',
                                borderRadius: '10px',
                                padding: '10px',
                                // marginLeft: '20px',
                              }}
                            >
                              <p>
                                Typically, today customer evaluates multiple
                                solution based on the advice of their Partners
                                or OEM/Publishers directly, but there is a great
                                chance that the proposed advice options are
                                biased to the OEM /Publisher and there is may be
                                no independent view available to do a detailed
                                technical evaluation of the solutions.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div className="universal nssplSection">
                        <div className="consulting__title heading__title top__heading">
                          The Independent Advocacy
                        </div>

                        <div className="universal__text text-center">
                          This is where Silver Leaf Solutions does not represent
                          any OEM in any technology area, rather provide
                          independent advisory on the evaluation of the
                          technology solutions required by the customer.
                        </div>

                        <div className="universal__box reverse ml-30">
                          <div className="universal__imageContainer">
                            <img
                              src="https://silverleaf.nssplindia.com/assets/images/service-single/independent1.png"
                              style={{
                                width: '100%',
                                height: '340px',
                                objectFit: 'cover',
                              }}
                            />
                          </div>

                          <div className="universal__list">
                            <div className="consulting__title heading__title">
                              There May Be Multiple Evaluation Criteria For The
                              Customer:
                            </div>

                            <div className="universal__listItem singleRow">
                              <span className="ti-arrow-circle-right"></span>
                              Business need fulfilment.
                            </div>

                            <div className="universal__listItem singleRow">
                              <span className="ti-arrow-circle-right"></span>
                              Maturity.
                            </div>

                            <div className="universal__listItem singleRow">
                              <span className="ti-arrow-circle-right"></span>
                              Cost Reduction Potential.
                            </div>

                            <div className="universal__listItem singleRow">
                              <span className="ti-arrow-circle-right"></span>
                              Performance.
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>

                  {/* </div> */}
                </div>
              </div>
              <div className="col col-md-3 col-md-pull-9 hide-phone">
                <div className="service-sidebar">
                  <div className="widget service-list-widget">
                    <ul>
                      <li>
                        <Link to="/service" style={{ textDecoration: 'none' }}>
                          All Service
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/softwareLicenseAdvisory"
                          style={{ textDecoration: 'none' }}
                        >
                          Software Licensing Advisory
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/cyberSecurity"
                          style={{ textDecoration: 'none' }}
                        >
                          CyberSecurity Solutions
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          to="/itInfrastructure"
                          style={{ textDecoration: 'none' }}
                        >
                          Infrastructure Modernization Solutions
                        </Link>
                      </li> */}

                      <li>
                        <Link
                          to="/softwareAssetManagement"
                          style={{ textDecoration: 'none' }}
                        >
                          Managed SAM (Software Asset Management)
                        </Link>
                      </li>

                      <li className="current">
                        <Link
                          to="/servicethreed"
                          style={{ textDecoration: 'none' }}
                        >
                          Technology Evangelization
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/servicefour"
                          style={{ textDecoration: 'none' }}
                        >
                          Procurement Optimization
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="widget download-widget">
                    <ul>
                      <li>
                        <a
                          href="/assets/file/Silver_Leaf_Solutions_Who_Are_We.pdf"
                          download="Silver_Leaf_Solutions_Who_Are_We.pdf"
                        >
                          <i className="ti-zip"></i>Company Profile
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="widget contact-widget">
                    <div>
                      <h5>
                        We are Managed SAM (Software Asset Management){' '}
                        <span>Experts</span>
                      </h5>
                      <a href="/contact">Contact with us</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="containerFluid">
              <div className="consulting nssplSection">
                <div className="consulting__title">
                  Technology Evaluation Steps
                </div>

                <div className="consulting__container">
                  <div className="consulting__box">
                    <div className="consulting__boxImage">
                      <img src={checklist} alt="checklist" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxContent">
                        We understand the requirements of the customer and
                        create a requirement and problem statement document.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box">
                    <div className="consulting__boxImage">
                      <img src={connection} alt="connection" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxContent">
                        Mapping the requirements to the technologies that fulfil
                        the requirements of the customers and eliminates the
                        existing problem statement, if any.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box">
                    <div className="consulting__boxImage">
                      <img src={php} alt="php" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxContent">
                        Mapping the Technology Solutions to Products
                        (Software/Hardware etc.) which will be multiple in the
                        market.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box">
                    <div className="consulting__boxImage">
                      <img src={line_charts} alt="line_charts" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxContent">
                        Create a comparison of all the products from multiple
                        OEMs/Publishers based on the requirements. This is an
                        independent unbiased comparison.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box">
                    <div className="consulting__boxImage">
                      <img src={user_profile} alt="user_profile" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxContent">
                        Assign weightage to the features/requirements and add up
                        the points to scientifically arrive at the top options
                        for the customer.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box">
                    <div className="consulting__boxImage">
                      <img src={funnel} alt="funnel" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxContent">
                        Present all options, highlighting the top ones that
                        qualify the requirement along with budgetary pricing for
                        the solution.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box">
                    <div className="consulting__boxImage">
                      <img src={setting} alt="setting" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxContent">
                        Technical evaluation process wherein Proof of Concept
                        (PoC) is done for multiple solution options
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box">
                    <div className="consulting__boxImage">
                      <img src={agreement} alt="agreement" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxContent">
                        Final Analysis is provided post the POC is completed and
                        budget is internally arrived to zero down on the
                        solution to be finalized.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="showcase nssplSection">
                <div className="showcase__boxContainer">
                  <div className="showcase__box short">
                    <div className="showcase__image">
                      <img src="https://silverleaf.nssplindia.com/assets/images/technology/image-div1.png" />
                    </div>
                    <div className="showcase__content">
                      <div className="showcase__contentTitle">
                        SILVER LEAF SOLUTIONS
                      </div>
                      <div className="showcase__contentText">
                        The Perfect Fit
                      </div>
                    </div>
                  </div>

                  <div className="showcase__box short">
                    <div className="showcase__image">
                      <img src="https://silverleaf.nssplindia.com/assets/images/technology/image-div2.png" />
                    </div>
                    <div className="showcase__content">
                      <div className="showcase__contentTitle">
                        INTRODUCING NEW SERVICE
                      </div>
                      <div className="showcase__contentText">
                        Robotic Process Automation
                      </div>
                    </div>
                  </div>

                  <div className="showcase__box short">
                    <div className="showcase__image">
                      <img src="https://silverleaf.nssplindia.com/assets/images/technology/image-div3.png" />
                    </div>
                    <div className="showcase__content">
                      <div className="showcase__contentTitle">NOW OPEN</div>
                      <div className="showcase__contentText">
                        Western Europe – Germany
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={'row'}>
              <div className="col col-md-3 col-md-pull-9 hide-desktop">
                <div className="service-sidebar">
                  <div className="widget service-list-widget">
                    <ul>
                      <li>
                        <Link to="/service" style={{ textDecoration: 'none' }}>
                          All Service
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/softwareLicenseAdvisory"
                          style={{ textDecoration: 'none' }}
                        >
                          Software Licensing Advisory
                        </Link>
                      </li>
                      {/* <li><a href="content-engineering.html">Software Licensing Advisory</a></li> */}
                      <li>
                        <Link
                          to="/cyberSecurity"
                          style={{ textDecoration: 'none' }}
                        >
                          CyberSecurity Solutions
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          to="/itInfrastructure"
                          style={{ textDecoration: 'none' }}
                        >
                          Infrastructure Modernization Solutions
                        </Link>
                      </li> */}
                      <li>
                        <Link
                          to="/softwareAssetManagement"
                          style={{ textDecoration: 'none' }}
                        >
                          Managed SAM (Software Asset Management)
                        </Link>
                      </li>
                      <li className="current">
                        <Link
                          to="/servicethreed"
                          style={{ textDecoration: 'none' }}
                        >
                          Technology Evangelization
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/servicefour"
                          style={{ textDecoration: 'none' }}
                        >
                          Procurement Optimization
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="widget download-widget">
                    <ul>
                      <li>
                        <a
                          href="/assets/file/Silver_Leaf_Solutions_Who_Are_We.pdf"
                          download="Silver_Leaf_Solutions_Who_Are_We.pdf"
                        >
                          <i className="ti-zip"></i>Company Profile
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="widget contact-widget">
                    <div>
                      <h5>
                        We are Software Licensing Advisory<span>Experts</span>
                      </h5>
                      <a href="/contact">Contact with us</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- end row --> */}
          </div>
          {/* <!-- end container --> */}
        </section>

        {/* SAM Services | Compliance, Governance and Control */}

        <Footer />

        {/* <!-- end site-footer --> */}
      </div>

      <script src="assets/js/jquery.min.js"></script>
      <script src="assets/js/bootstrap.min.js"></script>

      {/* <!-- Plugins for this template --> */}
      <script src="assets/js/jquery-plugin-collection.js"></script>

      {/* <!-- Custom script for this template --> */}
      <script src="assets/js/script.js"></script>
    </div>
  );
}

export default SoftwareAssetManagement;
