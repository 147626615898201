import React, { useEffect } from "react";
import Footer from "../Component/Footer";
import Navbar from "../Component/Navbar";
import EmailContact from "../Component/EmailContact";
import { Global } from "@emotion/react";
import GlobalPresence from "../Component/GlobalPresence";
import { ScriptFile } from "../constants/server.constants";
function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    // getDataCase();
    const script = document.createElement("script");

    script.src = ScriptFile;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      <div>
        <div className="page-wrapper">
          {/* <!-- start preloader -->
<div className="preloader">
    <div className="sk-cube-grid">
      <div className="sk-cube sk-cube1"></div>
      <div className="sk-cube sk-cube2"></div>
      <div className="sk-cube sk-cube3"></div>
      <div className="sk-cube sk-cube4"></div>
      <div className="sk-cube sk-cube5"></div>
      <div className="sk-cube sk-cube6"></div>
      <div className="sk-cube sk-cube7"></div>
      <div className="sk-cube sk-cube8"></div>
      <div className="sk-cube sk-cube9"></div>
    </div>
</div> */}
          {/* <!-- end preloader --> */}

          {/* <!-- Start he?ader --> */}
          <header id="header" className="site-header header-style-1">
            <Navbar />
          </header>
          {/* <!-- end of header --> */}

          {/* <!-- start page-title --> */}
          <section className="page-title-contact">
            <div className="container">
              <div className="row">
                <div className="col col-xs-12">
                  <h2 style={{ textTransform: "uppercase" }}>
                    Connect with Us
                  </h2>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- end page-title --> */}

          {/* <!-- start contact-section --> */}

          <EmailContact />

          {/* <!-- end contact-section --> */}

          {/* <!-- start contact-info-section --> */}
          <section className="contact-info-section section-padding">
            <div className="container">
              <div className="row">
                <div className="col col-xs-12">
                  <div className="contact-info clearfix">
                    <div>
                      <div className="icon">
                        <i className="ti-email"></i>
                      </div>
                      <h5>Email</h5>
                      <a
                        href="mailto: info@silverleafsolutions.com"
                        // style={{ color: 'black' }}
                      >
                        info@silverleafsolutions.com
                      </a>
                      {/* <p>info@silverleafsolutions.com</p> */}
                    </div>
                    <div>
                      <div className="icon">
                        <i className="ti-headphone-alt"></i>
                      </div>
                      <h5>Contact</h5>
                      <p>+91-124-4069345</p>
                    </div>
                    <div>
                      <div className="icon">
                        <i className="ti-location-pin"></i>
                      </div>
                      <h5>Address</h5>

                      <a
                        target="_blank"
                        // href="https://www.google.com/maps/dir//Silver+Leaf+Solutions+Private+Limited+510,+Vipul+Trade+Centre,+Sector+48+Badshahpur+Sohna+Rd+Hwy+Gurugram,+Haryana+122018/@28.4068192,77.0418245,14z/data=!4m5!4m4!1m0!1m2!1m1!1s0x390d23e14bdc01d3:0xb4866f0f0380d16c"
                        href="https://www.google.com/maps/place/EMERALD+PLAZA,+Sector+65,+Gurugram,+Haryana+122102/@28.4058748,77.0680108,17z/data=!3m1!4b1!4m6!3m5!1s0x390d2268827039ed:0xa45dde6b8930b6f8!8m2!3d28.4057872!4d77.0683187!16s%2Fg%2F11pcszg8ys?entry=ttu"
                      >
                        {/* 510, Vipul Trade Centre, Sector 48, Sohna Road,
                        Gurugram, Haryana–122018, India */}
                        Silver Leaf Solutions Pvt Ltd. 5th floor, Emerald Plaza,
                        Sector 65, Golf Course Extension Road, Gurgaon 122102
                      </a>
                    </div>
                    {/* <div>
                    <div className="icon">
                      <i className="ti-global-site-pin"></i>
                      <img
                        src="assets/images/global.png"
                        style={{
                          borderRadius: '39px',
                          width: '60px',
                          height: '60px',
                        }}
                      />
                    </div>
                    <h5>Global Site</h5>
                    <p>www.silverleafsolutions.com</p>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- end contact-info-section --> */}

          {/* <!-- start contact-map-section --> */}
          <section className="contact-map-section">
            <h2 className="hidden">Contact map</h2>
            <div className="contact-map">
              {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.9147703055!2d-74.11976314309273!3d40.69740344223377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew+York%2C+NY%2C+USA!5e0!3m2!1sen!2sbd!4v1547528325671"
              allowfullscreen
            ></iframe> */}
              <iframe
                // src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14037.648450351848!2d77.0418245!3d28.4068192!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb4866f0f0380d16c!2sSilver%20Leaf%20Solutions%20Private%20Limited!5e0!3m2!1sen!2sin!4v1653315919610!5m2!1sen!2sin"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.4433978201732!2d77.06801085!3d28.4058748!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d2268827039ed%3A0xa45dde6b8930b6f8!2sEMERALD%20PLAZA%2C%20Sector%2065%2C%20Gurugram%2C%20Haryana%20122102!5e0!3m2!1sen!2sin!4v1723616373978!5m2!1sen!2sin"
                allowfullscreen
              ></iframe>
            </div>
          </section>
          {/* <!-- end contact-map-section --> */}

          {/* <section className="contact-section contact-section-s2 section-padding"> */}
          <div className="container">
            <div className="row">
              <div className="col col-xs-12">
                <div className="section-title-s5">
                  <GlobalPresence />
                </div>
              </div>
            </div>
          </div>

          {/* </section> */}
          {/* <!-- start news-letter-section --> */}
          {/* <section className="news-letter-section">
          <div className="container">
            <div className="row">
              <div className="col col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
                <div className="newsletter">
                  <h3>Email Newsletter</h3>
                  <p>
                    Aenean leo ligula porttitor eu consequat vitae eleifend ac
                    enim. Aliquam lorem ante dapibus in viverra quiss consequat
                    vitae
                  </p>
                  <div className="newsletter-form">
                    <form>
                      <div>
                        <input type="text" className="form-control" />
                        <button type="submit">Subscrib</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
          {/* <!-- end news-letter-section --> */}

          {/* <!-- start site-footer --> */}
          <Footer />
          {/* <!-- end site-footer --> */}
        </div>
      </div>
    </>
  );
}

export default Contact;
