import React, { useState, useEffect, useRef, useMemo } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { ContactUs } from "../handelrs/contact.handler";
import ReCAPTCHA from "react-google-recaptcha";

import countryList from "react-select-country-list";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

function EmailContact() {
  const [captchaToken, setCaptchaToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const captchaRef = useRef(null);

  const phoneRegExp = RegExp(
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  );
  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
    mobile: "",
    companyName: "",
    caseSubject: "",
    caseDescription: "",
  });

  useEffect(() => {});
  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "mobile") {
      if (value?.length > 15) {
        return;
      }
    }
    setUserInfo({ ...userInfo, [name]: value });
  };

  const getData = async () => {
    if (
      userInfo.name !== "" &&
      userInfo.email !== "" &&
      // userInfo.mobile &&
      // phoneRegExp.test(userInfo.mobile) &&
      userInfo.caseDescription !== "" &&
      userInfo.caseSubject !== "" &&
      userInfo.companyName !== "" &&
      captchaToken !== ""
    ) {
      if (userInfo.mobile && userInfo.mobile.length < 10) {
        toast.error("Please Enter valid mobile number", {
          position: "top-center",
        });
        return;
      }

      const data = {
        name: userInfo.name,
        email: userInfo.email,
        mobile: userInfo.mobile,
        caseSubject: userInfo.caseSubject,
        caseDescription: userInfo.caseDescription,
        companyName: userInfo.companyName,
      };
      // setLoading(true);

      setIsLoading(true);

      ContactUs(data)
        .then((resp) => {
          console.log("resp ==", resp);
          if (resp.data.status === 200) {
            toast.info(
              `Thank you for reaching out to us, kindly allow us some time to get back to you ,one of our representative would be happy to assist you soonest.`,
              {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );

            setUserInfo({
              name: "",
              email: "",
              mobile: "",
              caseSubject: "",
              caseDescription: "",
              companyName: "",
            });

            setCaptchaToken("");
            captchaRef.current?.reset();
          } else {
            toast.info(`${resp.data.message}`, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (userInfo.name === "") {
      toast.error("Please Enter Name", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (userInfo.email === "") {
      toast.error("Please Enter Email", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    // else if (userInfo.mobile === '') {
    //   toast.error('Please Enter Mobile', {
    //     position: 'top-center',
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
    else if (userInfo.caseSubject === "") {
      toast.error("Please Select Service", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (userInfo.companyName === "") {
      toast.error("Please Enter Company Name", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (userInfo.caseDescription === "") {
      toast.error("Please Enter Description", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (captchaToken === "") {
      toast.error("Please verify captcha");
    }
    // else if (!phoneRegExp.test(userInfo.mobile)) {
    //   toast.error('Please Enter valid mobile number', {
    //     position: 'top-center',
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
  };

  function onCaptchaChange(value) {
    setCaptchaToken(value);
  }
  return (
    <div>
      <section className="contact-section contact-section-s2 section-padding">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <div className="section-title-s5">
                {/* <span>Connect with Us</span> */}
                <h2>We’d Love to Hear From You!</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col col-lg-8 col-lg-offset-2">
              <div className="content-area">
                {/* <p>
                  Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur
                  ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus.
                  Maecenas tempus, tellus eget condimentum rhoncus, sem quam
                </p> */}
                <div className="contact-form">
                  <form
                    method="post"
                    className="contact-validation-active"
                    id="contact-form-main"
                  >
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        id="name"
                        placeholder="Name*"
                        value={userInfo.name}
                        onChange={handleInput}
                        maxLength={30}
                      />
                    </div>
                    <div>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Email*"
                        value={userInfo.email}
                        onChange={handleInput}
                        maxLength={60}
                      />
                    </div>
                    <div className="contact-form-number-container">
                      {/* <select
                        onChange={(e) => {
                          console.log("e", e);
                          // handleInput(e);
                        }}
                        // value={userInfo.countryCode}
                        name="countryCode"
                      >
                        <option value="">Country Code</option>

                        {options.map((item) => (
                          <option value={item.value}>{item.label}</option>
                        ))}
                      </select> */}

                      <PhoneInput
                        defaultCountry="IN"
                        country={userInfo?.countryCode}
                        value={userInfo.mobile}
                        limitMaxLength={true}
                        name="mobile"
                        onChange={(e) => {
                          const data = { target: { value: e, name: "mobile" } };
                          handleInput(data);
                        }}
                      />

                      {/* <input
                        type="number"
                        className="form-control numberInput"
                        name="mobile"
                        id="phone"
                        min={10}
                        placeholder="Contact"
                        value={userInfo.mobile}
                        onChange={handleInput}
                        maxLength={15}
                      /> */}
                    </div>

                    <div>
                      <select
                        name="caseSubject"
                        className="form-control"
                        value={userInfo.caseSubject}
                        onChange={handleInput}
                        required
                      >
                        <option disabled="disabled" value="">
                          Please Select Service
                        </option>
                        <option value="Software Licensing Advisory">
                          Software Licensing Advisory
                        </option>
                        <option value="CyberSecurity Solutions">
                          CyberSecurity Solutions
                        </option>
                        <option value="Managed SAM (Software Asset Management)">
                          Managed SAM (Software Asset Management)
                        </option>
                        <option value="Technology Evangelization">
                          Technology Evangelization
                        </option>
                        <option value="Procurement Optimization">
                          Procurement Optimization
                        </option>
                        {/* <option value="subject6">Security Solutions</option>
                        <option value="subject7">Managed ITAM Services</option> */}
                      </select>
                    </div>

                    <div className="fullwidth">
                      <input
                        type="text"
                        className="form-control"
                        name="companyName"
                        id="companyName"
                        placeholder="Company Name*"
                        value={userInfo.companyName}
                        onChange={handleInput}
                        maxLength={60}
                        required
                      />
                    </div>

                    <div className="fullwidth">
                      <textarea
                        className="form-control contact-textarea"
                        name="caseDescription"
                        id="note"
                        placeholder="Please share with us some context of your requirement..."
                        value={userInfo.caseDescription}
                        onChange={handleInput}
                        maxLength={150}
                      ></textarea>
                    </div>

                    <ReCAPTCHA
                      ref={captchaRef}
                      sitekey="6Lc6qnYqAAAAABkfPdBmOiH8Y1mB2AbY9dndbM0I"
                      onChange={onCaptchaChange}
                    />

                    <div className="submit-area">
                      <button
                        type="button"
                        className={`theme-btn submit-btn ${
                          isLoading ? "submit-btn-loading" : ""
                        }`}
                        onClick={getData}
                        disabled={isLoading}
                      >
                        {isLoading ? "Submitting..." : "Submit Now"}
                      </button>

                      <div id="loader">
                        <i className="ti-reload"></i>
                      </div>
                    </div>
                    <div className="clearfix error-handling-messages">
                      <div id="success">Thank you</div>
                      <div id="error">
                        {" "}
                        Error occurred while sending email. Please try again
                        later.{" "}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </section>
    </div>
  );
}

export default EmailContact;
