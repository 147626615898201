import React, { useEffect } from 'react';
import Footer from '../Component/Footer';
import Navbar from '../Component/Navbar';
import { Link } from 'react-router-dom';
import cost_optimization from '../../src/assets/gif/cost-optimization.gif';
import asset_management from '../../src/assets/gif/asset-management.gif';
import security from '../../src/assets/gif/security.gif';
import legal from '../../src/assets/gif/legal.gif';
import risk from '../../src/assets/gif/risk.gif';
import optimization_config from '../../src/assets/gif/bar-chart.gif';
import management from '../../src/assets/gif/management.gif';
import socialMedia from '../../src/assets/gif/social-media.gif';
import building from '../../src/assets/gif/building.gif';
import quality from '../../src/assets/gif/optimization.gif';
import search from '../../src/assets/gif/search.gif';
import folder from '../../src/assets/gif/folder.gif';
import checklist from '../../src/assets/gif/checklist.gif';
import evangelization from '../../src/assets/gif/evangelization.gif';
import rocket from '../../src/assets/gif/rocket.gif';
import complete from '../../src/assets/gif/complete.gif';
import diagram from '../../src/assets/gif/diagram.gif';
import presentation from '../../src/assets/gif/presentation.gif';
import virus from '../../src/assets/gif/virus.gif';
import email from '../../src/assets/gif/message.gif';
import padlock from '../../src/assets/gif/padlock.gif';
import lock from '../../src/assets/gif/locked.gif';
import cloudnetwork from '../../src/assets/gif/cloud-network.gif';

function IT_security() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="page-wrapper">
        {/* <!-- start preloader --> */}
        {/* <div className="preloader">
    <div className="sk-cube-grid">
      <div className="sk-cube sk-cube1"></div>
      <div className="sk-cube sk-cube2"></div>
      <div className="sk-cube sk-cube3"></div>
      <div className="sk-cube sk-cube4"></div>
      <div className="sk-cube sk-cube5"></div>
      <div className="sk-cube sk-cube6"></div>
      <div className="sk-cube sk-cube7"></div>
      <div className="sk-cube sk-cube8"></div>
      <div className="sk-cube sk-cube9"></div>
    </div>
</div> */}
        {/* <!-- end preloader --> */}

        {/* <!-- Start heade?r --> */}
        <header id="header" className="site-header header-style-1">
          <Navbar />
        </header>
        {/* <!-- end of header --> */}

        {/* <!-- start page-title --> */}
        <section className="page-title-services">
          <div className="container">
            <div className="row">
              <div className="col col-xs-12">
                <h2 style={{ textTransform: 'uppercase' }}>
                  Security Solutions | Solution Offerings
                </h2>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- end page-title --> */}

        {/* <!-- start service-single-section --> */}
        <section className="service-single-section section-padding">
          <div className="container">
            <div className="row">
              <div className="col col-md-9 col-md-push-3">
                <div className="service-single-content">
                  {/* <div className="service-single-img">
                    <img
                      src="assets/images/service-single/security-solutions.jpg"
                      alt="security"
                    />
                  </div> */}

                  {/*One Time Assessment Approach end  */}
                  {/*The earlier the better  */}
                  {/* <h3 
                  className="paddingTop">
                    Security Solutions | Solution Offerings
                  </h3> */}
                  <section
                    className="services-section-s3"
                    // style={{ padding: '30px' }}
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col col-xs-12">
                          <div className="services-grid clearfix">
                            <div className="grid">
                              <div className="grid-header">
                                <i className="fi flaticon-cloud-computing"></i>
                                <h4>Endpoint Security </h4>
                              </div>
                              <div className="wpb_wrapper">
                                <p>
                                  <ul>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>Complete cloud-based solution</span>
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>
                                        Integrated Threat Intelligence
                                      </span>
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>Proactive Threat Hunting</span>
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>
                                        Gain visibility on Emerging Threats
                                      </span>
                                    </li>
                                  </ul>
                                </p>
                              </div>
                            </div>
                            <div className="grid">
                              <div className="grid-header">
                                <i className="fi flaticon-copywriting"></i>
                                <h4>Multi-factor Authentication</h4>
                              </div>
                              <div className="wpb_wrapper">
                                <p>
                                  <ul>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>
                                        Allow access to only authorized users
                                      </span>
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>
                                        Mitigate risks associated with remote
                                        access
                                      </span>
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>
                                        Ensure compliance with regulatory norms
                                      </span>
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>
                                        Reduces Risk from Compromised Passwords
                                      </span>
                                    </li>
                                  </ul>
                                </p>
                              </div>
                            </div>

                            <div className="grid">
                              <div className="grid-header">
                                {/* <img
                                  src={evangelization}
                                  className="gif_style"
                                /> */}
                                <i className="fi flaticon-security"></i>
                                <h4>Network Security</h4>
                              </div>

                              <div className="wpb_wrapper">
                                <p>
                                  {/* Customers can get exceptions that are not
                                  known to them in the following: */}
                                  <ul>
                                    <div>
                                      <li>
                                        <i className="ti-arrow-circle-right"></i>
                                        <span>
                                          Single appliance for firewall, IPS,
                                          Web Filtering, Email Filtering and
                                          Antivirus
                                        </span>
                                      </li>
                                      <li>
                                        <i className="ti-arrow-circle-right"></i>
                                        <span>
                                          Visibility into users, hosts, apps and
                                          content on enterprise network
                                        </span>
                                      </li>

                                      <li>
                                        <i className="ti-arrow-circle-right"></i>
                                        <span>
                                          Protection against known and unknown
                                          threats
                                        </span>
                                      </li>
                                      {/* </div> */}
                                      {/* <div> */}
                                      <li>
                                        <i className="ti-arrow-circle-right"></i>
                                        <span>Zero Day Protection</span>
                                      </li>
                                    </div>
                                  </ul>
                                </p>
                              </div>
                            </div>
                            <div className="grid">
                              <div className="grid-header">
                                <i className="fi flaticon-copywriting"></i>
                                {/* <img src={optimization} className="gif_style" /> */}
                                <h4>Secure Remote Access</h4>
                              </div>

                              <div className="wpb_wrapper">
                                <p>
                                  <ul>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>
                                        Enable secure access to office PCs,
                                        internal apps from home
                                      </span>
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>
                                        Secure access for mobile users, vendors,
                                        BYOD user
                                      </span>
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span> Monitor user behavior</span>
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>Granular access policies</span>
                                    </li>
                                  </ul>
                                </p>
                              </div>
                            </div>
                            <div className="grid">
                              <div className="grid-header">
                                <i className="fi flaticon-database"></i>
                                {/* <img src={optimization} className="gif_style" /> */}
                                <h4>Web Application Security</h4>
                              </div>

                              <div className="wpb_wrapper">
                                <p>
                                  <ul>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span> Defend against bots</span>
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>Prevent account takeover</span>
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span> Protect apps from dos</span>
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span> API Security</span>
                                    </li>
                                  </ul>
                                </p>
                              </div>
                            </div>
                            <div className="grid">
                              <div className="grid-header">
                                <i className="fi flaticon-cloud-computing"></i>
                                {/* <img src={optimization} className="gif_style" /> */}
                                <h4>Load Balancer</h4>
                              </div>

                              <div className="wpb_wrapper">
                                <p>
                                  <ul>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>
                                        Balance the load between the servers
                                      </span>
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>
                                        Monitor the health status of backend
                                        server
                                      </span>
                                    </li>

                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>
                                        Reduce the workload of backend server
                                      </span>
                                    </li>
                                    <li>
                                      <i className="ti-arrow-circle-right"></i>
                                      <span>
                                        Provide the secure connection between
                                        the client and server
                                      </span>
                                    </li>
                                  </ul>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  {/*The earlier the better  */}
                  {/* <h3 className="paddingTop">
                    The earlier the better. However, engagement can also be
                    mid-term of the Software Licensing Contract.
                  </h3>



                  {/* end */}
                </div>
              </div>
              <div className="col col-md-3 col-md-pull-9 hide-phone">
                <div className="service-sidebar">
                  <div className="widget service-list-widget">
                    <ul>
                      <li>
                        <Link to="/service" style={{ textDecoration: 'none' }}>
                          All Service
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/softwareLicenseAdvisory"
                          style={{ textDecoration: 'none' }}
                        >
                          Software Licensing Advisory
                        </Link>
                      </li>
                      {/* <li><a href="content-engineering.html">Software Licensing Advisory</a></li> */}
                      <li className="current">
                        <Link
                          to="/itSecurity"
                          style={{ textDecoration: 'none' }}
                        >
                          CyberSecurity Solutions
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          to="/itInfrastructure"
                          style={{ textDecoration: 'none' }}
                        >
                          Infrastructure Modernization Solutions
                        </Link>
                      </li> */}
                      <li>
                        <Link
                          to="/softwareAssetManagement"
                          style={{ textDecoration: 'none' }}
                        >
                          Managed SAM (Software Asset Management)
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/servicethreed"
                          style={{ textDecoration: 'none' }}
                        >
                          Technology Evangelization
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/servicefour"
                          style={{ textDecoration: 'none' }}
                        >
                          Procurement Optimization
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="widget download-widget">
                    <ul>
                      <li>
                        <a
                          href="/assets/file/Silver_Leaf_Solutions_Who_Are_We.pdf"
                          download="Silver_Leaf_Solutions_Who_Are_We.pdf"
                        >
                          <i className="ti-zip"></i>Company Profile
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="widget contact-widget">
                    <div>
                      <h5>
                        We are Software Licensing Advisory<span>Experts</span>
                      </h5>
                      <a href="/contact">Contact with us</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="containerFluid">
              <div className="consulting nssplSection">
                <div className="consulting__title heading__title">
                  Security Solutions | Endpoint Security
                </div>

                <div className="consulting__container">
                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/complete.14023e24c56ea288dbf9.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Complete Cloud-Based Solution
                      </div>
                      <div className="consulting__boxContent">
                        Cloud-based solution stands for on-demand delivery of
                        computing resources over the Internet. On a
                        pay-for-use-basis, you can get access to as many
                        resources as you need such as storage space, software
                        and applications, networks, and other on-demand
                        services.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/virus.2676197b6281b8e0dfd7.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Integrate Threat Intelligence
                      </div>
                      <div className="consulting__boxContent">
                        Threat intelligence is an information that an
                        organization uses to understand the threats that have,
                        will, or are currently targeting the organization. This
                        information is used to prepare, prevent, and identify
                        cyber threats looking to take advantage of valuable
                        resources.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/search.c616487775916d05b720.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Proactive Threat Hunting
                      </div>
                      <div className="consulting__boxContent">
                        Threat hunting is the practice of proactively searching
                        for cyber threats that are lurking undetected in a
                        network. Threat hunting is an active information
                        security strategy used by security analysts. It consists
                        of searching iteratively through networks to detect
                        indicators of compromise (IoCs); hacker tactics,
                        techniques, and procedures (TTPs); and threats such as
                        Advanced Persistent Threats (APTs) that are evading your
                        existing security system
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/security.12aee98969a1cf5c30c6.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Gain Visibility On Emerging Threats
                      </div>
                      <div className="consulting__boxContent">
                        It delivers continuous, comprehensive endpoint
                        visibility that spans detection, response and forensics
                        to ensure nothing is missed and potential breaches are
                        stopped. Continuous monitoring captures endpoint
                        activity so you know exactly what’s happening - from a
                        threat on a single endpoint to the threat level of the
                        organization
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="consulting nssplSection">
                <div className="consulting__title heading__title">
                  Security Solutions | Network Security
                </div>

                <div className="consulting__container">
                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/message.2d282eb3644f1851858f.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Single Appliance For Firewall, IPS, Web Filtering, Email
                        Filtering And Antivirus
                      </div>
                      <div className="consulting__boxContent">
                        Next-Generation Firewall (NGFW) capabilities with high
                        performance required for future enterprise networks.
                        These Next-Generation Network security appliances offer
                        application inspection and control, HTTPS inspection,
                        Intrusion Prevention System, malware protection, secure
                        remote access via VPN (IPSec and SSL) and granular
                        bandwidth controls.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/diagram.2a6d4848e3864a0ab10f.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Visibility Into Users, Hosts, Apps And Content On
                        Enterprise Network
                      </div>
                      <div className="consulting__boxContent">
                        Network visibility allows organizations to have a better
                        awareness of the behavior of traffic on their networks,
                        and can use it to improve the efficiency, security and
                        performance of those networks.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/rocket.40329a34c4c138180450.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Protection Against Known And Unknown Threats
                      </div>
                      <div className="consulting__boxContent">
                        Known threats can sometimes slip past even the best
                        defensive measures, which is why most security
                        organizations actively look for both known and unknown
                        threats in their environment. Thus, organization uses
                        Network threat detection technology to understand
                        traffic patterns on the network and monitor traffic
                        within and between trusted networks, as well as to the
                        internet and try to protect their network from both
                        known and unknown threats.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/risk.62cce5cc5d6bedb2a0a5.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Zero Day Protection
                      </div>
                      <div className="consulting__boxContent">
                        If a hacker manages to exploit the vulnerability before
                        software developers can find a fix, that exploit becomes
                        known as a zero-day attack. Zero-day vulnerabilities can
                        take almost any form, because they can manifest as any
                        type of broader software vulnerability. For example,
                        they could take the form of missing data encryption, SQL
                        injection, buffer overflows, missing authorizations,
                        broken algorithms, URL redirects, bugs, or problems with
                        password security.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="consulting nssplSection">
                <div className="consulting__title heading__title">
                  Security Solutions | Web Application Security
                </div>

                <div className="consulting__container">
                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/asset-management.3f003261fef002b0d2e7.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Defend Against Rogue Bots
                      </div>
                      <div className="consulting__boxContent">
                        Proactively defend your applications against automated
                        attacks by bot and other attack tools. This prevents
                        layer 7 DoS attacks, web scraping, and brute-force
                        attacks. Proactive bot defense helps identify and
                        mitigate attacks before they cause damage to the site.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/padlock.870fa14f218d6a19e5da.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Prevent Account Takeover
                      </div>
                      <div className="consulting__boxContent">
                        Protect sensitive information from interception by
                        encrypting data while it’s still in the browser. It
                        encrypts data at the application layer to protect
                        against malware and keyloggers.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/optimization.daa65e97283b314010b8.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Protect App From DDoS
                      </div>
                      <div className="consulting__boxContent">
                        Behavioral DoS provides automatic protection against
                        DDoS attacks by analyzing traffic behavior using machine
                        learning and data analysis. By continuously monitoring
                        server health and load, anomalies (performance slowdowns
                        or traffic spikes) can be accurately detected and
                        mitigated as needed.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/legal.346518e574b650c321e3.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">API Security</div>
                      <div className="consulting__boxContent">
                        Application programming interface (API) security refers
                        to the practice of preventing or mitigating attacks on
                        APIs. APIs work as the backend framework for web
                        applications. Therefore, it is critical to protect the
                        sensitive data they transfer.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="consulting nssplSection">
                <div className="consulting__title heading__title">
                  Security Solutions | Multi-Factor Authentication
                </div>

                <div className="consulting__container">
                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/locked.706a53bf199126e054b1.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Allow Access To Only Authorized Users
                      </div>
                      <div className="consulting__boxContent">
                        Grant access to the right corporate assets for the
                        authorized users in the right context.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/search.c616487775916d05b720.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Mitigate Risks Associated With Remote Access
                      </div>
                      <div className="consulting__boxContent">
                        Allow/deny access or enforce further authentication
                        based on multiple parameters like user's location,
                        device health, browser used, access time, etc.
                        Just-in-time grant of privileged access and revocation
                        when not needed.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/checklist.93ec4af093387c5bb6d9.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Ensure Compliance With Regulatory Norms
                      </div>
                      <div className="consulting__boxContent">
                        Ensure that business is always in control of data,
                        meeting all data privacy & data security guidelines of
                        regulatory bodies like GDPR, RBI, HIPAA, EPCS, GLBA etc.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/bar-chart.c17d17201cb54c72ecb8.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Reduces Risk From Compromised Passwords
                      </div>
                      <div className="consulting__boxContent">
                        Compromised passwords pose a significant threat to the
                        security of organizations and individuals and as time
                        ticks on, the list of exposed passwords continues to
                        grow at an alarming rate. In fact, according to the
                        Verizon Data Breach report, 81% of hacking-related
                        breaches leveraged either stolen and/or weak passwords.
                        By providing an extra barrier and layer of security that
                        makes it incredibly difficult for attackers to get past,
                        MFA can block over 99.9 percent of account compromise
                        attacks.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="consulting nssplSection">
                <div className="consulting__title heading__title">
                  Security Solutions | Secure Remote Access
                </div>

                <div className="consulting__container">
                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/building.5ab8b5f4b75cb41291a2.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Enable Secure Access To Office PCs, Internal Apps From
                        Home
                      </div>
                      <div className="consulting__boxContent">
                        Allow business users to access office PCs, internal
                        web-apps, client-server apps, email server, SSH servers,
                        file servers, RDP servers & any other apps without
                        exposing your internal network to external threats and
                        provide them with secure remote access from home.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/presentation.87d95ef1b7b0d1b11962.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Secure Access For Mobile Users, Vendors, BYOD User
                      </div>
                      <div className="consulting__boxContent">
                        Secure any business application and make it available to
                        all internal and external users, for access from any
                        personal laptops, desktops or smartphones.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/complete.14023e24c56ea288dbf9.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Ensure Compliance With Regulatory Norms
                      </div>
                      <div className="consulting__boxContent">
                        Ensure that business is always in control of data,
                        meeting all data privacy & data security guidelines of
                        regulatory bodies like GDPR, RBI, HIPAA, EPCS, GLBA etc.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/evangelization.ef372834e2a4ce3eef91.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Monitor User Behavior
                      </div>
                      <div className="consulting__boxContent">
                        Monitor user productivity with per-user data points like
                        log on time, log out time, and the time for which the
                        user accessed each application.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/management.76a05a61fca7b16aa7c3.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Granular Access Policies
                      </div>
                      <div className="consulting__boxContent">
                        Enable strict security policies and allow/deny access or
                        enforce further authentication based on multiple
                        parameters like compliance, geofencing, time, device
                        control, etc.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="consulting nssplSection">
                <div className="consulting__title heading__title">
                  Security Solutions | Load Balancer
                </div>

                <div className="consulting__container">
                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/cloud-network.d84810a37ed169af8bc8.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Load The Balance Between The Servers
                      </div>
                      <div className="consulting__boxContent">
                        A load balancer is a device that acts as a reverse proxy
                        and distributes network or application traffic across a
                        number of servers. Load balancers are used to increase
                        capacity (concurrent users) and reliability of
                        applications.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/folder.81c7b6bc2b7762d2a720.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Monitor The Health Status Of Backend Server
                      </div>
                      <div className="consulting__boxContent">
                        Load balancers ensure reliability and availability by
                        monitoring the "health" of applications and only sending
                        requests to servers and applications that can respond in
                        a timely manner.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/social-media.c425a61ef0966f7c2ac0.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Provide The Secure Connection Between The Client And
                        Server
                      </div>
                      <div className="consulting__boxContent">
                        The load balancer can detect and drop distributed
                        denial-of-service (DDoS) traffic before it gets to your
                        website.
                      </div>
                    </div>
                  </div>

                  <div className="consulting__box singleRow">
                    <div className="consulting__boxImage">
                      <img src="https://silverleaf.nssplindia.com/static/media/bar-chart.c17d17201cb54c72ecb8.gif" />
                    </div>
                    <div className="consulting__textArea">
                      <div className="consulting__boxHeading">
                        Reduce The Workload Of Backend Server
                      </div>
                      <div className="consulting__boxContent">
                        A load balancer acts as the “traffic cop” sitting in
                        front of your servers and routing client requests across
                        all servers capable of fulfilling those requests in a
                        manner that maximizes speed and capacity utilization and
                        ensures that no one server is overworked, which could
                        degrade performance.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={'row'}>
              <div className="col col-md-3 col-md-pull-9 hide-desktop">
                <div className="service-sidebar">
                  <div className="widget service-list-widget">
                    <ul>
                      <li>
                        <Link to="/service" style={{ textDecoration: 'none' }}>
                          All Service
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/softwareLicenseAdvisory"
                          style={{ textDecoration: 'none' }}
                        >
                          Software Licensing Advisory
                        </Link>
                      </li>
                      {/* <li><a href="content-engineering.html">Software Licensing Advisory</a></li> */}
                      <li className="current">
                        <Link
                          to="/itSecurity"
                          style={{ textDecoration: 'none' }}
                        >
                          CyberSecurity Solutions
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          to="/itInfrastructure"
                          style={{ textDecoration: 'none' }}
                        >
                          Infrastructure Modernization Solutions
                        </Link>
                      </li> */}
                      <li>
                        <Link
                          to="/softwareAssetManagement"
                          style={{ textDecoration: 'none' }}
                        >
                          Managed SAM (Software Asset Management)
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/servicethreed"
                          style={{ textDecoration: 'none' }}
                        >
                          Technology Evangelization
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/servicefour"
                          style={{ textDecoration: 'none' }}
                        >
                          Procurement Optimization
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="widget download-widget">
                    <ul>
                      <li>
                        <a
                          href="/assets/file/Silver_Leaf_Solutions_Who_Are_We.pdf"
                          download="Silver_Leaf_Solutions_Who_Are_We.pdf"
                        >
                          <i className="ti-zip"></i>Company Profile
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="widget contact-widget">
                    <div>
                      <h5>
                        We are Software Licensing Advisory<span>Experts</span>
                      </h5>
                      <a href="/contact">Contact with us</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- end service-single-section --> */}

        {/* <!-- start news-letter-section --> */}
        {/* <section className="news-letter-section">
          <div className="container">
            <div className="row">
              <div className="col col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
                <div className="newsletter">
                  <h3>Email Newsletter</h3>
                  <p>
                    Aenean leo ligula porttitor eu consequat vitae eleifend ac
                    enim. Aliquam lorem ante dapibus in viverra quiss consequat
                    vitae
                  </p>
                  <div className="newsletter-form">
                    <form>
                      <div>
                        <input type="text" className="form-control" />
                        <button type="submit">Subscrib</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
       
        </section> */}
        {/* <!-- end news-letter-section --> */}

        {/* <!-- start site-footer --> */}
        <Footer />
        {/* <footer className="site-footer">
    <div className="upper-footer">
        <div className="container">
            <div className="row">
                <div className="separator"></div>
                <div className="col col-lg-4 col-md-3 col-sm-6">
                    <div className="widget about-widget">
                        <div className="logo widget-title">
                            <img src="assets/images/footer-logo.png" alt>
                        </div>
                        <p>Condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar</p>
                        <ul>
                            <li><a href="#"><i className="ti-facebook"></i></a></li>
                            <li><a href="#"><i className="ti-twitter-alt"></i></a></li>
                            <li><a href="#"><i className="ti-linkedin"></i></a></li>
                            <li><a href="#"><i className="ti-pinterest"></i></a></li>
                            <li><a href="#"><i className="ti-youtube"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div className="col col-lg-3 col-md-3 col-sm-6">
                    <div className="widget contact-widget service-link-widget">
                        <div className="widget-title">
                            <h3>Contact</h3>
                        </div>
                        <ul>
                            <li>54/1 New dhas sorini Asut, <br>Melbord Austria.</li>
                            <li><span>Phone:</span> 54786547521</li>
                            <li><span>Email:</span> demo@example.com</li>
                            <li><span>Office Time:</span> 9AM- 5PM</li>
                        </ul>
                    </div>
                </div>
                <div className="col col-lg-3 col-md-3 col-sm-6">
                    <div className="widget link-widget">
                        <div className="widget-title">
                            <h3>Services</h3>
                        </div>
                        <ul>
                            <li><a href="#">Managed IT services</a></li>
                            <li><a href="#">IT Support & helpdesk</a></li>
                            <li><a href="#">IT Consultancy</a></li>
                            <li><a href="#">Custom Software</a></li>
                            <li><a href="#">Cyber Security</a></li>
                        </ul>
                    </div>
                </div>
                <div className="col col-lg-2 col-md-3 col-sm-6">
                    <div className="widget link-widget">
                        <div className="widget-title">
                            <h3>Company</h3>
                        </div>
                        <ul>
                            <li><a href="#">About our company</a></li>
                            <li><a href="#">Client’s Testimonial</a></li>
                            <li><a href="#">Privacy policy</a></li>
                            <li><a href="#">Pricing and plans</a></li>
                            <li><a href="#">Cookies Policy</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div> <!-- end container -->
    </div>
    <div className="lower-footer">
        <div className="container">
            <div className="row">
                <div className="separator"></div>
                <div className="col col-xs-12">
                    <p className="copyright">Copyright &copy; 2019 netserv. All rights reserved.</p>
                </div>
            </div>
        </div>
    </div>
</footer> */}
        {/* <!-- end site-footer --> */}
      </div>

      <script src="assets/js/jquery.min.js"></script>
      <script src="assets/js/bootstrap.min.js"></script>

      {/* <!-- Plugins for this template --> */}
      <script src="assets/js/jquery-plugin-collection.js"></script>

      {/* <!-- Custom script for this template --> */}
      <script src="assets/js/script.js"></script>
    </div>
  );
}

export default IT_security;
