import axios from 'axios';
import AppConstants from '../constants';

const AxiosInstance = axios.create({
  baseURL: AppConstants.ServerConstants.BASE_PATH,
  timeout: -1,
  headers: {
    'Content-Type': 'application/json',
  },
});

AxiosInstance.interceptors.response.use(
  (response) => ({
    status: true,
    message: response.data.message,
    data: response.data,
  }),
  (error) => {
    return {
      status: false,
      message: error.response.data.errors,
    };
  }
);

export default AxiosInstance;
