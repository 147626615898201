import React from "react";
import Navbar from "../Component/Navbar";
import Footer from "../Component/Footer";

const ourParternsData = [
  {
    img: "/assets/images/partners/accops-logo.svg",
    title: "Accops",
    textColor: "#F25B30",
    link: "https://www.accops.com/",
  },
  {
    img: "/assets/images/partners/azul.svg",
    title: "Azul",
    textColor: "#142241",
    link: "https://www.azul.com/",
  },
  {
    img: "/assets/images/partners/sentinelone.svg",
    title: "SentinelOne",
    textColor: "#5700E4",
    link: "https://www.sentinelone.com/",
  },
  {
    img: "/assets/images/partners/flexera.svg",
    title: "Flexera",
    textColor: "#00A1DE",
    link: "https://www.flexera.com/",
  },
  {
    img: "/assets/images/partners/crowdstrike.svg",
    title: "Crowdstrike",
    textColor: "#EC0000",
    link: "https://www.crowdstrike.com/en-us/",
  },
];

function OurPartners() {
  return (
    <>
      <div className="page-wrapper">
        <header id="header" className="site-header header-style-1">
          <Navbar />
        </header>

        <section className="page-title-partner-with-us">
          <div className="container">
            <div className="row">
              <div className="col col-xs-12">
                <h2 style={{ textTransform: "uppercase" }}>Partnerships</h2>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="our-partners-cardContainer">
              {ourParternsData.map((item) => (
                <a
                  key={item.title}
                  href={item.link}
                  target="_blank"
                  rel="noreferrer"
                  className="our-partner-card"
                >
                  <div className="our-partner-logo">
                    <img src={item.img} alt={`${item.title} logo`} />
                  </div>

                  {/* <div className="our-partner-textContainer">
                    <h3
                      className="our-partner-heading"
                      //   style={{ color: item.textColor }}
                    >
                      {item.title}
                    </h3>

                    <h3
                      className="our-partner-heading our-partner-heading-active "
                      style={{ color: item.textColor }}
                    >
                      {item.title}
                    </h3>
                  </div> */}
                </a>
              ))}
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
}

export default OurPartners;
