import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AboutUs from '../Component/AboutUs';
import Footer from '../Component/Footer';
import Manager from '../Component/Manager';
import Navbar from '../Component/Navbar';

function Privacy() {
  // useEffect(() => {
  //   const script = document.createElement('script');

  //   script.src = 'http://52.250.20.141/silverleaf-backend/script/script.js';
  //   script.async = true;

  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <div className="page-wrapper privacy-page-wrapper">
        {/* <!-- Start header --> */}
        <header id="header" className="site-header header-style-1">
          <Navbar />
        </header>
        {/* <!-- end of header --> */}

        {/* <!-- start page-title --> */}
        <section className="page-title">
          <div className="container">
            <div className="row">
              <div className="col col-xs-12">
                <h2>Privacy Policy</h2>
              </div>
            </div>
            {/* <!-- end row --> */}
          </div>
          {/* <!-- end container --> */}
        </section>
        {/* <!-- end page-title --> */}

        {/* <!-- start features-section --> */}
        <section className="features-section section-padding">
          <div className="container">
            <div className="row">
              <div id="ajax-content-wrap" style={{ marginTop: '10px' }}>
                <div className="container-wrap">
                  <div className="container main-content">
                    <div className="row privacy-policy-row">
                      <h3>Privacy Policy</h3>
                      <p className="privacy-policy-paragraph">
                        Silver Leaf Solutions is concerned about privacy issues
                        and wants you to be familiar with how we collect, use
                        and disclose information. This Privacy Policy describes
                        our practices in connection with information that we
                        collect through activities that link to this Privacy
                        Policy including websites and through your interactive
                        business with Silver Leaf Solutions.
                      </p>
                      <p className="privacy-policy-paragraph">
                        By providing information to us, you agree to the terms
                        and conditions of this Privacy Policy. You can find out
                        details of all of Silver Leaf Solutions’ affiliates by
                        visiting our website here. When we refer to ‘we’ or
                        ‘Silver Leaf Solutions’ in this policy we are referring
                        to Silver Leaf Solutions Enterprises, Inc. and its
                        affiliates.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- end container --> */}
        </section>
        {/* <!-- end features-section --> */}

        <section className="features-section section-padding">
          <div className="container">
            <div className="row">
              <div id="ajax-content-wrap" style={{ marginTop: '10px' }}>
                <div className="container-wrap">
                  <div className="container main-content">
                    <div className="row">
                      <h3>Information We May Collect</h3>
                      <p>
                        <strong>
                          Categories of data subjects from whom we may collect
                          data:
                        </strong>
                      </p>
                      <ul className="privacy-li">
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>
                            employees and former employees including volunteers,
                            agents, individual contractors, temporary or casual
                            workers;
                          </p>
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>applicants who have applied for employment;</p>
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>
                            relatives, guardians or associates of employees,
                            former employees or applicants;
                          </p>
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>
                            contractors, agents, consultants and suppliers both
                            past and present and their individual employees or
                            representative;
                          </p>
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>
                            customers and former customers and their employees
                            or representatives, which may include clients of our
                            customers and former customers;
                          </p>
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>
                            individuals and representatives of organizations who
                            have registered with Silver Leaf Solutions to obtain
                            information on products and/or services offered; and
                          </p>
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>
                            individuals and representatives of organizations who
                            have visited the website.
                          </p>
                        </li>
                      </ul>
                      <p>
                        <strong>Information may include:</strong>
                      </p>
                      <ul className="privacy-li">
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>browser and device information;</p>
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>server log file information;</p>
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>
                            information collected through cookies and other
                            technologies;
                            <br />
                            demographic information and other information
                            provided by you;
                            <br />
                            financial details;
                          </p>
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>complaints or grievances;</p>
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>
                            contact details, being corporate contact details
                            where appropriate, including email, postal address,
                            telephone and facsimile numbers;
                            <br />
                            purchasing habits and customers;
                          </p>
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>customer preferences;</p>
                        </li>
                      </ul>
                      <p className="privacy-policy-paragraph">
                        <strong>
                          In addition, with respect to employees, former
                          employees and applicants the additional categories of
                          data may include:
                        </strong>
                      </p>
                      <ul className="privacy-li">
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>Personal details</p>
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>Family, lifestyle and social circumstances</p>
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>Employment details</p>
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>Financial details</p>
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>Complaints or grievances</p>
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>Email and postal addresses</p>
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>Telephone and facsimile numbers</p>
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>
                            Limited sensitive data such as racial or ethnic
                            origin, physical or mental health or condition,
                            religious observance, issues notified under
                            whistleblower policies, criminal records and
                            disciplinary records, collected in the ordinary
                            course of the employment relationship.
                          </p>
                        </li>
                      </ul>
                      <h3>How We May Collect the Information</h3>
                      <p>
                        <strong>
                          We may collect information in a variety of ways,
                          including:
                        </strong>
                      </p>
                      <ul className="privacy-li">
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>From you directly.</p>
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>
                            Through this site: Silver Leaf Solutions may collect
                            information through the website when you visit.
                          </p>
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>
                            Through business interaction: We may collect
                            information through your or your employer’s business
                            interaction with our employees or offices.
                          </p>
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>
                            From other sources: We may receive information from
                            other sources, such as public databases; joint
                            marketing partners; social media platforms; or other
                            third parties.
                          </p>
                        </li>
                      </ul>
                      <h3>How We May Use Information</h3>
                      <ul className="privacy-li">
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>Data storage and administration;</p>
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>Data security;</p>
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>
                            Customer and user administration, including the
                            processing of orders, enquires and client reporting;
                          </p>
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>Employee administration;</p>
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>
                            Advertising, marketing and public relations
                            activities;
                          </p>
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>Maintenance of accounts and records;</p>
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>Analysis of trends and statistical data;</p>
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>
                            Crime prevention and prosecution of offenders; and
                          </p>
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>
                            Maintenance and development of our website including
                            tailoring for specific visitors.
                          </p>
                        </li>
                      </ul>
                      <br />
                      <br />
                      <p className="privacy-policy-paragraph">
                        Silver Leaf Solutions will always request your consent
                        to send you marketing communications &#8211; as per the
                        Information Disclosure section below you may opt-out of
                        this whenever you wish.
                      </p>
                      <p className="privacy-policy-paragraph">
                        Our legal basis for collecting and using the personal
                        data described above and in this Privacy Policy will
                        depend on the personal data concerned and the specific
                        context in which we collect it.
                      </p>
                      <p className="privacy-policy-paragraph">
                        However, we will normally collect personal data from you
                        only (i) where we have your consent to do so, (ii) where
                        we need the personal data to perform a contract with
                        you, or (iii) where the processing is in our legitimate
                        interests and not overridden by your rights.
                      </p>
                      <p className="privacy-policy-paragraph">
                        If we ask you to provide personal information to comply
                        with a legal requirement or to perform a contract with
                        you, we will make this clear at the relevant time and
                        advise you whether the provision of your personal
                        information is mandatory or not (as well as of the
                        possible consequences if you do not provide your
                        personal data).
                      </p>
                      <p className="privacy-policy-paragraph">
                        We will process your information for the purposes of
                        operating our website and communicating with you as
                        necessary to provide our services to you and in our
                        legitimate commercial interests, for instance, when
                        responding to your queries, undertaking marketing
                        activities, improving our website and services or for
                        the purposes of detecting or preventing illegal
                        activities.
                        <br />
                        If you have questions about or need further information
                        concerning the legal basis on which we collect and use
                        your personal information, please contact us using the
                        contact details provided under the “Your data protection
                        rights” heading below.
                      </p>
                      <p>
                        <strong>Cookies</strong>
                      </p>
                      <p className="privacy-policy-paragraph">
                        Silver Leaf Solutions, its service providers or other
                        third parties may set cookies for your web browser when
                        you visit our website, use our products or visit a
                        website where Silver Leaf Solutions provides ads,
                        content or analytics.
                      </p>
                      <p>
                        <strong>Surveys</strong>
                      </p>
                      <p className="privacy-policy-paragraph">
                        The information you provide for surveys will be used to
                        support your relationship with Silver Leaf Solutions.
                        Silver Leaf Solutions may use online surveys to ask
                        visitors/customers for contact information (like their
                        email address). We use contact data from our surveys to
                        send the survey taker information about our company and
                        promotional material on behalf of our partners. We may
                        combine survey information with other data we hold about
                        you.
                      </p>
                      <p>
                        <strong>Contests/Giveaways</strong>
                      </p>
                      <p className="privacy-policy-paragraph">
                        The information you provide during contests and
                        giveaways will be used to support your customer
                        relationship with Silver Leaf Solutions. Silver Leaf
                        Solutions may ask for contact information (like your
                        email address). We use contact data from these events to
                        send users information about our company and promotional
                        material from some of our partners. The contact
                        information is also used to contact the visitor when
                        necessary. We may combine this information with other
                        data we hold about you.
                      </p>
                      <p>
                        <strong>Careers</strong>
                      </p>
                      <p className="privacy-policy-paragraph">
                        If you use the website to apply to work with us, the
                        information you provide will be used to evaluate your
                        candidacy for job opportunities with Silver Leaf
                        Solutions and to monitor recruitment statistics. We
                        provide electronic applications on our site in which we
                        ask job candidates for contact information (like their
                        email address) and information regarding work history
                        and education. We use third parties to help us to do
                        that. We use contact data from our applications to
                        contact those individuals whose backgrounds most closely
                        meet our requirements to schedule a personal interview.
                        As a global business, we may transfer your details
                        outside of your home country and to other Silver Leaf
                        Solutions affiliated entities. Personal information
                        about unsuccessful candidates will be held after the
                        recruitment exercise has been completed and may be
                        reconsidered should an appropriate position become
                        available. Silver Leaf Solutions will follow the
                        applicable law as to destruction and deletion of such
                        personal information. We may retain de-personalized
                        statistical information about applicants to help inform
                        our recruitment activities, but individuals should not
                        be identifiable from that data. Once a person has taken
                        up employment with us, we will compile a file relating
                        to their employment. At that stage, we will give more
                        details about how we hold employee data.
                      </p>
                      <p>
                        <strong>Information Disclosure</strong>
                      </p>
                      <p className="privacy-policy-paragraph">
                        Silver Leaf Solutions will not give or sell any
                        information regarding you or your order and the products
                        you purchase to any outside organization for its use in
                        marketing or solicitation without your consent, save for
                        the limited circumstances outlined in this policy. Your
                        information may be shared with group companies, agents,
                        contractors or vendors of Silver Leaf Solutions who
                        provide data processing services to us or who otherwise
                        process personal data for the purpose of performing
                        services for you or Silver Leaf Solutions or for the
                        purpose notified to you when we collected your personal
                        data. Silver Leaf Solutions may release personal data
                        (including personally identifiable information) when
                        required to by law if we have a good-faith belief that
                        such action is necessary to comply with a current
                        judicial proceeding, in response to a lawful request by
                        public authorities, a court order or legal process
                        served on Silver Leaf Solutions.
                      </p>
                      <p className="privacy-policy-paragraph">
                        Silver Leaf Solutions may also share your personal data
                        with a potential or actual buyer (and its agents and
                        advisers) in connection with any proposed or actual
                        purchase, merger or acquisition of any part of our
                        business, provided that we inform the buyer it must use
                        your personal data only for the purposes disclosed in
                        this Privacy Policy, as well as any other person with
                        your consent to the disclosure.
                      </p>
                      <p className="privacy-policy-paragraph">
                        We may also disclose your information if we sell or buy
                        any business or assets, in which case we may disclose
                        your personal data to the prospective seller or buyer of
                        the business or assets. If Silver Leaf Solutions or
                        substantially all of its assets are acquired by a third
                        party, personal data held may be one of the transferred
                        assets. Any third party who acquires assets from us may
                        acquire all or part of our business. They may not be in
                        the same line of business as we are. Similarly, your
                        personal information may be passed on to a successor in
                        interest in the event of a reconstruction, liquidation
                        or administration.
                      </p>
                      <p>
                        <strong>Security</strong>
                      </p>
                      <p className="privacy-policy-paragraph">
                        While Silver Leaf Solutions uses all reasonable efforts
                        to safeguard your personal data, no security program is
                        impenetrable, and criminals have been adept at
                        circumventing widely adopted security safeguards.
                        Accordingly, you acknowledge that the use of the
                        internet is not entirely secure and that you provide
                        personal information to Silver Leaf Solutions at your
                        own risk. Silver Leaf Solutions cannot therefore
                        guarantee the security or integrity of any personal data
                        that are transferred from you or via the internet. Any
                        user or third-party accessing Silver Leaf Solutions’
                        website or services is responsible for its own use of
                        the website and the services. Silver Leaf Solutions is
                        not responsible for any third party’s actions or its
                        security controls with respect to information that third
                        parties may collect or process via the website, services
                        or otherwise.
                      </p>
                      <p className="privacy-policy-paragraph">
                        Silver Leaf Solutions uses what we consider to be
                        reasonable and appropriate organizational, technical and
                        administrative measures to protect information under our
                        control. This site has security measures in place to
                        protect the loss, misuse and alteration of the
                        information under our control. All account information
                        you use or supply on Silver Leaf Solutions&#8217;
                        website when ordering or tracking products is held on a
                        system which is designed to be secure. We use reputable
                        advanced encryption technology which the aim to protect
                        you from unauthorized use of information supplied on our
                        website. In addition, we may use your IP address to help
                        diagnose problems with our server, and to administer our
                        website. Your IP address is used to help identify you
                        and your shopping cart and to gather broad demographic
                        information.
                      </p>
                      <p>
                        <strong>Social Media Features</strong>
                      </p>
                      <p className="privacy-policy-paragraph">
                        This website includes social media features, including
                        Facebook “Like” button and Twitter “Follow” button.
                        These features may collect your IP address, which page
                        you are visiting on this website, and may set a cookie
                        to enable the feature to function properly. Social media
                        features are either hosted by a third party or hosted
                        directly on this website. Your interactions with these
                        features are governed by the privacy policy of the
                        company providing it.
                      </p>
                      <p className="privacy-policy-paragraph">
                        Where we have given you (or where you have chosen) a
                        password which enables you to access certain parts of
                        our site or our online ordering facility, you are
                        responsible for keeping this password confidential. We
                        ask you not to share a password with anyone. Please make
                        sure this password is unique and that you do not use
                        this password for any other purpose. If you use another
                        account (such as a social media account) to log on to a
                        secure part of the Silver Leaf Solutions website, then
                        please make sure that you keep that password secure as
                        well. If you think that any of your passwords have been
                        compromised, please notify us so that we can look after
                        the security of your details on our website.
                      </p>
                      <p>
                        <strong>
                          Links to Third-Party Websites and Services
                        </strong>
                      </p>
                      <p className="privacy-policy-paragraph">
                        This website may contain links to third-party sites. If
                        you click on those links this will take you outside
                        Silver Leaf Solutions’ service. We cannot control and
                        are not responsible for the privacy practices of those
                        third-party sites. We encourage users to be aware when
                        they leave this website, and to read the privacy
                        statements of each website they visit. This Privacy
                        Policy applies only to this Website.
                      </p>
                      <p>
                        <strong>Retention of Personal Data</strong>
                      </p>
                      <p className="privacy-policy-paragraph">
                        We retain personal data we collect from you where we
                        have an ongoing legitimate business need to do so (for
                        example, to provide you with a service you have
                        requested or to comply with applicable legal, tax or
                        accounting requirements).
                      </p>
                      <p className="privacy-policy-paragraph">
                        When we have no ongoing legitimate business need to
                        process your personal data, we will either delete or
                        anonymise it or, if this is not possible (for example,
                        because your personal information has been stored in
                        backup archives), then we will securely store your
                        personal data and isolate it from any further processing
                        until deletion is possible.
                      </p>
                      <br />
                      <br />
                      <h3>Choice/Opt-Out/Rights</h3>
                      <br />
                      <p>
                        <strong>
                          You have the following data protection rights:
                        </strong>
                      </p>
                      <ul className="privacy-li">
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>
                            you have the right to opt-out of receiving marketing
                            communications from us. You can exercise this right
                            by clicking on &#8220;unsubscribe&#8221; by clicking
                            on the “unsubscribe” or “opt-out” link in the
                            marketing e-mails we send you. To opt-out of other
                            forms of marketing (such as postal marketing or
                            telemarketing), then please contact us using the
                            contact details provided below. You may also modify
                            your preferences in respect of such communications
                            here.
                          </p>{' '}
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>
                            you may also have the following rights (although all
                            of these rights are subject to some conditions and
                            exceptions):
                            <ul>
                              <li>
                                <i className="ti-arrow-circle-right"></i>
                                <p>
                                  to access, correct, and request deletion of
                                  your personal data;
                                </p>{' '}
                              </li>
                              <li>
                                <i className="ti-arrow-circle-right"></i>
                                <p>
                                  to object to processing of your personal data,
                                  ask us to restrict said processing, and/or
                                  request portability of your personal data;
                                </p>{' '}
                              </li>
                              <li>
                                <i className="ti-arrow-circle-right"></i>
                                <p>to erasure of your personal data.</p>{' '}
                              </li>
                            </ul>
                          </p>{' '}
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>
                            if we have collected and process your personal data
                            with your consent, then you can withdraw your
                            consent at any time. Withdrawing your consent will
                            not affect the lawfulness of any processing we
                            conducted prior to your withdrawal, nor will it
                            affect processing of your personal data conducted in
                            reliance on lawful processing grounds other than
                            consent;
                          </p>{' '}
                        </li>
                        <li>
                          <i className="ti-arrow-circle-right"></i>
                          <p>
                            you have the right to complain to a data protection
                            authority about our collection and use of your
                            personal data. For more information, please contact
                            your local data protection authority.
                          </p>{' '}
                        </li>
                      </ul>
                      <p className="privacy-policy-paragraph">
                        You must be able to provide your name and email address
                        or other information sufficient to allow us to identify
                        you. We respond to all requests we receive from
                        individuals wishing to exercise their data protection
                        rights in accordance with applicable data protection
                        laws.
                      </p>
                      <p className="privacy-policy-paragraph">
                        If you would like to exercise your rights please get in
                        touch with the relevant Silver Leaf Solutions affiliate.
                        The contact details of each Silver Leaf Solutions
                        affiliate are detailed on our website at{' '}
                        <a href="http://silverleafsolutions.com">here</a>.
                      </p>
                      <p className="privacy-policy-paragraph">
                        <strong>
                          {' '}
                          Email should be sent to{' '}
                          <a href="mailto:info@silverleafsolutions.com">
                            info@silverleafsolutions.com{' '}
                          </a>
                        </strong>
                      </p>
                      <p>
                        <strong>Privacy Shield and Data Transfer</strong>
                      </p>
                      <p className="privacy-policy-paragraph">
                        Silver Leaf Solutions is a global business. As a result,
                        the data that we may collect from you may be transferred
                        to, and stored at, any of our locations which may be
                        inside or outside India. Data may also be processed by
                        people around the world who work for use or for one of
                        our suppliers/vendors. These people may be engaged in,
                        among other things, the fulfilment of your information
                        requests, meeting your orders and the provision of
                        support services. By submitting your personal data, you
                        agree to this transfer, storing or processing.
                      </p>
                      <p className="privacy-policy-paragraph">
                        Silver Leaf Solutions has put in place a number of
                        measures designed to protect data which is transferred
                        from India to the US – EU or any other location,
                        including appropriate security measures to safeguard
                        your personal data. Silver Leaf Solutions maintains a
                        network of specific agreements to require contracting
                        parties to observe data protection legislation. We are
                        also committed to supervising third parties where we
                        have transferred data to them after data transfer to us
                        and we accept our liability in cases of onward transfer
                        of data to third parties.
                      </p>
                      <p>
                        <strong>Updates to this Privacy Policy</strong>
                      </p>
                      <p className="privacy-policy-paragraph">
                        We may change this Privacy Policy as our business
                        continues to evolve. When we update our Privacy Policy,
                        we will take appropriate measures to inform you in case
                        of significant modifications, consistent with the
                        significance of the changes we make. Please take a look
                        at the “Last Updated” legend at the bottom of this page
                        to see when this Privacy Policy was last revised.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- end container --> */}
        </section>

        {/* <Manager /> */}
        <Footer />
      </div>
      {/* <!-- end of page-wrapper --> */}

      {/* <!-- All JavaScript files
================================================== --> */}
      {/* <script src="assets/js/jquery.min.js"></script>
      <script src="assets/js/bootstrap.min.js"></script> */}

      {/* <!-- Plugins for this template --> */}
      {/* <script src="assets/js/jquery-plugin-collection.js"></script> */}

      {/* <!-- Custom script for this template --> */}
      {/* <script src="assets/js/script.js"></script> */}
    </React.Fragment>
  );
}

export default Privacy;
