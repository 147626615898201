import React, { useState, useEffect } from 'react';
import Footer from '../Component/Footer';
import Navbar from '../Component/Navbar';
import { useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { BASE_PATH } from '../constants/server.constants';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import SingleCareer from './SingleCareer';

import ReactHtmlParser from 'html-react-parser';
import { Button } from 'reactstrap';

const regExp = RegExp(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/);

function Apply() {
  const params = useParams();

  const [userInfo, setUserInfo] = useState({
    job_title: '',
    job_description: '',
    category: '',
    created_By: '',
    _id: '',
  });
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    id: ' ',
  });

  useEffect(() => {
    getCareerId();
    window.scrollTo(0, 0);
  }, []);
  const getCareerId = async () => {
    // setLoading(true);
    await axios.post(`${BASE_PATH}/career/editJob/${params.id}`).then((res) => {
      setUserInfo(res.data.data);
      // setLoading(false);
    });
  };
  const applyjob = (e) => {
    const id = e.target.id;
    showModalForPublish(id);
  };

  const showModalForPublish = (_id) => {
    let id = _id;

    setConfirmationModal({
      show: true,
      id: id,
    });
  };

  const onClose = () => {
    setConfirmationModal({
      show: false,
      id: '',
    });
  };
  //

  //validation

  return (
    <div>
      <div className="page-wrapper">
        {/* <!-- start preloader -->
<div className="preloader">
    <div className="sk-cube-grid">
      <div className="sk-cube sk-cube1"></div>
      <div className="sk-cube sk-cube2"></div>
      <div className="sk-cube sk-cube3"></div>
      <div className="sk-cube sk-cube4"></div>
      <div className="sk-cube sk-cube5"></div>
      <div className="sk-cube sk-cube6"></div>
      <div className="sk-cube sk-cube7"></div>
      <div className="sk-cube sk-cube8"></div>
      <div className="sk-cube sk-cube9"></div>
    </div>
</div> */}
        {/* <!-- end preloader --> */}

        {/* <!-- Start he?ader --> */}
        <header id="header" className="site-header header-style-1">
          <Navbar />
          {confirmationModal.show && (
            <SingleCareer id={confirmationModal.id} onClose={onClose} />
          )}
        </header>
        {/* <!-- end of header --> */}

        {/* <!-- start page-title --> */}
        <section className="page-title">
          <div className="container">
            <div className="row">
              <div className="col col-xs-12">
                <h2>Apply Now</h2>
              </div>
            </div>
          </div>
        </section>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
          className="job-title"
        >
          <div className="job-data-container">
            <div>
              <div className="job-data">
                <h4>Date:</h4>
                <p>
                  {new Date(userInfo.job_date).toLocaleDateString('en-us', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  })}
                </p>
              </div>
              <div className="job-data">
                <h4>Job Title:</h4>
                <p>{ReactHtmlParser(userInfo.job_title)}</p>
              </div>
              <div className="job-data">
                <h4>Category:</h4>
                <p>{ReactHtmlParser(userInfo.category)}</p>
              </div>
            </div>
            <div>
              <div className="job-data">
                <h4>Created By:</h4>
                <p>{ReactHtmlParser(userInfo.created_By)}</p>
              </div>
              <div className="job-data">
                <h4>Location:</h4>
                <p>{userInfo.job_Location}</p>
              </div>
            </div>
          </div>

          <div className="job_created">
            <div className="add-to-cart" style={{ marginTop: '25px' }}>
              <Button
                id={userInfo._id}
                value={userInfo._id}
                onClick={applyjob}
                color="primary"
              >
                Apply Now
              </Button>
            </div>
          </div>
        </div>

        <div className="job-description-container">
          <h3>Job Description</h3>
          <p className="job-description">
            {ReactHtmlParser(userInfo.job_description)}
          </p>
        </div>
        {/* <section className="news-letter-section">
          <div className="container">
            <div className="row">
              <div className="col col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
                <div className="newsletter">
                  <h3>Email Newsletter</h3>
                  <p>
                    Aenean leo ligula porttitor eu consequat vitae eleifend ac
                    enim. Aliquam lorem ante dapibus in viverra quiss consequat
                    vitae
                  </p>
                  <div className="newsletter-form">
                    <form>
                      <div>
                        <input type="text" className="form-control" />
                        <button type="submit">Subscribe</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <!-- end news-letter-section --> */}

        {/* <!-- start site-footer --> */}
        <Footer />
        {/* <!-- end site-footer --> */}
      </div>
    </div>
  );
}

export default Apply;
