import React, { useEffect } from 'react';
import Footer from '../Component/Footer';
import Navbar from '../Component/Navbar';
import { Link } from 'react-router-dom';
import { ScriptFile } from '../constants/server.constants';
function Servicefour() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    // getDataCase();
    const script = document.createElement('script');

    script.src = ScriptFile;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div>
      <div className="page-wrapper">
        {/* <!-- start preloader --> */}
        {/* <div className="preloader">
        <div className="sk-cube-grid">
          <div className="sk-cube sk-cube1"></div>
          <div className="sk-cube sk-cube2"></div>
          <div className="sk-cube sk-cube3"></div>
          <div className="sk-cube sk-cube4"></div>
          <div className="sk-cube sk-cube5"></div>
          <div className="sk-cube sk-cube6"></div>
          <div className="sk-cube sk-cube7"></div>
          <div className="sk-cube sk-cube8"></div>
          <div className="sk-cube sk-cube9"></div>
        </div>
    </div> */}
        {/* <!-- end preloader --> */}

        {/* <!-- Start header --> */}
        <header id="header" className="site-header header-style-1">
          <Navbar />
          {/* <nav className="navigation navbar navbar-default">
            <div className="container">
                <div className="nav-row clearfix">
                    <div className="navbar-header">
                        <button type="button" className="open-btn">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <a className="navbar-brand" href="index.html"><img src="assets/images/logo.png" alt></a>
                    </div>
                    <div id="navbar" className="navbar-collapse collapse navbar-right navigation-holder">
                        <button className="close-navbar"><i className="ti-close"></i></button>
                        <ul className="nav navbar-nav">
                            <li className="menu-item-has-children">
                                <a href="#">Home</a>
                                <ul className="sub-menu">
                                    <li><a href="index.html">Home Default</a></li>
                                    <li><a href="index-2.html">Home Style 2</a></li>
                                    <li><a href="index-3.html">Home Style 3</a></li>
                                    <li className="menu-item-has-children">
                                        <a href="#Level3">Header style</a>
                                        <ul className="sub-menu">
                                            <li><a href="index.html">Header Style 1</a></li>
                                            <li><a href="index-2.html">Header Style 2</a></li>
                                            <li><a href="index-3.html">Header Style 3</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li className="menu-item-has-children">
                                <a href="#">Pages</a>
                                <ul className="sub-menu">
                                    <li><a href="about.html">About</a></li>
                                    <li><a href="contact.html">Contact</a></li>
                                    <li><a href="team.html">Team</a></li>
                                    <li><a href="testimonials.html">Testimonials</a></li>
                                    <li><a href="testimonials-s2.html">Testimonials style 2</a></li>
                                    <li><a href="faq.html">FAQ</a></li>
                                    <li><a href="404.html">404</a></li>
                                </ul>
                            </li>
                            <li className="menu-item-has-children">
                                <a href="#">Services</a>
                                <ul className="sub-menu">
                                    <li><a href="services.html">Services</a></li>
                                    <li><a href="services-s2.html">Services style 2</a></li>
                                    <li><a href="service-single.html">IT Management</a></li>
                                    <li><a href="content-engineering.html">Content Engineering</a></li>
                                    <li><a href="capital-marketing.html">Capital Marketing</a></li>
                                    <li><a href="data-security.html">Data Security</a></li>
                                    <li><a href="cyber-security.html">Cyber Security</a></li>
                                </ul>
                            </li>
                            <li className="menu-item-has-children">
                                <a href="#">Case studies</a>
                                <ul className="sub-menu">
                                    <li><a href="projects.html">Case study</a></li>
                                    <li><a href="projects-s2.html">Case study style 2</a></li>
                                    <li><a href="project-single.html">Case Single</a></li>
                                </ul>
                            </li>
                            <li className="menu-item-has-children">
                                <a href="#">Shop</a>
                                <ul className="sub-menu">
                                    <li><a href="shop.html">Shop</a></li>
                                    <li><a href="shop-single.html">Shop single</a></li>
                                </ul>
                            </li>
                            <li className="menu-item-has-children">
                                <a href="#">Blog</a>
                                <ul className="sub-menu">
                                    <li><a href="blog.html">Blog Default</a></li>
                                    <li><a href="blog-left-sidebar.html">Blog left sidebar</a></li>
                                    <li><a href="blog-fullwidth.html">Blog full width</a></li>
                                    <li className="menu-item-has-children">
                                        <a href="#Level3">Blog Details</a>
                                        <ul className="sub-menu">
                                            <li><a href="blog-single.html">Blog Single Default</a></li>
                                            <li><a href="blog-single-left-sidebar.html">Blog single left sidebar</a></li>
                                            <li><a href="blog-single-fullwidth.html">Blog single full width</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li><a href="contact.html">Contact</a></li>
                        </ul>
                    </div><!-- end of nav-collapse -->

                    <div className="cart-search-contact">
                        <div className="header-search-form-wrapper">
                            <button className="search-toggle-btn"><i className="fi flaticon-search"></i></button>
                            <div className="header-search-form">
                                <form>
                                    <div>
                                        <input type="text" className="form-control" placeholder="Search here...">
                                        <button type="submit"><i className="ti-search"></i></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="mini-cart">
                            <button className="cart-toggle-btn"> <i className="fi flaticon-bag-1"></i> <span className="cart-count">02</span></button>
                            <div className="mini-cart-content">
                                <div className="mini-cart-items">
                                    <div className="mini-cart-item clearfix">
                                        <div className="mini-cart-item-image">
                                            <a href="#"><img src="assets/images/shop/mini-cart/img-1.jpg" alt></a>
                                        </div>
                                        <div className="mini-cart-item-des">
                                            <a href="#">Hoodi with zipper</a>
                                            <span className="mini-cart-item-price">$20.15</span>
                                            <span className="mini-cart-item-quantity">x 1</span>
                                        </div>
                                    </div>
                                    <div className="mini-cart-item clearfix">
                                        <div className="mini-cart-item-image">
                                            <a href="#"><img src="assets/images/shop/mini-cart/img-2.jpg" alt></a>
                                        </div>
                                        <div className="mini-cart-item-des">
                                            <a href="#">Ninja T-shirt</a>
                                            <span className="mini-cart-item-price">$13.25</span>
                                            <span className="mini-cart-item-quantity">x 2</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="mini-cart-action clearfix">
                                    <span className="mini-checkout-price">$215.14</span>
                                    <a href="#" className="view-cart-btn">View Cart</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- end of container -->
        </nav> */}
        </header>
        {/* <!-- end of header --> */}

        {/* <!-- start page-title --> */}
        <section className="page-title-services">
          <div className="container">
            <div className="row">
              <div className="col col-xs-12">
                <h2 style={{ textTransform: 'uppercase' }}>
                  Procurement Optimization
                </h2>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- end page-title --> */}

        {/* <!-- start service-single-section --> */}
        <section className="service-single-section section-padding">
          <div className="container">
            <div className="row">
              <div className="col col-md-9 col-md-push-3">
                <div className="service-single-content">
                  {/* <div className="service-single-img">
                    <img
                      src="assets/images/service-single/img-9.jpg"
                      alt="service"
                    />
                  </div> */}
                  <div className="consulting__title heading__title">
                    Challenges for Customer’s procurement organization
                  </div>

                  <ul>
                    <li className="ul-li">
                      <i className="ti-arrow-circle-right"></i>
                      <span>
                        Customer are especially interested in availing of Silver
                        Leaf Solutions expertise relating to global sourcing and
                        withholding tax as they realise they are currently
                        experiencing cost inefficiencies in this regard. These
                        issues are most prevalent when procuring third party
                        software.
                      </span>
                    </li>
                    <li className="ul-li">
                      <i className="ti-arrow-circle-right"></i>
                      <span>
                        Customer currently faces an uphill task in working with
                        local vendors and therefore seeks a single
                        Partner/authorized partner across multiple locations
                        that can provide a transparent cost-plus mode.
                      </span>
                    </li>
                    <li className="ul-li">
                      <i className="ti-arrow-circle-right"></i>
                      <span>
                        Customer is willing to add efficiency and knowledge in
                        the overall Software Procurement process and are thus
                        keen to explore the avenues of Software Spend Management
                        Outsourcing (starting with Tail End Spend) and Advisory
                        Services.
                      </span>
                    </li>
                  </ul>

                  <p style={{ textAlign: 'center' }}>
                    Silver Leaf Solutions helps customers in consolidating the
                    software purchases through a single source which benefits
                    them to achieve additional cost reduction and generate cost
                    optimization opportunities.
                  </p>
                </div>
                <div className="universal nssplSection">
                  <div className="universal__box reverse ml-0">
                    <div className="universal__imageContainer">
                      <img src="https://silverleaf.nssplindia.com/assets/images/procure/value-imge.png" />
                    </div>

                    <div className="universal__list service-single-content">
                      <div className="consulting__title heading__title">
                        Value Added Partner Services
                      </div>
                      <ul className="service_four_ul">
                        <li className="ul-li">
                          <i className="ti-arrow-circle-right"></i>
                          <span>
                            Silver Leaf Solutions helps customers to effectively
                            manage all its software transactions.
                          </span>
                        </li>
                        <li className="ul-li">
                          <i className="ti-arrow-circle-right"></i>
                          <span>
                            The Company offers a single source for most software
                            publishers supported by standardized processes and
                            tools to manage the software license and maintain
                            transactions in the most efficient (process) and
                            effective (cost) way.
                          </span>
                        </li>
                        <li className="ul-li">
                          <i className="ti-arrow-circle-right"></i>
                          <span>
                            As dedicated Partner of choice for Customer’s
                            software purchasing requirements, Silver Leaf
                            Solutions elevated partner status with the majority
                            of enterprise publishers/OEM’s worldwide and global
                            presence will ensure that not only will Customer
                            receive the best value possible but spend is
                            mitigated through a singular premium reselling
                            service.
                          </span>
                        </li>
                        <li className="ul-li">
                          <i className="ti-arrow-circle-right"></i>
                          <span>
                            Our Value Adding Partner Services ensure that
                            further cost savings and efficiencies can be
                            achieved through additional services like tax
                            avoidance, global sourcing and process automation.
                          </span>
                        </li>
                      </ul>
                      {/* <div className="universal__text">
                          
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col col-md-3 col-md-pull-9 hide-phone">
                <div className="service-sidebar">
                  <div className="widget service-list-widget">
                    <ul>
                      <li>
                        <Link to="/service" style={{ textDecoration: 'none' }}>
                          All Service
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/softwareLicenseAdvisory"
                          style={{ textDecoration: 'none' }}
                        >
                          Software Licensing Advisory
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/cyberSecurity"
                          style={{ textDecoration: 'none' }}
                        >
                          CyberSecurity Solutions
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          to="/itInfrastructure"
                          style={{ textDecoration: 'none' }}
                        >
                          Infrastructure Modernization Solutions
                        </Link>
                      </li> */}
                      <li>
                        <Link
                          to="/softwareAssetManagement"
                          style={{ textDecoration: 'none' }}
                        >
                          Managed SAM (Software Asset Management)
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/servicethreed"
                          style={{ textDecoration: 'none' }}
                        >
                          Technology Evangelization
                        </Link>
                      </li>
                      <li className="current">
                        <Link
                          to="/servicefour"
                          style={{ textDecoration: 'none' }}
                        >
                          Procurement Optimization
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="widget download-widget">
                    <ul>
                      <li>
                        <a
                          href="/assets/file/Silver_Leaf_Solutions_Who_Are_We.pdf"
                          download="Silver_Leaf_Solutions_Who_Are_We.pdf"
                        >
                          <i className="ti-zip"></i>Company Profile
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="widget contact-widget">
                    <div>
                      <h5>
                        We are Procurement Optimization <span>Experts</span>
                      </h5>
                      <a href="/contact">Contact with us</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="containerFluid">
              <div className="showcase nssplSection">
                <div className="consulting__title heading__title">
                  Procurement Optimization Portfolio
                </div>

                <div className="showcase__boxContainer">
                  <div className="showcase__box short">
                    <div className="showcase__image">
                      <img src="https://silverleaf.nssplindia.com/assets/images/procure/partner.png" />
                    </div>
                    <div className="showcase__content">
                      <div className="showcase__contentTitle">
                        Value Adding Partner
                      </div>
                    </div>
                  </div>

                  <div className="showcase__box short">
                    <div className="showcase__image">
                      <img src="https://silverleaf.nssplindia.com/assets/images/procure/software-procurement.png" />
                    </div>
                    <div className="showcase__content short">
                      <div className="showcase__contentTitle">
                        Software Transaction
                      </div>
                    </div>
                  </div>

                  <div className="showcase__box short">
                    <div className="showcase__image">
                      <img
                        src="https://silverleaf.nssplindia.com/assets/images/procure/manage-partners.png"
                        style={{ height: '213px' }}
                      />
                    </div>
                    <div className="showcase__content">
                      <div className="showcase__contentTitle">
                        Managed Service Tail End Spend
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="universal nssplSection">
                <div className="universal__box reverse ">
                  <div className="universal__imageContainer">
                    <img
                      src="https://silverleaf.nssplindia.com/assets/images/procure/software-transaction.png"
                      className="mt-30"
                    />
                  </div>

                  <div className="universal__list">
                    <div className="consulting__title heading__title">
                      Software Transaction Management
                    </div>

                    <div className="universal__text text-center">
                      Our Value-Added Partner services augment the basic
                      reselling services process of software license sales to
                      Customer by supporting on-going asset management
                      activities with integrated, “agreement-intelligent”
                      systems. Value adding software transaction management
                      includes:
                    </div>

                    <div className="universal__listItem singleRow">
                      <span className="ti-arrow-circle-right"></span>
                      Provision of quotes for software licenses (catalogue and
                      non-catalogue items), maintenance and subscriptions, based
                      on defined rules and service levels.
                    </div>

                    <div className="universal__listItem singleRow">
                      <span className="ti-arrow-circle-right"></span>
                      Order settlement of catalogue and non-catalogue software.
                      Enter order into the ERP system and place it at the
                      supplier in-line with agreed SLA. Provide all relevant
                      information (order confirmation, fulfilment document incl.
                      License certificate in line with agreed SLA)
                    </div>

                    <div className="universal__listItem singleRow">
                      <span className="ti-arrow-circle-right"></span>
                      Tracking of delivery to ensure the delivery within SLA
                      agreement
                    </div>

                    <div className="universal__listItem singleRow">
                      <span className="ti-arrow-circle-right"></span>
                      Control the quality of delivered articles and place a
                      claim at the vendor in case of shortcoming/false delivery
                    </div>

                    <div className="universal__listItem singleRow">
                      <span className="ti-arrow-circle-right"></span>
                      Early warnings on delays in delivery, Inform effected
                      requesters and / or projects in case of delays. Escalation
                      in case of urgent deliveries
                    </div>

                    <div className="universal__listItem singleRow">
                      <span className="ti-arrow-circle-right"></span>
                      End of life notifications are proactively managed by
                      Silver Leaf Solutions team
                    </div>
                  </div>
                </div>
              </div>

              <div className="showcase nssplSection">
                <div className="consulting__title heading__title">
                  Global Software Order Handling Process
                </div>

                <div className="showcase__boxContainer">
                  <div className="showcase__box ">
                    <div className="showcase__image">
                      <img src="https://silverleaf.nssplindia.com/assets/images/procure/partner.png" />
                    </div>
                    <div className="showcase__content">
                      <div className="showcase__contentTitle heading__title">
                        GLOBAL SOURCING OPTIMIZATION
                      </div>
                      <div className="showcase__contentText">
                        Silver Leaf Solutions selects the best suitable source
                        for a particular transaction and is able to transact
                        globally with all major currencies and with all major
                        publishers. Local billing inclusive invoice splitting
                        and hence leading to tax optimization is offered in
                        order to provide software licenses at the lowest
                        available costs.
                      </div>
                    </div>
                  </div>

                  <div className="showcase__box ">
                    <div className="showcase__image">
                      <img src="https://silverleaf.nssplindia.com/assets/images/procure/software-procurement.png" />
                    </div>
                    <div className="showcase__content short">
                      <div className="showcase__contentTitle heading__title">
                        ON-SITE RESOURCE
                      </div>
                      <div className="showcase__contentText">
                        An on-site (as per requirement) resource to support
                        operations, with the back-up of an offsite inside sales
                        resource for overflow, to perform the following
                        activities: Perform software license purchase
                        transactions for defined products/ publishers.
                      </div>
                    </div>
                  </div>

                  <div className="showcase__box ">
                    <div className="showcase__image">
                      <img
                        src="https://silverleaf.nssplindia.com/assets/images/procure/manage-partners.png"
                        style={{ height: '213px' }}
                      />
                    </div>
                    <div className="showcase__content">
                      <div className="showcase__contentTitle heading__title">
                        POWER TOOL INTEGRATION
                      </div>
                      <div className="showcase__contentText">
                        The Silver Leaf Solutions systems and their unique tools
                        are made available to customer and provide real-time
                        visibility of agreement structures, associated products,
                        and track procurement activities. Silver Leaf Solutions
                        Asset Inventory and License Management Tool is the core
                        system and provides comprehensive features.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="universal nssplSection">
                <div className="consulting__title heading__title">
                  Procurement Assessment
                </div>

                <div className="universal__box reverse">
                  <div className="universal__imageContainer">
                    <img
                      src="https://silverleaf.nssplindia.com/assets/images/procure/phase-1.jpg"
                      className="procurement-img"
                    />
                  </div>

                  <div className="universal__list">
                    <div className="universal__shortTitle">
                      Phase I: Planning Of The Assessment
                    </div>

                    <div className="universal__text text-center">
                      Silver Leaf Solutions will plan the Software Procurement
                      Assessment in form of a kick-off and planning meeting at
                      Customer’s premises or by telephone / video conference.
                    </div>

                    <div className="universal__listItem singleRow">
                      <span className="ti-arrow-circle-right"></span>
                      Planning of the Software Procurement Assessment timeline
                      and project logistics.
                    </div>

                    <div className="universal__listItem singleRow">
                      <span className="ti-arrow-circle-right"></span>
                      Detailed planning of the on-site Assessment
                    </div>

                    <div className="universal__listItem singleRow">
                      <span className="ti-arrow-circle-right"></span>
                      Identification of partners which are involved in software
                      procurement and can provide information about
                      organizational aspects and business processes, current and
                      future software demand, management of software demand, the
                      software vendor portfolio, management of software vendors,
                      IT systems used for software procurement as well as
                      number, skills and training of the people involved in
                      software procurement.
                    </div>

                    <div className="universal__listItem singleRow">
                      <span className="ti-arrow-circle-right"></span>
                      Alignment of available documents based on the Silver Leaf
                      Solutions Assessment checklist.
                    </div>
                  </div>
                </div>
              </div>

              <div className="universal nssplSection">
                <div className="universal__box reverse">
                  <div className="universal__imageContainer">
                    <img
                      src="https://silverleaf.nssplindia.com/assets/images/procure/phase3.jpg"
                      className="procurement-img"
                    />
                  </div>

                  <div className="universal__list">
                    <div className="universal__shortTitle">
                      Phase II: Documentation Of Results
                    </div>

                    <div className="universal__listItem singleRow">
                      <span className="ti-arrow-circle-right"></span>
                      Analysis of the gathered information
                    </div>

                    <div className="universal__listItem singleRow">
                      <span className="ti-arrow-circle-right"></span>
                      Clarify on missing information and open issues from the
                      on-site analysis
                    </div>

                    <div className="universal__listItem singleRow">
                      <span className="ti-arrow-circle-right"></span>
                      Create the Software Procurement Assessment results report
                      containing the information was provided by Customer, which
                      will include both Qualitative and Quantitative Aspects,
                      Evaluation of the maturity of Software Procurement, etc.
                    </div>
                  </div>
                </div>
              </div>

              <div className="universal nssplSection">
                <div className="universal__box reverse">
                  <div className="universal__imageContainer">
                    <img
                      src="https://silverleaf.nssplindia.com/assets/images/procure/phase4.jpg"
                      className="procurement-img"
                    />
                  </div>

                  <div className="universal__list">
                    <div className="universal__shortTitle">
                      Phase III: Presentation And Handover Of Assessment Results
                    </div>

                    <div className="universal__listItem singleRow">
                      <span className="ti-arrow-circle-right"></span>
                      Planning of the results presentation
                    </div>

                    <div className="universal__listItem singleRow">
                      <span className="ti-arrow-circle-right"></span>
                      Pre-alignment of the results report with the Customer
                      project manager
                    </div>

                    <div className="universal__listItem singleRow">
                      <span className="ti-arrow-circle-right"></span>
                      Presentation of Assessments results to the project team of
                      Customer
                    </div>

                    <div className="universal__listItem singleRow">
                      <span className="ti-arrow-circle-right"></span>
                      Discussion of the results and their impact on the elements
                      of a Managed Service for Software Procurement
                    </div>

                    <div className="universal__listItem singleRow">
                      <span className="ti-arrow-circle-right"></span>
                      Agreement of next steps
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={'row'}>
              <div className="col col-md-3 col-md-pull-9 hide-desktop">
                <div className="service-sidebar">
                  <div className="widget service-list-widget">
                    <ul>
                      <li>
                        <Link to="/service" style={{ textDecoration: 'none' }}>
                          All Service
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/softwareLicenseAdvisory"
                          style={{ textDecoration: 'none' }}
                        >
                          Software Licensing Advisory
                        </Link>
                      </li>
                      {/* <li><a href="content-engineering.html">Software Licensing Advisory</a></li> */}
                      <li>
                        <Link
                          to="/cyberSecurity"
                          style={{ textDecoration: 'none' }}
                        >
                          CyberSecurity Solutions
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          to="/itInfrastructure"
                          style={{ textDecoration: 'none' }}
                        >
                          Infrastructure Modernization Solutions
                        </Link>
                      </li> */}
                      <li>
                        <Link
                          to="/softwareAssetManagement"
                          style={{ textDecoration: 'none' }}
                        >
                          Managed SAM (Software Asset Management)
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/servicethreed"
                          style={{ textDecoration: 'none' }}
                        >
                          Technology Evangelization
                        </Link>
                      </li>
                      <li className="current">
                        <Link
                          to="/servicefour"
                          style={{ textDecoration: 'none' }}
                        >
                          Procurement Optimization
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="widget download-widget">
                    <ul>
                      <li>
                        <a
                          href="/assets/file/Silver_Leaf_Solutions_Who_Are_We.pdf"
                          download="Silver_Leaf_Solutions_Who_Are_We.pdf"
                        >
                          <i className="ti-zip"></i>Company Profile
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="widget contact-widget">
                    <div>
                      <h5>
                        We are Software Licensing Advisory<span>Experts</span>
                      </h5>
                      <a href="/contact">Contact with us</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- end row --> */}
          </div>
          {/* <!-- end container --> */}
        </section>
        {/* <!-- end service-single-section --> */}

        {/* <!-- start news-letter-section --> */}
        {/* <section className="news-letter-section">
          <div className="container">
            <div className="row">
              <div className="col col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
                <div className="newsletter">
                  <h3>Email Newsletter</h3>
                  <p>
                    Aenean leo ligula porttitor eu consequat vitae eleifend ac
                    enim. Aliquam lorem ante dapibus in viverra quiss consequat
                    vitae
                  </p>
                  <div className="newsletter-form">
                    <form>
                      <div>
                        <input type="text" className="form-control" />
                        <button type="submit">Subscrib</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
        </section> */}
        {/* <!-- end news-letter-section --> */}

        {/* <!-- start site-footer --> */}
        {/* <footer className="site-footer">
        <div className="upper-footer">
            <div className="container">
                <div className="row">
                    <div className="separator"></div>
                    <div className="col col-lg-4 col-md-3 col-sm-6">
                        <div className="widget about-widget">
                            <div className="logo widget-title">
                                <img src="assets/images/footer-logo.png" alt="service">
                            </div>
                            <p>Condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar</p>
                            <ul>
                                <li><a href="#"><i className="ti-facebook"></i></a></li>
                                <li><a href="#"><i className="ti-twitter-alt"></i></a></li>
                                <li><a href="#"><i className="ti-linkedin"></i></a></li>
                                <li><a href="#"><i className="ti-pinterest"></i></a></li>
                                <li><a href="#"><i className="ti-youtube"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-lg-3 col-md-3 col-sm-6">
                        <div className="widget contact-widget service-link-widget">
                            <div className="widget-title">
                                <h3>Contact</h3>
                            </div>
                            <ul>
                                <li>54/1 New dhas sorini Asut, <br>Melbord Austria.</li>
                                <li><span>Phone:</span> 54786547521</li>
                                <li><span>Email:</span> demo@example.com</li>
                                <li><span>Office Time:</span> 9AM- 5PM</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-lg-3 col-md-3 col-sm-6">
                        <div className="widget link-widget">
                            <div className="widget-title">
                                <h3>Services</h3>
                            </div>
                            <ul>
                                <li><a href="#">Managed IT services</a></li>
                                <li><a href="#">IT Support & helpdesk</a></li>
                                <li><a href="#">IT Consultancy</a></li>
                                <li><a href="#">Custom Software</a></li>
                                <li><a href="#">Cyber Security</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-lg-2 col-md-3 col-sm-6">
                        <div className="widget link-widget">
                            <div className="widget-title">
                                <h3>Company</h3>
                            </div>
                            <ul>
                                <li><a href="#">About our company</a></li>
                                <li><a href="#">Client’s Testimonial</a></li>
                                <li><a href="#">Privacy policy</a></li>
                                <li><a href="#">Pricing and plans</a></li>
                                <li><a href="#">Cookies Policy</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> <!-- end container -->
        </div>
        <div className="lower-footer">
            <div className="container">
                <div className="row">
                    <div className="separator"></div>
                    <div className="col col-xs-12">
                        <p className="copyright">Copyright &copy; 2019 netserv. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    </footer> */}
        {/* <!-- end site-footer --> */}
        <Footer />
      </div>

      <script src="assets/js/jquery.min.js"></script>
      <script src="assets/js/bootstrap.min.js"></script>

      {/* <!-- Plugins for this template --> */}
      <script src="assets/js/jquery-plugin-collection.js"></script>

      {/* <!-- Custom script for this template --> */}
      <script src="assets/js/script.js"></script>
    </div>
  );
}

export default Servicefour;
