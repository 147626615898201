import AppConstants from "../constants";
import AxiosInstance from "./http.handlers";

let url = AppConstants.ServerConstants.API_ROUTES;

export const GetAllGallery = async () => {
  return await AxiosInstance.get(url.Gallery.GET_All_Gallery)
    .then((response) => response)
    .catch((error) => error);
};
