import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../Component/Footer';
import Navbar from '../Component/Navbar';
import axios from 'axios';
import { Link } from 'react-router-dom';
import SingleProject from '../Component/SingleProject';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { GetAllCaseStudies } from '../handelrs/caseStudies.handler';
import { ScriptFile } from '../constants/server.constants';
import CaseStudy from '../Component/caseStudySection';

// import "../../public/assets/css/owl-carousel.css"
function Projects() {
  const navigate = useNavigate();
  const [allcaseStudies, setAllcaseStudies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState(1);
  const [count, setCount] = useState(0);
  const [openSingle, setOpenSingle] = useState({
    title: '',
    description: '',
  });
  useEffect(() => {
    // getData();
    window.scrollTo(0, 0);
    const script = document.createElement('script');

    script.src = ScriptFile;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
    // // jQuerycode();
    // console.log("check", $(".projects-slider").val());
    // jQueryCall();
  });

  // const handlePageClick = (event, newPage) => {
  //   setPage(newPage);
  // };
  // const data = {
  //   page: page,
  //   limit: 6,
  // };
  // const getData = async () => {
  //   setLoading(true);
  //   GetAllCaseStudies(data).then((resp) => {
  //     if (resp.data.status === 200) {
  //       setAllcaseStudies(resp.data.data.caseStudyList);
  //       setCount(resp.data.data.totalPages);
  //       setLoading(false);
  //     }
  //   });
  // };

  return (
    <div>
      <div className="page-wrapper">
        {/* <!-- start prel?oader --> */}
        {loading && (
          <div className="preloader">
            <div className="sk-cube-grid">
              <div className="sk-cube sk-cube1"></div>
              <div className="sk-cube sk-cube2"></div>
              <div className="sk-cube sk-cube3"></div>
              <div className="sk-cube sk-cube4"></div>
              <div className="sk-cube sk-cube5"></div>
              <div className="sk-cube sk-cube6"></div>
              <div className="sk-cube sk-cube7"></div>
              <div className="sk-cube sk-cube8"></div>
              <div className="sk-cube sk-cube9"></div>
            </div>
          </div>
        )}
        {/* <!-- end preloa?der --> */}

        {/* <!-- Start header --> */}
        <header id="header" className="site-header header-style-1">
          <Navbar />
        </header>
        {/* <!-- end of header --> */}

        <section className="page-title-caseStudies">
          <div className="container">
            <div className="row">
              <div className="col col-xs-12">
                <h2>CASE STUDIES</h2>
              </div>
            </div>
          </div>
        </section>

        <CaseStudy />

        {/* <!-- start site-footer --> */}
        <Footer />
      </div>
    </div>
  );
}

export default Projects;
